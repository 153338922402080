import { gql } from 'graphql-request';

const GET_PLAN_LIST_CREATE_TICKET_GQL = gql`
query MyQuery($year: Int, $userId: String, $type: String){
  planListCreateTicket(year: $year, userId: $userId, type: $type) {
    arrayPlans
  }
}
`;

export default GET_PLAN_LIST_CREATE_TICKET_GQL;
