import { Column } from '../interfaces/Column.d';

export default function useReassignColumn(columns: Column[]) {
  const reassignColumn = columns
    .filter(({ accessor }) => (accessor !== 'subscribed' && accessor !== 'results' && accessor !== 'Detalle' && accessor !== 'resultId'))
    .map(({ Header, accessor }) => ({
      label: Header,
      key: accessor,
    }));

  return { reassignColumn };
}
