import { Link } from 'react-router-dom';
import Theme from '../../../styles/Theme';
import useHelp from '../../../hooks/useHelp';
import { Table } from '../../atoms/Table/Table';
import Container from '../../atoms/Container/Container';
import Typography from '../../atoms/Typography/Typography';
import TableSkeleton from '../../atoms/Table/TableSkeleton';
import FilterForm from '../../organisms/FilterForm/FilterForm';
import CtaCreateTicket from '../../organisms/Ctas/CtaCreateTicket';
import LinkTo from '../../atoms/Link/LinkTo';
import FilterFormSkeleton from '../../organisms/FilterForm/FilterFormSkeleton';

export default function Help() {
  const {
    data,
    columns,
    filters,
    isFetchingFilters,
    globalFilter,
    updateGlobalFilter,
  } = useHelp();
  let dataTickets:Array<[]> = [];

  dataTickets = data?.userTickets?.nodes?.map((ticket:any) => ({
    ...ticket,
    detail: <LinkTo to={`/ticket/${ticket?.id}`} color={Theme.colorPrimary} style={{ textAlign: 'center', display: 'block' }}>Ver ticket</LinkTo>,
  }));

  const getDateRangeEndMax = () => {
    const today = new Date();
    // Months starts at 0. I need NEXT month
    let month = today.getMonth() + 2;
    let currentYear = today.getFullYear();
    if (month > 11) {
      currentYear += 1;
      month = 1;
    }
    return `${currentYear}-${String(month).padStart(2, '0')}-01`;
  };

  return (
    <Container>
      <Typography color={Theme.colorCampaign} size="32px" margin="0 0 1rem" display="block" align="center" weight="500">Tus incidencias</Typography>

      {!isFetchingFilters ? (
        <FilterForm
          dataFilters={filters}
          filter={globalFilter}
          setFilter={updateGlobalFilter}
          type=""
          fetching={isFetchingFilters}
          dateRange
          dateRangeStartMin="2023-01-01"
          dateRangeEndMax={getDateRangeEndMax()}
        />
      ) : <FilterFormSkeleton countFilters={4} />}

      <CtaCreateTicket>
        <Link to="/ticket-create" className="link" style={{ color: Theme.colorPrimary, marginBottom: '1rem', display: 'inline-block' }}>
          <span>Crear incidencia</span>
        </Link>
      </CtaCreateTicket>

      {data !== undefined ? (
        <Table
          columns={columns}
          data={dataTickets}
          theme={Theme.colorCampaign}
          className="tb__help"
        />
      ) : (<TableSkeleton countCell={6} theme={Theme.colorCampaign} />)}
    </Container>
  );
}
