import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { IFilter } from '../../../interfaces/IFilter.d';
import { IRanking } from '../../../interfaces/IRanking.d';
import Theme from '../../../styles/Theme';
import Container from '../../atoms/Container/Container';
import { LinkTable, Table } from '../../atoms/Table/Table';
import FilterModel from '../../molecules/Filter/FilterModel';
import GroupFilterGeneral from '../../molecules/Filter/GroupFilterGeneral';
import TableSkeleton from '../../atoms/Table/TableSkeleton';
import useYear from '../../../hooks/useYear';
import GET_RANKINGS_GQL from '../../../graphql/queries/getRankingsGql';
import { createGraphQLClient } from '../../../graphql/graphqlClient';

export default function RankingCalendar() {
  const graphQLClient = createGraphQLClient();
  const { year } = useYear();
  const [globalFilter, setGlobalFilter] = useState<FilterModel>(new FilterModel());
  const selectedFilters: IFilter = { year: [] };

  const updateGlobalFilter = (newFilter: FilterModel) => setGlobalFilter(newFilter);

  const {
    data,
    error,
    refetch,
    isLoading,
    isFetching,
  } = useQuery(
    ['RankingCalendar', year],
    async () => (graphQLClient && graphQLClient.request(GET_RANKINGS_GQL, { year })),
  );

  const dataRanking = data?.rankings?.nodes?.map((r: IRanking) => ({
    name: r?.name,
    year: r?.year,
    detail: r?.procedureFileUrl === '#' ? <Container>No disponible</Container> : <LinkTable url={r?.procedureFileUrl} title="Descargar" />,
  }));

  useEffect(() => {
    refetch();
  }, [year]);

  if (error) {
    return <Container>Error</Container>;
  }

  const columns = [
    { Header: 'Rankings', accessor: 'name' },
    { Header: 'Año', accessor: 'year' },
    { Header: 'Detalle', accessor: 'detail' },
  ];

  return (
    <Container>
      <>
        <GroupFilterGeneral
          selectedFilters={selectedFilters}
          filter={globalFilter}
          setGlobalFilter={updateGlobalFilter}
        />
        {(
          !isLoading
          && !isFetching
          && columns
          && dataRanking) ? (
            <Table
              columns={columns}
              data={dataRanking}
              theme={Theme.colorRanking}
              className="tb__ranking-calendar"
            />
          ) : <TableSkeleton countCell={6} theme={Theme.colorRanking} />}
      </>
    </Container>
  );
}
