import useRenderSubTable, { IRenderSubTable } from '../../../hooks/useRenderSubTable';
import Theme from '../../../styles/Theme';
import IconArrow from '../../atoms/Icon/IconArrow';
import { LinkTable, Table } from '../../atoms/Table/Table';
import useControlledColumns from '../../../hooks/useControlledColumns';
import useDate from '../../../hooks/useDate';
import useMappingColumnNames from '../../../hooks/useMappingColumnNames';
import Container from '../../atoms/Container/Container';
import CardDataExportXLSX from '../../molecules/Cards/CardDataExportXLSX';
import useProcessDataCSV from '../../../hooks/useProcessDataCSV';
import LinkTo from '../../atoms/Link/LinkTo';

export function renderRowSubComponent(results: any) {
  if (results?.row !== undefined && results?.row !== 'undefined' && results?.row !== null) {
    const resultsValues = results?.row?.original?.results?.nodes;
    const extraFieldsValues = results?.row?.original?.results?.nodes
      ?.map((obj: any) => obj?.extraFields);
    const planLevelsValues = results?.row?.original?.planLevels;
    const props: IRenderSubTable = {
      resultsValues,
      extraFieldsValues,
      planLevelsValues,
      planType: 'rappel',
      translations: results?.row?.original?.translations,
      groupedItems: results?.row?.original?.groupedItems,
    };

    const { processedKeys, processedValues } = useRenderSubTable(props);
    const processHeadersFirst = {};
    const processHeadersObjects:any = {};
    const processHeadersList:any = [];
    processedKeys.forEach((e: any) => {
      processHeadersList[e.accessor] = e.Header;
    });
    Object.assign(processHeadersObjects, processHeadersList);
    const newProcessHeader = { ...processHeadersFirst, ...processHeadersObjects };
    const processHedersCSV: any[] = [];
    const processHedersCSVKeys: any[] = ['IMPORTE'];
    Object.entries(newProcessHeader).forEach(([key, value]: any) => {
      if (!processHedersCSVKeys.includes(key)) {
        processHedersCSVKeys.push(key);
        processHedersCSV.push({ label: useMappingColumnNames(value, 'campaign'), key: useControlledColumns(key) });
      }
    });
    processedValues.forEach((item: any, index: number) => {
      Object.entries(item).forEach(([key, value]: any) => {
        if (key === 'FECHA ACT') {
          processedValues[index][key] = useDate(value);
        }
      });
    });
    let showData = true;
    if (processedKeys.length < 2) {
      showData = false;
    }

    const newProcessValues = useProcessDataCSV(processedValues);

    return (
      <Container>
        <CardDataExportXLSX
          headers={processHedersCSV}
          data={newProcessValues}
          name="rappel_global_position_sub_table"
        />
        <Table
          columns={showData ? processedKeys : []}
          data={showData ? processedValues : []}
          isScrollingSubtable={results?.row?.allCells?.length < processedKeys?.length}
        />
      </Container>
    );
  }
  return null;
}

export const specialRappels = ['RPP-R-N-20230003', 'RPP-R-N-20230004', 'RPP-R-N-20230005', 'RPP-R-N-20230006', 'RPP-R-N-20240004', 'RPP-R-N-20240003', 'RPP-R-N-20240005', 'RPP-R-N-20240002'];

export const headers = [
  { label: 'Rappeles', key: 'plan' },
  { label: 'Importe', key: 'amount' },
  { label: 'Cumple', key: 'comply' },
  { label: 'Gana', key: 'win' },
  { label: 'Situación rappel', key: 'situation' },
  { label: 'Fecha inicio', key: 'startDate' },
  { label: 'Fecha fin', key: 'endDate' },
];

export const columns = [
  { Header: 'Avísame', accessor: 'subscribed' },
  { Header: 'Rappeles', accessor: 'plan' },
  { Header: 'Importe', accessor: 'amount' },
  { Header: 'Cumple', accessor: 'comply' },
  { Header: 'Situación rappel', accessor: 'situation' },
  { Header: 'Fecha inicio', accessor: 'startDate' },
  { Header: 'Fecha fin', accessor: 'endDate' },
  { Header: 'Estado', accessor: 'planStatusName' },
  { Header: 'Datos a día', accessor: 'dataDate' },
  {
    Header: 'Detalle',
    accessor: 'detail',
    Cell: ({ row }:any) => (
      row?.original?.planLevels?.map(
        (e:any) => e.level,
      ).sort((a:any, b:any) => b - a)[0] < 2 && !specialRappels.includes(row?.original?.planCode)
        ? (
        // eslint-disable-next-line indent
          <label {
            ...row.getToggleRowExpandedProps()
          }
          >
            <LinkTable title="Detalle" onClick={() => renderRowSubComponent(row)}>
              {row.isExpanded
                ? <IconArrow color={`${Theme.colorPrimary}`} rotate />
                : <IconArrow color={`${Theme.colorPrimary}`} /> }
            </LinkTable>
          </label>
        ) : <LinkTo to={specialRappels.includes(row?.original?.planCode) ? `/global-position/rappel/${row?.original?.planCode}` : row?.original?.detail?.props?.to} color={Theme.colorPrimary} style={{ textAlign: 'center', display: 'block' }}>Ver detalle</LinkTo>
    ),
  },
  {
    Header: 'Comparativa',
    accessor: 'comparative',
  },
  { Header: 'Bases', accessor: 'download' },
  {
    Header: 'Ayuda',
    accessor: 'helpLink',
    Cell: ({ row }:any) => (<LinkTo to={`/ticket-create/${row?.original?.id}`} color={Theme.colorPrimary} style={{ textAlign: 'center', display: 'block' }}>Incidencia/Consulta</LinkTo>),
  },
];
