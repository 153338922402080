import { gql } from 'graphql-request';

const GET_DROPDOWN_HELP_GQL = gql`
query DropdownHelp($year: Int){
  dropdownHelp(year: $year) {
    arrayStatus
    arrayPlanType
    arrayTicketType
    arrayYears
  }
}
`;

export default GET_DROPDOWN_HELP_GQL;
