import { useEffect, useState, Fragment } from 'react';
import { useQueryClient, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Generic } from '../../../interfaces/Generic.d';
import Container from '../../atoms/Container/Container';
import GroupFilterGeneral from '../../molecules/Filter/GroupFilterGeneral';
import FilterModel from '../../molecules/Filter/FilterModel';
import useYear from '../../../hooks/useYear';
import { IFilter } from '../../../interfaces/IFilter.d';
import Theme from '../../../styles/Theme';
import {
  HeaderSummaryTeam,
  TitleSummaryTeam,
} from '../../organisms/SummaryTeam/StyledSummaryTeam';
import CardDataExportXLSX from '../../molecules/Cards/CardDataExportXLSX';
import { Table } from '../../atoms/Table/Table';
import TableSkeleton from '../../atoms/Table/TableSkeleton';
import { ButtonBack } from '../../atoms/Button/StyledButton';
import useDelegatePlanDetail from '../../../hooks/useDelegatePlanDetail';
import Grid from '../../atoms/Grid/Grid';
import useRemoveUndefinedProperties from '../../../hooks/useRemoveUndefinedProperties';
import Graphic from '../../atoms/Graphic/Graphic';
import Score from '../../molecules/Score/Score';
import { Card } from '../../atoms/Card/Card';
import Typography from '../../atoms/Typography/Typography';
import { createGraphQLClient } from '../../../graphql/graphqlClient';
import GET_CURRENT_USER_PLANS_GQL from '../../../graphql/queries/getCurrentUserPlansGql';

type Params = {
  [key: string]: string | number | undefined;
  timeframe?: string;
  startDate?: string;
  endDate?: string;
  grouping?: string;
  type?: string;
  status?: string;
  year?: number;
}

type PlanProps = {
  id: string,
  name: string,
  color: string,
}

export default function SummaryDelegates() {
  const navigate = useNavigate();
  const { year } = useYear();
  const queryClient = useQueryClient();
  const user: any = queryClient?.getQueryState(['UserInfo']);
  const userId = user?.data?.me?.id;
  const graphQLClient = createGraphQLClient();

  const [globalFilter,
    setGlobalFilter,
  ] = useState<FilterModel>(new FilterModel());

  const selectedFilters: IFilter = {
    temporalidad: [], year: [], status: [],
  };

  const updateGlobalFilter = (newFilter: FilterModel) => setGlobalFilter(newFilter);

  const {
    data: plansData,
  } = useQuery(
    ['MePlans', year],
    async () => (graphQLClient && graphQLClient.request(
      GET_CURRENT_USER_PLANS_GQL,
      { year },
    )),
    {
      staleTime: Infinity,
    },
  );

  const plans: PlanProps[] = [
    { id: 'campaign', name: 'Campañas', color: Theme.colorCampaign },
    { id: 'rappel', name: 'Rappeles', color: Theme.colorRappel },
    { id: 'convention', name: 'Convención', color: Theme.colorConvention },
    { id: 'ranking', name: '', color: Theme.colorRanking },
  ];

  const summaryPlans = plans.map((plan: PlanProps) => {
    const variables: Params = {
      timeframe: (globalFilter.dateRange && globalFilter.dateRange.split(',')[0]) ? globalFilter.dateRange.split(',')[0].toString() : undefined,
      startDate: (globalFilter.dateRange && globalFilter.dateRange.split(',')[1]) ? globalFilter.dateRange.split(',')[1].toString() : undefined,
      endDate: (globalFilter.dateRange && globalFilter.dateRange.split(',')[2]) ? globalFilter.dateRange.split(',')[2].toString() : undefined,
      grouping: globalFilter.grouping || undefined,
      subgrouping: globalFilter.subgrouping || undefined,
      type: `${plan.id?.charAt(0).toUpperCase()}${plan.id?.substring(1)}`,
      year,
      startD: (globalFilter.dateRange && globalFilter.dateRange.split(',')[1]) ? globalFilter.dateRange.split(',')[1].toString() : undefined,
      endD: (globalFilter.dateRange && globalFilter.dateRange.split(',')[2]) ? globalFilter.dateRange.split(',')[2].toString() : undefined,
      status: globalFilter.status || undefined,
    };

    useRemoveUndefinedProperties(variables);

    const {
      planId,
      planType,
      planName,
      planColor,
      refetch,
      error,
      isFetching,
      isLoading,
      columns,
      columnsExcel,
      planFormat,
      planFormatExcel,
      statisticStructure,
      graphics,
    } = useDelegatePlanDetail(variables, userId, plan.name, plan.color);

    useEffect(() => {
      refetch();
    }, [globalFilter.dateRange, globalFilter.grouping, globalFilter.status]);

    return {
      planId,
      planName,
      planColor,
      planType,
      planFormat,
      planFormatExcel,
      refetch,
      error,
      isFetching,
      isLoading,
      columns,
      columnsExcel,
      statisticStructure,
      graphics,
    };
  });

  const hasAnyError = summaryPlans.some((s: any) => s.error);
  if (hasAnyError) {
    return <Container>Error</Container>;
  }

  return (
    <Container>
      <ButtonBack onClick={() => navigate('/')} type="button" style={{ margin: '0 0 1rem' }}>Volver</ButtonBack>
      <>
        <GroupFilterGeneral
          selectedFilters={selectedFilters}
          filter={globalFilter}
          setGlobalFilter={updateGlobalFilter}
        />
        {summaryPlans.map((item: any, index: number) => (
          item.planType !== 'Ranking' && plansData?.mePlans?.[item.planType.toLowerCase()] ? (
            <div key={`${index * 2}`}>
              <HeaderSummaryTeam>
                <TitleSummaryTeam theme={item.planColor}>
                  {item.planName}
                </TitleSummaryTeam>
                {item.columnsExcel && item.planFormatExcel && (
                  <CardDataExportXLSX
                    headers={item.columnsExcel}
                    data={item.planFormatExcel}
                    name={`${item.planType}_delegate_summary`}
                  />
                )}
              </HeaderSummaryTeam>
              <Grid
                columnsLg={2}
                spaceBetween={1}
                rowHeight={['320px', '320px']}
                margin="0 auto"
                className="delegates__table"
                regular
              >
                <Card
                  background="transparent"
                  display="flex"
                  flexDirection={`${item?.graphics?.charts?.length > 2 ? 'column' : ''}`}
                  alignItems="flex-start"
                  gap="1rem"
                >
                  <Card
                    display="flex"
                    alignItems="center"
                    flexDirection="row"
                    justifyContent="center"
                    padding={`${item?.planType === 'Rappel' ? '1rem' : '1rem 20% 1rem 1rem'}`}
                    radius="5px"
                    width="100%"
                  >
                    <Card margin={`${item?.graphics?.values ? '0' : ''}`}>
                      {item?.graphics?.values && item?.graphics?.values
                        ?.map((graphic: any, i: number) => (
                          <Fragment key={graphic.id}>
                            <Typography margin="0" weight="500">
                              {graphic?.title}
                            </Typography>
                            <Typography
                              color={`${i === 0 ? Theme.colorSecondary3 : item?.graphics?.charts?.[0]?.theme}`}
                              display="block"
                              margin="0 0 0.5rem"
                              size="22px"
                              weight="600"
                            >
                              <span style={{
                                background: '#FFFFFF', display: 'block', padding: '0.2rem 0.5rem', borderRadius: '5px', textAlign: 'center',
                              }}
                              >
                                {graphic?.value}
                              </span>
                            </Typography>
                          </Fragment>
                        ))}
                    </Card>
                    <Card display="flex" justifyContent="center" margin={`${item?.graphics?.values ? '0 auto' : ''}`} className="delegates__table__graphics">
                      {item?.graphics?.charts
                        ?.filter((chart: Generic) => chart.type === 'pie')
                        ?.map((chart: Generic) => (
                          <Graphic
                            key={chart.id}
                            type={chart.type}
                            theme={chart.theme}
                            score={chart.score}
                            total={chart.total}
                          />
                        ))}
                    </Card>
                  </Card>
                  <div className={`${item.planType === 'Rappel' ? 'score__container rappel' : 'score__container'}`}>
                    {item?.graphics?.charts?.length > 0 && (
                      <Score
                        score={item?.graphics?.charts?.filter((chart: Generic) => chart.type === 'card')}
                        cols={item.planType === 'campaign' ? 3 : 1}
                        maxWidth="100%"
                      />
                    )}
                  </div>
                </Card>

                {(!item.isLoading && !item.isFetching)
                  ? (item.planFormat?.length > 0 && (
                    <Table
                      columns={item.columns}
                      data={item.planFormat}
                      theme={item.planColor}
                    />
                  )) : (
                    <TableSkeleton
                      countCell={3}
                      theme={item.planColor}
                    />
                  )}
              </Grid>
            </div>
          ) : ''
        ))}
      </>
    </Container>
  );
}
