import { useState, useEffect, useRef } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { columnsSummary, headers } from './dataConventionTeam';
import Theme from '../../../styles/Theme';
import Container from '../../atoms/Container/Container';
import { Tabs } from '../../atoms/Tabs';
import Typography from '../../atoms/Typography/Typography';
import { LinkTable, Table } from '../../atoms/Table/Table';
import GET_MY_TEAM_PLANS_BY_PARAMS_GQL from '../../../graphql/queries/getMyTeamPlansByParamsGql';
import LinkTo from '../../atoms/Link/LinkTo';
import FilterModel from '../../molecules/Filter/FilterModel';
import Score from '../../molecules/Score/Score';
import useNumberFormat from '../../../hooks/userNumberFormat';
import GET_MY_TEAM_DATA_FILTERS_GQL from '../../../graphql/queries/getMyTeamDataFiltersGql';
import { Card } from '../../atoms/Card/Card';
import useFormatDate from '../../../hooks/useDate';
import useCsvNumber from '../../../hooks/useCsvNumber';
import FilterForm from '../../organisms/FilterForm/FilterForm';
import CardDataExportXLSX from '../../molecules/Cards/CardDataExportXLSX';
import { PlanByType } from '../../../interfaces/IPlan.d';
import ParamsModel from '../../models/PaginationModel';
import GET_PLAN_COUNT_GQL from '../../../graphql/queries/getPlanCounterGql';
import FilterFormSkeleton from '../../organisms/FilterForm/FilterFormSkeleton';
import TableSkeleton from '../../atoms/Table/TableSkeleton';
import GET_MY_INFO_GQL from '../../../graphql/queries/getCurrentUserInfoGql';
import SubscriptionButton from '../../molecules/Subscriptios/SubscriptionButton';
import useYear from '../../../hooks/useYear';
import SkeletonCard from '../../atoms/Card/SkeletonCard';
import { getGraphQLClient } from '../../../graphql/graphqlClient';
import { ButtonBack } from '../../atoms/Button/StyledButton';
import GET_TEAM_RESULTS_GQL from '../../../graphql/queries/getTeamResultsGql';

export interface TeamConventionParams {
  id: string;
}

export default function ConventionTeam({ id }:TeamConventionParams) {
  const graphQLClient = getGraphQLClient();
  const { grouping } = useParams();
  const [globalFilter, setGlobalFilter] = useState<FilterModel>(new FilterModel());
  const [globalParams, setGlobalParams] = useState<ParamsModel>(new ParamsModel());
  const [activeTab, setActiveTab] = useState(grouping || id || '');
  const [activeSubtab, setActiveSubtab] = useState('');
  const type = 'Convention';
  const { year } = useYear();
  const [tabs, setTabs] = useState<any>([]);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const updateGlobalFilter = (newFilter: FilterModel) => setGlobalFilter(newFilter);
  const updateGlobalParams = (params: ParamsModel) => setGlobalParams(params);

  const conventionParams = {
    type,
    timeframe: (globalFilter.dateRange && globalFilter.dateRange.split(',')[0]) ? globalFilter.dateRange.split(',')[0].toString() : undefined,
    startDate: (globalFilter.dateRange && globalFilter.dateRange.split(',')[1]) ? globalFilter.dateRange.split(',')[1].toString() : undefined,
    endDate: (globalFilter.dateRange && globalFilter.dateRange.split(',')[2]) ? globalFilter.dateRange.split(',')[2].toString() : undefined,
    category: globalFilter.category ? globalFilter.category : undefined,
    dgt: globalFilter.dgt ? globalFilter.dgt : undefined,
    dt: globalFilter.dt ? globalFilter.dt : undefined,
    od: globalFilter.od ? globalFilter.od : undefined,
    group: globalFilter.group ? globalFilter.group : undefined,
    year,
    firstName: globalFilter.firstName ? globalFilter.firstName : undefined,
    lastName: globalFilter.lastName ? globalFilter.lastName : undefined,
    nif: globalFilter.nif ? globalFilter.nif : undefined,
    grouping: activeTab !== 'resumen' ? activeTab.toUpperCase() : undefined,
    subgrouping: activeSubtab !== 'resumen' ? activeSubtab?.toUpperCase() : '',
    search: globalFilter.search ? globalFilter.search : '',
    status: globalFilter.status ? globalFilter.status : '',
    planStatus: globalFilter.status ? globalFilter.status : '',
    numberElements: globalParams?.numberElements || 10,
    numberPage: globalParams?.numberPage || 1,
    order: globalParams?.order || 'name',
    orderType: globalParams?.orderType || 'desc',
    myTeam: true,
  };

  const {
    data: dataCount,
    isFetching: isFetchingCount,
    refetch: refetchCount,
    status: statusCount,
  } = useQuery(
    ['ConventionTeamCount', conventionParams],
    async () => (graphQLClient && graphQLClient.request(GET_PLAN_COUNT_GQL, conventionParams)),
    {
      staleTime: Infinity,
    },
  );

  const {
    data: dataFilters,
    error: errorFilters,
    isFetching: isFetchingFilters,
  } = useQuery(
    ['ConventionTeamFilters', { type, year }],
    async () => (graphQLClient && graphQLClient.request(
      GET_MY_TEAM_DATA_FILTERS_GQL,
      { type: 'Convention', year },
    )),
    {
      staleTime: Infinity,
    },
  );

  const {
    data: planConvention,
    error: errorConvention,
    refetch: refetchConvetion,
    isFetching: isFetchingConvention,
    status: statusConvention,
  } = useQuery(
    ['ConventionTeam', conventionParams],
    async () => (graphQLClient && graphQLClient.request(
      GET_MY_TEAM_PLANS_BY_PARAMS_GQL,
      conventionParams,
    )),
    {
      staleTime: Infinity,
    },
  );

  const {
    data: dataUser,
  } = useQuery(
    ['User'],
    async () => (graphQLClient && graphQLClient.request(GET_MY_INFO_GQL)),
    {
      staleTime: Infinity,
    },
  );

  const userId = dataUser?.me?.id;

  useEffect(() => {
    setGlobalFilter({ ...globalFilter, grouping: activeTab });
  }, []);

  const conventionParamsCSV = {
    ...conventionParams,
    numberElements: undefined,
  };

  const {
    data: planConventionCSV,
    refetch: refetchConventionCSV,
    isFetching: isFetchingConventionCSV,
    status: statusConventionCSV,
  } = useQuery(
    ['ConventionTeamXlsx', conventionParamsCSV],
    async () => (graphQLClient && graphQLClient.request(
      GET_MY_TEAM_PLANS_BY_PARAMS_GQL,
      conventionParamsCSV,
    )),
    {
      staleTime: Infinity,
    },
  );

  const variablesChartByTimeframe = {
    myTeam: true,
    type: 'Convention',
    year,
    grouping: activeTab !== 'resumen' ? activeTab.toUpperCase() : undefined,
    status: globalFilter.status ? globalFilter.status : undefined,
  };
  const {
    data: dataChartByTimeframe,
    isFetching: isFetchingChartByTimeframe,
  } = useQuery(
    ['ConventionTeamResults', variablesChartByTimeframe],
    async () => (graphQLClient
      && graphQLClient.request(GET_TEAM_RESULTS_GQL, variablesChartByTimeframe)),
    {
      staleTime: Infinity,
    },
  );
  const results = dataChartByTimeframe?.summaryGlobalPositionChartByTimeframe;
  const scoreIndicators = () => {
    const participants = results !== undefined ? Object.entries(results?.participantsCount[0])
      .find(([key]) => key === 'Anual') : '';
    const wins = results !== undefined ? Object.entries(results?.totalUserWinCount[0])
      .find(([key]) => key === 'Anual') : '';
    const score = [
      {
        id: 0,
        title: 'Participa',
        subttitle: '',
        score: (participants?.[1]),
        icon: 'participant',
      },
      {
        id: 1,
        title: 'Ganan',
        subttitle: '',
        score: (wins?.[1]),
        icon: 'cup',
      },
    ];
    return score;
  };

  const groupingList: any = queryClient.getQueryState(['GroupingList']);

  const getTabs = () => {
    let response = [];
    const list = groupingList?.data?.groupingList?.grouping;
    if (list) {
      response = list[year].Convention;
    }
    return response;
  };

  useEffect(() => {
    setTabs(getTabs());
  }, [groupingList]);

  useEffect(() => {
    navigate(`/team/convention/${activeTab}`, { replace: true });
    setGlobalFilter({ ...globalFilter, grouping: activeTab });
    setGlobalParams({ ...globalParams, numberPage: 1 });

    const completeActiveTab :any = tabs.find((tab: any) => tab.id === activeTab);
    if (completeActiveTab?.subgroupings) {
      setActiveSubtab(completeActiveTab?.subgroupings[0].id);
    }
  }, [activeTab]);

  useEffect(() => {
    setTabs(getTabs());
    if (statusCount !== 'success') {
      refetchCount();
    }
    if (statusConvention !== 'success' && statusCount !== 'success') {
      refetchConvetion();
    }
    if (statusConventionCSV !== 'success' && statusCount !== 'success') {
      refetchConventionCSV();
    }
  }, [globalFilter]);

  const debouncedSearch = (value :any, currentGlobalFilter: FilterModel) => {
    setGlobalFilter({ ...currentGlobalFilter, search: value });
  };

  const setServicesValueDebounced = useRef(_.debounce(debouncedSearch, 1000));

  const handleChange = (e :any) => {
    setServicesValueDebounced.current(e.currentTarget.value, globalFilter);
  };

  const dataConvention = planConvention?.myTeamPlans?.nodes?.map((c:PlanByType) => ({
    conventionName: c?.description,
    participants: useNumberFormat(c?.participantsNumber),
    wins: c?.winsNumber,
    amount: useNumberFormat(c?.resultsAmount),
    dataDate: useFormatDate(c?.dataDate),
    status: c.planStatusName,
    conventionResults: <LinkTo to={`/team/convention/resumen/${c?.code}`} color={Theme.colorPrimary} style={{ textAlign: 'center', display: 'block' }}>Ver resultados</LinkTo>,
    timeframe: c?.timeframe,
    startDate: c?.startDate,
    endDate: c?.endDate,
    grouping: c?.grouping,
    subscribed: <SubscriptionButton
      subscribed={c.subscribed}
      planId={c.id}
      userId={userId}
    />,
    download: <LinkTable url={c?.basesUrl} title="Descargar" />,
    helpLink: <LinkTo to={`/ticket-create/${c?.id}`} color={Theme.colorPrimary} style={{ display: 'block', textAlign: 'center' }}>Incidencia/Consulta</LinkTo>,
  }));

  const dataConventionCSV = planConventionCSV?.myTeamPlans?.nodes?.map((c:PlanByType) => ({
    conventionName: c?.description,
    participants: useNumberFormat(c?.participantsNumber),
    wins: c?.winsNumber,
    amount: useNumberFormat(c?.resultsAmount),
    dataDate: useFormatDate(c?.dataDate),
    status: c.planStatusName,
    conventionResults: <LinkTo to={`/team/convention/${c?.code}`} color={Theme.colorPrimary} style={{ textAlign: 'center', display: 'block' }}>Ver resultados</LinkTo>,
    timeframe: c?.timeframe,
    startDate: c?.startDate,
    endDate: c?.endDate,
    grouping: c?.grouping,
  }));

  const processDataConvention = dataConventionCSV?.map((d: any) => ({ ...d }));

  processDataConvention?.forEach((item: any, index: number) => {
    Object.entries(item).forEach(([key, value]: any) => {
      const decimals = false;
      processDataConvention[index][key] = useCsvNumber(value, decimals);
    });
  });

  let participantsCounter = 0;
  let winsCounter = 0;

  dataConvention?.map((c:any) => {
    participantsCounter += Number(c.participants);
    winsCounter += Number(c.wins);
    return [participantsCounter, winsCounter];
  });

  if (errorConvention || errorFilters) {
    return <Container>Error</Container>;
  }

  return (
    <Container>
      <ButtonBack onClick={() => navigate('/team/summary/general')} type="button">Volver</ButtonBack>
      <Typography color={Theme.colorConvention} size="32px" margin="0 0 1rem" display="block" align="center" weight="500">Convenciones</Typography>

      <Card background="transparent" display="flex" alignItems="center" justifyContent="space-between">
        <input
          type="text"
          placeholder="Buscar por nombre de convención"
          onChange={handleChange}
          onPaste={handleChange}
          className="search"
          style={{ height: '38px', maxWidth: '450px', margin: '0 0 1rem' }}
        />
      </Card>

      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        onChangeTab={setActiveTab}
        activeSubtab={activeSubtab}
        onChangeSubtab={setActiveSubtab}
        theme={Theme.colorConvention}
      />

      {!isFetchingFilters ? (
        <FilterForm
          dataFilters={dataFilters}
          filter={globalFilter}
          setFilter={updateGlobalFilter}
          type="Convention"
          fetching={isFetchingConvention}
        />
      ) : <FilterFormSkeleton countFilters={10} />}

      {headers && processDataConvention && !isFetchingConventionCSV && (
        <CardDataExportXLSX
          headers={headers}
          data={processDataConvention}
          name="convention_team"
        />
      )}

      {!isFetchingCount && !isFetchingConvention && dataConvention ? (
        <Table
          columns={columnsSummary}
          data={dataConvention}
          theme={Theme.colorConvention}
          defaultPageSize={10}
          countsRecords={dataCount?.planCounter?.counter}
          countPerPage={10}
          globalParams={globalParams}
          setGlobalParams={updateGlobalParams}
        />
      ) : <TableSkeleton countCell={6} theme={Theme.colorConvention} />}

      {!isFetchingChartByTimeframe ? (
        <Score score={scoreIndicators()} cols={2} maxWidth="260px" margin="0 auto 4rem auto" />
      ) : (<SkeletonCard cols={2} maxWidth="260px" />)}

    </Container>
  );
}
