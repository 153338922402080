import { useQuery } from 'react-query';
import LinkTo from '../components/atoms/Link/LinkTo';
import SubscriptionButton from '../components/molecules/Subscriptios/SubscriptionButton';
import GET_MY_TEAM_PLANS_BY_PARAMS_GQL from '../graphql/queries/getMyTeamPlansByParamsGql';
import { Column } from '../interfaces/Column.d';
import { SummaryPlan } from '../interfaces/SummaryPlan.d';
import Theme from '../styles/Theme';
import useCsvNumber from './useCsvNumber';
import useFormatDate from './useDate';
import useProcessData from './useProcessData';
import useNumberFormat from './userNumberFormat';
import useSummaryTeamColumns from './useSummaryTeamColumns';
import { getGraphQLClient } from '../graphql/graphqlClient';

type Variables = {
  [key: string]: string | number | undefined;
  timeframe?: string;
  startDate?: string;
  endDate?: string;
  grouping?: string;
  type?: string;
  status?: string;
  year?: number;
}

export default function useSummaryTeamByPlan(
  variables: Variables,
  userId: string,
  planId: string,
  planName: string,
  planColor: string,
) {
  const graphQLClient = getGraphQLClient();
  let planStructure = [];
  let columns: any = [];
  let columnsExcel: any = [];
  const planType = variables.type;

  const {
    campaignColumns,
    campaignColumnsExcel,
    rappelColumns,
    rappelColumnsExcel,
    conventionColumns,
    conventionColumnsExcel,
  } = useSummaryTeamColumns();

  const {
    data,
    error,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(
    [`${planType}_summary`, variables],
    async () => (graphQLClient && graphQLClient.request(
      GET_MY_TEAM_PLANS_BY_PARAMS_GQL,
      variables,
    )),
    {
      staleTime: Infinity,
    },
  );

  planStructure = data?.myTeamPlans?.nodes?.map((plan: SummaryPlan) => ({
    name: plan?.description,
    participants: useNumberFormat(plan?.participantsNumber),
    updatedAt: useFormatDate(plan?.dataDate && plan?.dataDate),
    status: plan?.planStatusName,
    wins: useNumberFormat(plan?.winsNumber),
    amount: useNumberFormat(plan?.resultsAmount, true),
    comply: plan?.fulfillsNumber,
    dataDate: useFormatDate(plan?.dataDate),
    results: <LinkTo to={`/team/convention/${plan?.code}`} color={Theme.colorPrimary}>Ver resultados</LinkTo>,
    subscribed: <SubscriptionButton
      subscribed={plan.subscribed}
      planId={plan.id}
      userId={userId}
    />,
  }));

  if (planType && planType === 'Campaign') {
    columns = campaignColumns.map((column: Column) => column);
    columnsExcel = campaignColumnsExcel.reassignColumn;
  }

  if (planType && planType === 'Rappel') {
    columns = rappelColumns;
    columnsExcel = rappelColumnsExcel.reassignColumn;
  }

  if (planType && planType === 'Convention') {
    columns = conventionColumns;
    columnsExcel = conventionColumnsExcel.reassignColumn;
  }

  const planFormatExcel = planStructure !== undefined
    ? planStructure?.map((d: any) => ({ ...d }))
    : [];

  planFormatExcel.forEach((item: any, index: number) => {
    Object.entries(item).forEach(([key, value]: any) => {
      planFormatExcel[index][key] = useCsvNumber(value);
    });
  });

  const planFormat = useProcessData(planStructure);

  return {
    planId,
    planType,
    planName,
    planColor,
    planFormat,
    planFormatExcel,
    refetch,
    isFetching,
    error,
    isLoading,
    columns,
    columnsExcel,
  };
}
