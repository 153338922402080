import Styled from 'styled-components';
import Theme from '../../../styles/Theme';

const Error = Styled.div`
  background-color: ${Theme.colorErrorBg};
  border-radius: ${Theme.radiusField};
  color: ${Theme.colorError};
  font-size: 0.72rem;
  font-weight: 600;
  line-height: 0.92;
  margin-top: -2px;
  margin-bottom: 0.72rem;
  padding: 0.62rem 0.82rem;
`;

export const Success = Styled.div`
  background-color: ${Theme.colorSuccessBg};
  border-radius: ${Theme.radiusField};
  color: ${Theme.colorSuccess};
  font-size: 0.72rem;
  font-weight: 600;
  line-height: 0.92;
  margin-top: -2px;
  margin-bottom: 0.72rem;
  padding: 0.62rem 0.82rem;
`;

export default Error;
