import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import Container from '../../atoms/Container/Container';
import GroupFilterGeneral from '../../molecules/Filter/GroupFilterGeneral';
import FilterModel from '../../molecules/Filter/FilterModel';
import useYear from '../../../hooks/useYear';
import { IFilter } from '../../../interfaces/IFilter.d';
import useSummaryTeamByPlan from '../../../hooks/useSummaryTeamByPlan';
import Theme from '../../../styles/Theme';
import {
  HeaderSummaryTeam,
  TitleSummaryTeam,
  LinkSummaryTeam,
} from '../../organisms/SummaryTeam/StyledSummaryTeam';
import CardDataExportXLSX from '../../molecules/Cards/CardDataExportXLSX';
import { Table } from '../../atoms/Table/Table';
import SummaryTeamCta from '../../organisms/SummaryTeam/SummaryTeamCta';
import TableSkeleton from '../../atoms/Table/TableSkeleton';
import Typography from '../../atoms/Typography/Typography';
import { Card } from '../../atoms/Card/Card';
import LinkTo from '../../atoms/Link/LinkTo';
import { ButtonBack } from '../../atoms/Button/StyledButton';
import useRemoveUndefinedProperties from '../../../hooks/useRemoveUndefinedProperties';

type Params = {
  [key: string]: string | number | undefined;
  timeframe?: string;
  startDate?: string;
  endDate?: string;
  grouping?: string;
  type?: string;
  status?: string;
  year?: number;
}

type PlanProps = {
  id: string,
  name: string,
  color: string,
}

export default function SummaryTeam() {
  const navigate = useNavigate();
  const { year } = useYear();

  const queryClient = useQueryClient();

  const user: any = queryClient?.getQueryState(['UserInfo']);
  const userId = user?.data?.me?.id;

  const groupingList: any = queryClient.getQueryState(['GroupingList']);
  const groupings = groupingList?.data?.groupingList?.grouping[year];

  const [globalFilter,
    setGlobalFilter,
  ] = useState<FilterModel>(new FilterModel());

  const selectedFilters: IFilter = {
    temporalidad: [], campaignType: [], rappelType: [], status: [], year: [],
  };

  const updateGlobalFilter = (newFilter: FilterModel) => setGlobalFilter(newFilter);

  const plans: PlanProps[] = [
    { id: 'campaign', name: 'Campañas', color: Theme.colorCampaign },
    { id: 'rappel', name: 'Rappeles', color: Theme.colorRappel },
    { id: 'convention', name: 'Convención', color: Theme.colorConvention },
    { id: 'ranking', name: 'Ranking', color: Theme.colorRanking },
  ];

  const summaryPlans = plans.map((plan: PlanProps) => {
    const variables: Params = {
      timeframe: (globalFilter.dateRange && globalFilter.dateRange.split(',')[0]) ? globalFilter.dateRange.split(',')[0].toString() : undefined,
      startDate: (globalFilter.dateRange && globalFilter.dateRange.split(',')[1]) ? globalFilter.dateRange.split(',')[1].toString() : undefined,
      endDate: (globalFilter.dateRange && globalFilter.dateRange.split(',')[2]) ? globalFilter.dateRange.split(',')[2].toString() : undefined,
      grouping: globalFilter.grouping || undefined,
      type: `${plan.id?.charAt(0).toUpperCase()}${plan.id?.substring(1)}`,
      status: globalFilter.status ? globalFilter.status : undefined,
      year,
    };

    useRemoveUndefinedProperties(variables);

    const {
      planId,
      planType,
      planName,
      planColor,
      refetch,
      error,
      isFetching,
      isLoading,
      columns,
      columnsExcel,
      planFormat,
      planFormatExcel,
    } = useSummaryTeamByPlan(variables, userId, plan.id, plan.name, plan.color);

    useEffect(() => {
      refetch();
    }, [globalFilter.dateRange, globalFilter.grouping, globalFilter.status]);

    return {
      planId,
      planName,
      planColor,
      planType,
      planFormat,
      planFormatExcel,
      refetch,
      error,
      isFetching,
      isLoading,
      columns,
      columnsExcel,
    };
  });

  const hasAnyError = summaryPlans.some((s: any) => s.error);
  if (hasAnyError) {
    return <Container>Error</Container>;
  }

  return (
    <Container>
      <ButtonBack onClick={() => navigate('/team/summary')} type="button" style={{ margin: '0 0 1rem' }}>Volver</ButtonBack>
      <div className="grid-item">
        <GroupFilterGeneral
          selectedFilters={selectedFilters}
          filter={globalFilter}
          setGlobalFilter={updateGlobalFilter}
        />
        {summaryPlans.map((item: any, index: number) => (
          <div key={`${index * 2}`}>
            <HeaderSummaryTeam>
              <TitleSummaryTeam theme={item.planColor}>
                {item.planName}
              </TitleSummaryTeam>
              <LinkSummaryTeam to={{ pathname: `/team/${item.planId}/resumen` }} theme={item.planColor}>
                Ver detalle
              </LinkSummaryTeam>
            </HeaderSummaryTeam>
            {item.columnsExcel && item.planFormatExcel && (
              <CardDataExportXLSX
                headers={item.columnsExcel}
                data={item.planFormatExcel}
                name="resumen_campaign_my_team"
              />
            )}
            {/* eslint no-nested-ternary: 0 */}
            {(!item.isLoading && !item.isFetching)
              ? (item.planFormat?.length > 0 ? (
                <Table
                  columns={item.columns}
                  data={item.planFormat}
                  theme={item.planColor}
                />
              ) : (
                item.planId === 'ranking'
                  ? (
                    <div className="list-cta">
                      <LinkTo to={{ pathname: '/team/ranking' }} className="btn-outline">
                        <span>Ver ficheros de rankings</span>
                      </LinkTo>
                    </div>
                  )
                  : (
                    <Card background="#FFF" padding="0.32rem" margin="0 0 1.5rem">
                      <Typography display="block" align="center" margin="1rem 0" size="16px"> No hay datos</Typography>
                    </Card>
                  )
              ))
              : (
                <TableSkeleton
                  countCell={6}
                  theme={item.planColor}
                />
              )}
            <div className="list-cta">
              <SummaryTeamCta plan={item.planId} groupings={groupings} />
            </div>
          </div>
        ))}
      </div>
    </Container>
  );
}
