import { gql } from 'graphql-request';

const RESPONSE_TICKET_GQL = gql`
  mutation responseTicket(
    $ticketId: ID!,
    $body: String!,
    $action: String!,
    $files: [String!]
  ) {
    responseTicket(
      ticketId: $ticketId,
      body: $body,
      action: $action,
      files: $files
    ) {
      body
      createdAt
      id
      internalId
      openedAt
      planId
      relatedUserId
      subject
      ticketStatus
      ticketType
      files
      updatedAt
    }
  }
`;

export default RESPONSE_TICKET_GQL;
