import {
  useState, useEffect, Fragment, useRef,
} from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import { columnsSummary, headers } from './dataRappelsTeam';
import Theme from '../../../styles/Theme';
import Container from '../../atoms/Container/Container';
import Typography from '../../atoms/Typography/Typography';
import { LinkTable, Table } from '../../atoms/Table/Table';
import { Tabs, TabPanel } from '../../atoms/Tabs';
import GET_MY_TEAM_PLANS_BY_PARAMS_GQL from '../../../graphql/queries/getMyTeamPlansByParamsGql';
import LinkTo from '../../atoms/Link/LinkTo';
import Score from '../../molecules/Score/Score';
import useNumberFormat from '../../../hooks/userNumberFormat';
import FilterModel from '../../molecules/Filter/FilterModel';
import { IFilter } from '../../../interfaces/IFilter.d';
import GET_MY_TEAM_DATA_FILTERS_GQL from '../../../graphql/queries/getMyTeamDataFiltersGql';
import FilterForm from '../../organisms/FilterForm/FilterForm';
import { Card } from '../../atoms/Card/Card';
import GroupFilterGeneral from '../../molecules/Filter/GroupFilterGeneral';
import useFormatDate from '../../../hooks/useDate';
import useCsvNumber from '../../../hooks/useCsvNumber';
import GET_TEAM_RESULTS_GQL from '../../../graphql/queries/getTeamResultsGql';
import CardDataExportXLSX from '../../molecules/Cards/CardDataExportXLSX';
import useProcessData from '../../../hooks/useProcessData';
import SubscriptionButton from '../../molecules/Subscriptios/SubscriptionButton';
import GET_MY_INFO_GQL from '../../../graphql/queries/getCurrentUserInfoGql';
import { PlanByType } from '../../../interfaces/IPlan.d';
import FilterFormSkeleton from '../../organisms/FilterForm/FilterFormSkeleton';
import TableSkeleton from '../../atoms/Table/TableSkeleton';
import useYear from '../../../hooks/useYear';
import SkeletonCard from '../../atoms/Card/SkeletonCard';
import { createGraphQLClient } from '../../../graphql/graphqlClient';
import { ButtonBack } from '../../atoms/Button/StyledButton';

export interface TeamRappelsParams {
  id: string;
}

export default function RappelsTeam({ id }:TeamRappelsParams) {
  const graphQLClient = createGraphQLClient();
  const { grouping } = useParams();
  const navigate = useNavigate();
  const [globalFilter, setGlobalFilter] = useState<FilterModel>(new FilterModel());
  const selectedFilters: IFilter = { temporalidad: [] };
  const [activeTab, setActiveTab] = useState(grouping || id || '');
  const [activeSubtab, setActiveSubtab] = useState('');
  const [tabs, setTabs] = useState<any>([]);
  const type = 'Rappel';
  const { year } = useYear();
  const queryClient = useQueryClient();
  let dataRappels:Array<[]> = [];

  const updateGlobalFilter = (newFilter: FilterModel) => setGlobalFilter(newFilter);

  const variablesFilters = activeTab !== 'resumen' ? {
    grouping: activeTab.toUpperCase(),
    subgrouping: activeSubtab !== 'resumen' ? activeSubtab?.toUpperCase() : '',
    type,
    year,
  } : {
    type,
    year,
  };

  const variablesResults = activeTab !== 'resumen' ? {
    grouping: activeTab.toUpperCase(),
    subgrouping: activeSubtab !== 'resumen' ? activeSubtab?.toUpperCase() : '',
    type,
    myTeam: true,
    status: globalFilter.status,
    year,
  } : {
    type,
    myTeam: true,
    status: globalFilter.status,
    year,
  };

  const rappelParams:any = {
    type,
    grouping: activeTab !== 'resumen' ? activeTab.toUpperCase() : undefined,
    subgrouping: activeSubtab !== 'resumen' ? activeSubtab?.toUpperCase() : '',
    timeframe: (globalFilter.dateRange && globalFilter.dateRange.split(',')[0]) ? globalFilter.dateRange.split(',')[0].toString() : undefined,
    startDate: (globalFilter.dateRange && globalFilter.dateRange.split(',')[1]) ? globalFilter.dateRange.split(',')[1].toString() : undefined,
    endDate: (globalFilter.dateRange && globalFilter.dateRange.split(',')[2]) ? globalFilter.dateRange.split(',')[2].toString() : undefined,
    category: globalFilter.category ? globalFilter.category : undefined,
    dgt: globalFilter.dgt ? globalFilter.dgt : undefined,
    dt: globalFilter.dt ? globalFilter.dt : undefined,
    od: globalFilter.od ? globalFilter.od : undefined,
    firstName: globalFilter.firstName ? globalFilter.firstName : undefined,
    lastName: globalFilter.lastName ? globalFilter.lastName : undefined,
    nif: globalFilter.nif ? globalFilter.nif : undefined,
    search: globalFilter.search ? globalFilter.search : '',
    year,
    status: globalFilter.status ? globalFilter.status : '',
  };
  Object.keys(rappelParams).forEach(
    (key:any) => (rappelParams[key] === undefined || rappelParams[key] === '') && delete rappelParams[key],
  );

  const {
    data: dataFilters,
    error: errorFilters,
    isFetching: isFetchingFilters,
  } = useQuery(
    ['RappelTeamFilters', variablesFilters],
    async () => (graphQLClient && graphQLClient.request(
      GET_MY_TEAM_DATA_FILTERS_GQL,
      variablesFilters,
    )),
    {
      staleTime: Infinity,
    },
  );

  const {
    data: planRappel,
    error: errorRappel,
    refetch: refetchRappel,
    isLoading: isLoadingRappel,
    isFetching: isFetchingRappel,
    status: statusRappel,
  } = useQuery(
    ['RappelTeam', rappelParams],
    async () => (graphQLClient && graphQLClient.request(
      GET_MY_TEAM_PLANS_BY_PARAMS_GQL,
      rappelParams,
    )),
    {
      staleTime: Infinity,
    },
  );

  const { data: dataUser } = useQuery(
    ['User'],
    async () => (graphQLClient && graphQLClient.request(GET_MY_INFO_GQL)),
  );

  const userId = dataUser?.me?.id;
  const groupingList :any = queryClient.getQueryState(['GroupingList']);

  const getTabs = () => {
    let response = [];
    const list = groupingList?.data?.groupingList?.grouping;
    if (list) {
      response = list[year].Rappel;
    }
    return response;
  };

  const {
    data: dataGetResults,
    refetch: refetchResults,
    isFetching: isFetchingResults,
    status: statusResults,
  } = useQuery(
    ['RappelTeamResults', variablesResults],
    async () => (graphQLClient && graphQLClient.request(
      GET_TEAM_RESULTS_GQL,
      variablesResults,
    )),
    {
      staleTime: Infinity,
    },
  );

  useEffect(() => {
    setGlobalFilter({ ...globalFilter, grouping: activeTab });
    setTabs(getTabs());
    if (!isFetchingRappel && statusResults !== 'success') {
      refetchResults();
    }
  }, []);

  useEffect(() => {
    setTabs(getTabs());
  }, [groupingList]);

  useEffect(() => {
    navigate(`/team/rappel/${activeTab}`, { replace: true });
    setGlobalFilter({ ...globalFilter, grouping: activeTab });

    const completeActiveTab :any = tabs.find((tab: any) => tab.id === activeTab);
    if (completeActiveTab?.subgroupings) {
      setActiveSubtab(completeActiveTab?.subgroupings[0].id);
    }
  }, [activeTab]);

  useEffect(() => {
    setTabs(getTabs());
    if (statusRappel !== 'success') {
      refetchRappel();
    }
    if (statusResults !== 'success') {
      refetchResults();
    }
  }, [globalFilter]);

  useEffect(() => {
    if (statusRappel !== 'success' && (globalFilter.type || globalFilter.grouping)) {
      refetchRappel();
    }
    if (statusResults !== 'success' && (globalFilter.type || globalFilter.grouping)) {
      refetchResults();
    }
  }, [globalFilter.type, globalFilter.grouping]);

  const debouncedSearch = (value :any, currentGlobalFilter :FilterModel) => {
    setGlobalFilter({ ...currentGlobalFilter, search: value });
  };

  const setServicesValueDebounced = useRef(_.debounce(debouncedSearch, 1000));

  const handleChange = (e :any) => {
    setServicesValueDebounced.current(e.currentTarget.value, globalFilter);
  };

  dataRappels = planRappel?.myTeamPlans?.nodes?.map((plan:PlanByType) => ({
    plan: plan?.description,
    amount: useNumberFormat(plan?.resultsAmount, true),
    meets: plan?.winsNumber,
    situation: plan?.planStatusName,
    comply: plan?.fulfillsNumber,
    participants: useNumberFormat(plan?.participantsNumber),
    dataDate: plan?.dataDate !== undefined ? useFormatDate(plan?.dataDate) : plan?.dataDate,
    details: <LinkTo to={`/team/rappel/resumen/${plan?.code}`} color={Theme.colorPrimary}>Ver detalle</LinkTo>,
    timeframe: plan?.timeframe,
    startDate: plan?.startDate,
    endDate: plan?.endDate,
    grouping: plan?.grouping,
    subscribed: <SubscriptionButton
      subscribed={plan.subscribed}
      planId={plan.id}
      userId={userId}
    />,
    download: <LinkTable url={plan?.basesUrl} title="Descargar" />,
    helpLink: <LinkTo to={`/ticket-create/${plan?.id}`} color={Theme.colorPrimary} style={{ display: 'block', textAlign: 'center' }}>Incidencia/Consulta</LinkTo>,
  }));

  const rappelsByTimeFrame = dataRappels && dataRappels.reduce((rv:any, x:any) => {
    let dynamicTimeFrame = '';
    if (x.timeframe === 'TRIMESTRAL') {
      if (x.startDate.split('-')[1] === '01') {
        dynamicTimeFrame = '1T';
      } else if (x.startDate.split('-')[1] === '04') {
        dynamicTimeFrame = '2T';
      } else if (x.startDate.split('-')[1] === '07') {
        dynamicTimeFrame = '3T';
      } else if (x.startDate.split('-')[1] === '10') {
        dynamicTimeFrame = '4T';
      }
    }
    if (dynamicTimeFrame.length > 0) {
      // eslint-disable-next-line no-param-reassign
      (rv[dynamicTimeFrame] = rv[dynamicTimeFrame] || []).push(x);
    } else {
      // eslint-disable-next-line no-param-reassign
      (rv[x.timeframe] = rv[x.timeframe] || []).push(x);
    }
    return rv;
  }, {});

  const orderedCamps:any = {
    '1T': rappelsByTimeFrame?.['1T'] || [],
    '2T': rappelsByTimeFrame?.['2T'] || [],
    '3T': rappelsByTimeFrame?.['3T'] || [],
    '4T': rappelsByTimeFrame?.['4T'] || [],
    Anual: rappelsByTimeFrame?.ANUAL || [],
    Semestral: rappelsByTimeFrame?.SEMESTRAL || [],
    Mensual: rappelsByTimeFrame?.MENSUAL || [],
    Interanual: rappelsByTimeFrame?.INTERANUAL || [],
    Semanal: rappelsByTimeFrame?.SEMANAL || [],
    Quincenal: rappelsByTimeFrame?.QUINCENAL || [],
    Bimestral: rappelsByTimeFrame?.BIMESTRAL || [],
    Cuatrimestral: rappelsByTimeFrame?.CUATRIMESTRAL || [],
  };

  const csvData:any = {};
  Object.keys(orderedCamps)
    .forEach((k) => {
      csvData[k] = [];
      if (orderedCamps[k].length) {
        Object.entries(orderedCamps[k])?.forEach(([key]: any) => {
          csvData[k][key] = {};
          Object.entries(orderedCamps[k][key])?.forEach(([key2, value2]: any) => {
            csvData[k][key][key2] = useCsvNumber(value2);
          });
        });
      }
      return null;
    });

  const contadores:any = {};
  Object.keys(orderedCamps)
    .map((k) => {
      if (orderedCamps[k].length) {
        let winCounter = 0;
        let amountCounter = 0;
        let participantsCounter = 0;
        let complyCounter = 0;
        // eslint-disable-next-line no-return-assign
        const counter = orderedCamps[k].map((e:any) => {
          const h = {
            win: (winCounter += Number(e.meets) || 0),
            amount: (amountCounter += Number(e.amount) || 0),
            participants: (participantsCounter += Number(e.participants) || 0),
            comply: (complyCounter += Number(e.comply) || 0),
          };
          return h;
        })[orderedCamps[k].length - 1];
        contadores[k] = counter;
      }
      return null;
    })
    .filter((b) => b !== null);

  const results = dataGetResults?.summaryGlobalPositionChartByTimeframe;
  const scoreIndicators = (k: any) => {
    const participants = results !== undefined ? Object.entries(results?.participantsCount[0])
      .find(([key]) => k === key) : '';
    const metCount = results !== undefined ? Object.entries(results?.totalUserMetCount[0])
      .find(([key]) => k === key) : '';
    const amount = results !== undefined ? Object.entries(results?.totalAmount[0])
      .find(([key]) => k === key) : '';
    const clover = results !== undefined ? Object.entries(results?.totalTreboles[0])
      .find(([key]) => k === key) : '';

    const score = [
      {
        id: 0,
        title: 'Participa',
        subttitle: '',
        score: useNumberFormat(participants?.[1]),
        icon: 'participant',
      },
      {
        id: 1,
        title: 'Cumplen',
        subttitle: '',
        score: useNumberFormat(metCount?.[1]),
        icon: 'comply',
      },
      {
        id: 2,
        title: 'Importe',
        subttitle: '',
        score: useNumberFormat(amount?.[1], true),
        icon: 'euro',
      },
      {
        id: 2,
        title: 'Tréboles',
        subttitle: '',
        score: useNumberFormat(clover?.[1]),
        icon: 'clover',
      },
    ];
    return score;
  };

  if (errorRappel || errorFilters) {
    return <Container>Error</Container>;
  }

  return (
    <Container>
      <ButtonBack onClick={() => navigate('/team/summary/general')} type="button">Volver</ButtonBack>
      <Typography color={Theme.colorRappel} size="32px" margin="0 0 1rem" display="block" align="center" weight="500">Rappeles</Typography>
      <Card background="transparent" display="flex" alignItems="flex-start" justifyContent="space-between">
        <GroupFilterGeneral
          selectedFilters={selectedFilters}
          filter={globalFilter}
          setGlobalFilter={updateGlobalFilter}
        />
        <input
          type="text"
          placeholder="Buscar por nombre de rappel"
          onChange={handleChange}
          onPaste={handleChange}
          className="search"
          style={{ height: '38px', maxWidth: '450px', margin: '1rem 0 0' }}
        />
      </Card>

      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        onChangeTab={setActiveTab}
        theme={Theme.colorRappel}
        activeSubtab={activeSubtab}
        onChangeSubtab={setActiveSubtab}
      />

      <TabPanel isActivePanel id="rappel-tab-panel">
        {(activeTab !== 'resumen') && (
          <div style={{ textAlign: 'right', margin: '3rem 0 2rem' }}>
            <LinkTo
              to={`/team/rappel/results/${activeTab}?code=${planRappel?.myTeamPlans?.nodes[0]?.code}`}
              background={Theme.colorRappel}
              border="8px"
              color={Theme.colorWhite}
              padding="0.72rem 1.2rem"
              underline="none"
            >
              {`Ver resultados nominales ${activeTab}`}
            </LinkTo>
          </div>
        )}

        {!isFetchingFilters ? (
          <FilterForm
            dataFilters={dataFilters}
            filter={globalFilter}
            setFilter={updateGlobalFilter}
            type="Rappel"
            grouping={activeTab}
            fetching={isFetchingRappel}
          />
        ) : <FilterFormSkeleton countFilters={10} />}

        {(!isLoadingRappel
          && !isFetchingRappel
          && columnsSummary
          && dataRappels
          && dataFilters) ? (
            Object.keys(orderedCamps).map((k:any) => (
              orderedCamps[k].length ? (
                <Fragment key={k}>
                  <Typography
                    align="center"
                    color={Theme.colorRappel}
                    display="block"
                    size="28px"
                    margin="2rem auto"
                    weight="500"
                  >
                    {k === '1T' && 'Trimestre 1'}
                    {k === '2T' && 'Trimestre 2'}
                    {k === '3T' && 'Trimestre 3'}
                    {k === '4T' && 'Trimestre 4'}
                    {k === 'Semanal' && 'Semanal'}
                    {k === 'Quincenal' && 'Quincenal'}
                    {k === 'Mensual' && 'Mensual'}
                    {k === 'Bimestral' && 'Bimestral'}
                    {k === 'Cuatrimestral' && 'Cuatrimestral'}
                    {k === 'Semestral' && 'Semestral'}
                    {k === 'Anual' && 'Anual'}
                    {k === 'Interanual' && 'Interanual'}
                  </Typography>
                  <Card
                    background={Theme.colorWhite}
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                    gap="1rem"
                    margin="0"
                  >
                    {headers && csvData[k].length > 0 && (
                      <CardDataExportXLSX
                        headers={headers}
                        data={csvData[k]}
                        margin="0 0 0.5rem auto"
                        name={`rappel_team_${activeTab}_${k.toLowerCase()}`}
                      />
                    )}
                  </Card>
                  <Table
                    columns={columnsSummary}
                    data={useProcessData(orderedCamps[k])}
                    theme={Theme.colorRappel}
                    className="tb-rappel__team"
                  />

                  {!isFetchingResults ? (
                    <Score score={scoreIndicators(k)} cols={4} maxWidth="600px" margin="0 auto 4rem auto" />
                  ) : (<SkeletonCard cols={4} maxWidth="600px" />)}

                </Fragment>
              ) : ''
            ))
          ) : <TableSkeleton countCell={6} theme={Theme.colorRappel} />}
      </TabPanel>
    </Container>
  );
}
