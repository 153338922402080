import Styled from 'styled-components';
import Theme from '../../../styles/Theme';

export const DetailTicketContainer = Styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
`;

export const DetailTicketContainerContent = Styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  max-width: 60%;
`;

export const CancelButton = Styled.button`
  background-color: ${Theme.colorPrimary};
  cursor: pointer;
  font-weight: 400;
  padding: 10px;
  border: none;
  font-size: 18px;
  border-radius: ${Theme.radius};
  color: ${(props) => (props.color ? props.color : Theme.colorWhite)};
  position: absolute;
  right: 22%;
  top: 295px;
  z-index: 99;
  align-self: flex-end;
  &:hover {
      box-shadow: ${Theme.shadow};
      opacity: 0.7;
    }
`;

export const AcceptButton = Styled.button`
  background-color: ${Theme.colorPrimary};
  cursor: pointer;
  font-weight: 400;
  padding: 10px;
  border: none;
  font-size: 16px;
  border-radius: ${Theme.radius};
  color: ${(props) => (props.color ? props.color : Theme.colorWhite)};
  &:hover {
      box-shadow: ${Theme.shadow};
      opacity: 0.7;
    }
`;

export const ResponseButton = Styled.button`
  background-color: ${Theme.colorPrimary};
  cursor: pointer;
  font-weight: 400;
  padding: 10px;
  border: none;
  font-size: 16px;
  border-radius: ${Theme.radius};
  color: ${(props) => (props.color ? props.color : Theme.colorWhite)};
  position: absolute;
  right: 22%;
  z-index: 99;
  &:hover {
      box-shadow: ${Theme.shadow};
      opacity: 0.7;
    }
`;

export const ButtonBack = Styled.button<{color?: string}>`
  appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  text-decoration: underline;
  color: ${Theme.colorPrimary};
  font-weight: 500;
  font-size: 20px;

  &:before {
    content: "<";
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
  }
`;

export const BodyContainer = Styled.div`
  background-color: ${Theme.colorWhite};
  padding: 1rem;
  marginBottom: 1.5rem;
`;

export default DetailTicketContainer;
