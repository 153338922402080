import useFormatDate from './useDate';
import useNumberFormat from './userNumberFormat';

export interface IRenderSubTable {
  resultsValues: any[];
  extraFieldsValues: any[];
  planLevelsValues: string[];
  planType: string;
  translations: any;
  groupedItems?: string;
}

export default function useRenderSubTable({
  resultsValues, extraFieldsValues, planLevelsValues, translations, groupedItems,
}: IRenderSubTable) {
  const translationsValues = JSON.parse(translations);

  const listNumericValues = extraFieldsValues?.map((item: any) => (
    Object?.fromEntries(Object?.entries(item)
      ?.filter(([key, value]) => (typeof (value) === 'number') && key))));

  if (listNumericValues !== undefined && listNumericValues.length > 0) {
    listNumericValues?.forEach((value: any, index:number) => {
      Object.keys(listNumericValues[index])?.map((column:any) => {
        if (!translationsValues[column]?.includes('decimal') && !translationsValues[column]?.includes('(porcentaje)')) {
          listNumericValues[index][column] = `${useNumberFormat(listNumericValues[index][column], false)}`;
        }
        if (translationsValues[column]?.includes('decimal')) {
          listNumericValues[index][column] = `${useNumberFormat(listNumericValues[index][column], true)}`;
        }
        if (translationsValues[column]?.includes('(porcentaje)') || translationsValues[column]?.includes('Porcentaje')) {
          listNumericValues[index][column] = `${useNumberFormat(Number(listNumericValues[index][column]) * 100, true)}%`;
        }
        return column;
      });
    });
  }

  const listNumericValuesFiltered:any = {};
  if (listNumericValues.length > 0) {
    Object.keys(listNumericValues[0]).map((e:any) => {
      listNumericValuesFiltered[e.split('.').join('')] = listNumericValues[0][e];
      return listNumericValuesFiltered;
    });
  }
  const listRestOfValues = extraFieldsValues?.map((item: any) => (
    Object?.fromEntries(Object?.entries(item)
      ?.filter(([key, value]) => (typeof (value) !== 'number' && key)))
  ));

  const extraFieldsValuesMerged = [listNumericValuesFiltered]
    ?.map((item: any, i:number) => ({ ...item, ...listRestOfValues[i] }));

  const mergeAllValues = resultsValues
    ?.map((item: any, i:number) => ({ ...item, ...extraFieldsValuesMerged[i] }));

  const formattedValues = mergeAllValues?.map((plan: any) => ({
    ...plan,
    dataDate: useFormatDate(plan?.dataDate),
    updateDate: useFormatDate(plan?.updateDate),
    amount: useNumberFormat(plan?.resultsAmount),
    DGT: `${plan?.dgt} - ${plan?.territoryNameDgt}`,
    DT: plan?.territoryNameDt ? `${plan?.dt} - ${plan?.territoryNameDt}` : plan?.dt,
    OD: plan?.territoryNameOd ? `${plan?.od} - ${plan?.territoryNameOd}` : plan?.od,
    NIF: plan?.nif,
    'FECHA DATO': plan?.dataDate !== undefined ? useFormatDate(plan?.dataDate) : useFormatDate(plan?.['FECHA ACT']),
  }));
  const processedValues = mergeAllValues
    ?.map((item: any, i:number) => ({ ...item, ...formattedValues[i] }));

  const processedValuesCsv = processedValues;

  const distinctKeys: any = [];
  const keys = planLevelsValues
    ?.map((level: any) => level?.field)
    ?.reduce((acc: any, current: any) => {
      const x = acc.find((item: any) => item === current);
      return !x ? acc.concat([current]) : acc;
    }, [])
    ?.sort((a: any, b: any) => a.order - b.order);

  keys.forEach((element: any) => (!distinctKeys?.includes(element) || !groupedItems?.split(',')?.map((gItem) => element.includes(gItem)).includes(true)
    ? distinctKeys.push(element)
    : (distinctKeys?.splice(distinctKeys?.indexOf(element), 1)
        && distinctKeys.push(element))));

  const planLevels1 = planLevelsValues?.filter((l:any) => l.level === '1' || l.level === 1)?.map((e:any) => e.field?.replace(/ /g, '').toLowerCase());

  const distinctFormattedKeys = distinctKeys.filter((e:any) => planLevels1.includes(e?.replace(/ /g, '').toLowerCase()));

  const formatColumn = (column: string) => {
    if (translationsValues[column]) {
      if (translationsValues[column]?.includes('(decimales)')) {
        return translationsValues[column]?.replace(/\(decimales\)/gi, '');
      }
      if (translationsValues[column]?.includes('porcentaje')) {
        return translationsValues[column]?.replace(/\(porcentaje\)/gi, '');
      }
      return translationsValues[column];
    }
    return column;
  };

  const processedKeys = distinctFormattedKeys?.map((col: string) => ({
    Header: formatColumn(col),
    accessor: col.replace(/^[ \t]+|[ \t]+$/gm, '').split('.').join(''),
  }));

  const processedKeysCsv = distinctKeys?.map((col: string) => ({
    label: formatColumn(col.split('.').join('')),
    key: col.replace(/^[ \t]+|[ \t]+$/gm, '').split('.').join(''),
  }));

  return {
    processedKeys, processedValues, processedKeysCsv, processedValuesCsv,
  };
}
