import { useQuery } from 'react-query';
import { useState } from 'react';
import { createGraphQLClient } from '../graphql/graphqlClient';
import useCategoryInfo from './useCategoryInfo';
import useYear from './useYear';
import USER_TICKETS from '../graphql/queries/getUserTicketsGql';
import GET_DROPDOWN_HELP_GQL from '../graphql/queries/getDropdownHelpGql';
import FilterModel from '../components/molecules/Filter/FilterModel';

export default function useHelp() {
  const graphQLClient = createGraphQLClient();
  const { year } = useYear();
  const { userInfo } = useCategoryInfo(year);
  const userId = userInfo?.me?.id;

  const [globalFilter, setGlobalFilter] = useState<FilterModel>(new FilterModel());

  const updateGlobalFilter = (newFilter: FilterModel) => setGlobalFilter(newFilter);

  const variables = {
    userId,
    year,
    startDate: globalFilter.startDate ? globalFilter.startDate : undefined,
    endDate: globalFilter.endDate ? globalFilter.endDate : undefined,
    planType: globalFilter.planType ? globalFilter.planType : undefined,
    ticketType: globalFilter.ticketType ? globalFilter.ticketType : undefined,
    ticketStatus: globalFilter.ticketStatus ? globalFilter.ticketStatus : undefined,
  };

  const {
    data: dataFilters,
    isFetching: isFetchingFilters,
  } = useQuery(
    ['Dropdown', year],
    async () => (graphQLClient && graphQLClient.request(GET_DROPDOWN_HELP_GQL, { year })),
    {
      staleTime: Infinity,
    },
  );

  const filters = {
    ...dataFilters,
    dropdownFilter: {
      ...dataFilters?.dropdownHelp,
      arrayPlanTypes: dataFilters?.dropdownHelp.arrayPlanType,
      arrayTicketStatuses: dataFilters?.dropdownHelp.arrayStatus,
      arrayTicketTypes: dataFilters?.dropdownHelp.arrayTicketType,
    },
  };

  delete filters?.dropdownHelp;
  delete filters?.dropdownFilter.arrayPlanType;
  delete filters?.dropdownFilter.arrayStatus;
  delete filters?.dropdownFilter.arrayTicketType;
  delete filters?.dropdownFilter.arrayPlans;

  const {
    data,
    isFetching,
  } = useQuery(
    ['UserTickets', variables],
    async () => (graphQLClient && graphQLClient.request(USER_TICKETS, variables)),
    {
      enabled: !!userId,
    },
  );

  data?.userTickets?.nodes?.map((ticket:any) => {
    ticket.subject = ticket.subject?.length > 50 ? `${ticket.subject.slice(0, 50)}...` : ticket.subject;
    return ticket;
  });

  const columns = [
    { Header: 'ID', accessor: 'internalId' },
    { Header: 'Asunto', accessor: 'subject' },
    { Header: 'Tipo de consulta', accessor: 'ticketType' },
    { Header: 'Estado', accessor: 'ticketStatus' },
    { Header: 'Plan', accessor: 'planName' },
    { Header: 'Usuario', accessor: 'userName' },
    { Header: 'Fecha creación', accessor: 'createdAt' },
    { Header: 'Fecha apertura', accessor: 'openedAt' },
    { Header: 'Fecha fin', accessor: 'updatedAt' },
    { Header: 'Ver', accessor: 'detail' },
  ];

  return {
    data,
    isFetching,
    columns,
    filters,
    isFetchingFilters,
    globalFilter,
    updateGlobalFilter,
  };
}
