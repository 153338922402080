import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { ButtonBack } from '../../atoms/Button/StyledButton';
import Container from '../../atoms/Container/Container';
import GET_TERRITORY_USERS_GQL from '../../../graphql/queries/getTerritoriesUsersGql';
import { createGraphQLClient } from '../../../graphql/graphqlClient';
import useYear from '../../../hooks/useYear';
import FilterModel from '../../molecules/Filter/FilterModel';
import FilterFormSkeleton from '../../organisms/FilterForm/FilterFormSkeleton';
import Typography from '../../atoms/Typography/Typography';
import Theme from '../../../styles/Theme';
import FilterForm from '../../organisms/FilterForm/FilterForm';
import { LinkTable, Table } from '../../atoms/Table/Table';
import useColumnTableUserResults from '../../../hooks/useColumnTableUserResults';
import USER_RESULTS from '../../../graphql/queries/getUserResultsGql';
import useFormatDate from '../../../hooks/useDate';
import TableSkeleton from '../../atoms/Table/TableSkeleton';
import Button from '../../atoms/Button/Button';
import LinkTo from '../../atoms/Link/LinkTo';
import useNumberFormat from '../../../hooks/userNumberFormat';

export default function ResultsTeam() {
  const navigate = useNavigate();
  const graphQLClient = createGraphQLClient();
  const { year } = useYear();
  const [globalFilter, setGlobalFilter] = useState<FilterModel>(new FilterModel());
  const updateGlobalFilter = (newFilter: FilterModel) => setGlobalFilter(newFilter);
  const [clearFilter, setClearFilter] = useState<boolean>(false);
  const { columns } = useColumnTableUserResults();

  const variables = {
    dgt: globalFilter.dgt,
    dt: globalFilter.dt,
    od: globalFilter.od,
    year,
  };

  const {
    data: dataFilters,
    isFetching: isFetchingFilters,
    refetch: refetchFilters,
  } = useQuery(
    ['ResultsTeamFilters'],
    async () => (graphQLClient && graphQLClient.request(GET_TERRITORY_USERS_GQL, variables)),
    {
      staleTime: Infinity,
    },
  );

  const {
    data: dataNifFilter,
    isFetching: isFetchingNifFilter,
    refetch: refetchNifFilter,
  } = useQuery(
    ['ResultsNifFilter', variables],
    async () => (graphQLClient && graphQLClient.request(GET_TERRITORY_USERS_GQL, variables)),
    {
      staleTime: Infinity,
    },
  );

  const {
    data: dataResults,
    isFetching: isFetchingResults,
    refetch: refetchResults,
  } = useQuery(
    ['Results', globalFilter.id, globalFilter.status, globalFilter.planType],
    async () => (graphQLClient && graphQLClient.request(
      USER_RESULTS,
      {
        userId: String(globalFilter?.nif),
        planStatus: globalFilter?.status,
        planType: globalFilter?.planType,
        year,
      },
    )),
    {
      enabled: false,
      staleTime: Infinity,
    },
  );

  delete dataFilters?.dropdownTerritoriesUsers?.arrayUsersData;

  delete dataNifFilter?.dropdownTerritoriesUsers?.arrayDgts;
  delete dataNifFilter?.dropdownTerritoriesUsers?.arrayDts;
  delete dataNifFilter?.dropdownTerritoriesUsers?.arrayOds;
  delete dataNifFilter?.dropdownTerritoriesUsers?.arrayDgtsDtsOds;
  delete dataNifFilter?.dropdownTerritoriesUsers?.arrayDtsOds;
  delete dataNifFilter?.dropdownTerritoriesUsers?.arrayStatuses;
  delete dataNifFilter?.dropdownTerritoriesUsers?.arrayPlanStatus;
  delete dataNifFilter?.dropdownTerritoriesUsers?.arrayPlanTypes;

  let newArrayPlanStatus: any = [];
  if (dataFilters && dataFilters.dropdownTerritoriesUsers) {
    const { arrayPlanStatus } = dataFilters.dropdownTerritoriesUsers;
    newArrayPlanStatus = [
      { label: 'Todos', name: undefined },
      ...arrayPlanStatus.map((status: any) => ({ ...status })),
    ];
    dataFilters.dropdownTerritoriesUsers.arrayStatuses = newArrayPlanStatus;
  }

  if (dataNifFilter && dataNifFilter.dropdownTerritoriesUsers) {
    const { arrayUsersData } = dataNifFilter.dropdownTerritoriesUsers;
    dataNifFilter.dropdownTerritoriesUsers.arrayNifs = arrayUsersData;
  }

  const resultsAdapter = dataResults?.userResults?.map((item: any) => ({
    ...item,
    amount: useNumberFormat(item.amount),
    dataDate: item.dataDate !== undefined ? useFormatDate(item.dataDate) : '',
    download: item?.basesUrl !== '#' ? <LinkTable url={item?.basesUrl} title="Descargar" /> : '',
    planType: item?.planType === 'Campaign' ? 'Campaña' : item?.planType === 'Rappel' ? 'Rappel' : item?.planType === 'Convention' ? 'Convención' : '',
    result: item?.planCode ? <LinkTo to={`/team/campaign/resumen/${item?.planCode}`} color={Theme.colorPrimary} style={{ display: 'block', textAlign: 'center' }}>Ver resultados</LinkTo> : '',
  }));

  const handleClearFilters = () => {
    const clearedFilter = new FilterModel();
    setGlobalFilter(clearedFilter);
    setClearFilter(true);
  };

  useEffect(() => {
    if (clearFilter) {
      setClearFilter(true);
      refetchFilters();
      refetchNifFilter();
    }
  }, [clearFilter]);

  useEffect(() => {
    if (globalFilter.status !== undefined
      || globalFilter.nif !== undefined
      || globalFilter.planType !== undefined
      || ((!isFetchingFilters && !isFetchingNifFilter) && (globalFilter.status !== undefined || globalFilter.status !== 'in_progress'))
    ) {
      refetchResults();
    }
  }, [globalFilter.status, globalFilter.nif, globalFilter.planType]);

  useEffect(() => {
    setGlobalFilter({ ...globalFilter, nif: '' });
  }, [dataNifFilter]);

  return (
    <Container>
      <ButtonBack onClick={() => navigate('/team/summary/general')} type="button">Volver</ButtonBack>
      <Typography color={Theme.colorCampaign} size="32px" margin="0 0 1rem" display="block" align="center" weight="500">Resultados</Typography>

      <div className="filter__grid">
        <div className="filter__form_1">
          {(!isFetchingFilters) ? (
            dataFilters?.dropdownTerritoriesUsers && (
              <FilterForm
                dataFilters={dataFilters?.dropdownTerritoriesUsers}
                filter={globalFilter}
                setFilter={updateGlobalFilter}
                type=""
                fetching
                autoFilter={0}
                resetFilter={clearFilter}
              />
            )
          ) : <FilterFormSkeleton countFilters={3} buttons={false} />}
        </div>
        <div className="filter__form_2">
          {(!isFetchingNifFilter) ? (
            dataNifFilter?.dropdownTerritoriesUsers && (
              <FilterForm
                dataFilters={dataNifFilter.dropdownTerritoriesUsers}
                filter={globalFilter}
                setFilter={updateGlobalFilter}
                type=""
                fetching
                autoFilter={0}
                resetFilter={clearFilter}
              />
            )
          ) : <FilterFormSkeleton countFilters={1} buttons={false} />}
        </div>
      </div>

      <div className="filter__button">
        <Button type="button" onClick={handleClearFilters} padding="0.5rem 1rem" size={200}>
          Limpiar filtros
        </Button>
      </div>

      {!isFetchingResults ? (
        dataResults?.userResults !== undefined && (
          <Table
            columns={columns}
            data={resultsAdapter || []}
            theme={Theme.colorCampaign}
          />
        )
      ) : <TableSkeleton countCell={6} theme={Theme.colorCampaign} />}
    </Container>
  );
}
