import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  columns, renderRowSubComponent,
} from './dataRappelGlobalPosition';
import Container from '../../atoms/Container/Container';
import Typography from '../../atoms/Typography/Typography';
import Theme from '../../../styles/Theme';
import Score from '../../molecules/Score/Score';
import { Table } from '../../atoms/Table/Table';
import GroupFilterGeneral from '../../molecules/Filter/GroupFilterGeneral';
import { IFilter } from '../../../interfaces/IFilter.d';
import FilterModel from '../../molecules/Filter/FilterModel';
import usePlan from '../../../hooks/usePlan';
import useGlobalPosition from '../../../hooks/useGlobalPosition';
import useGlobalPositionCharts from '../../../hooks/useGlobalPositionCharts';
import useNumberFormat from '../../../hooks/userNumberFormat';
import useMappingColumnNames from '../../../hooks/useMappingColumnNames';
import useControlledColumns from '../../../hooks/useControlledColumns';
import useFormatDate from '../../../hooks/useDate';
import useCsvNumber from '../../../hooks/useCsvNumber';
import GET_CALCULATE_PROVISIONAL_AMOUNT from '../../../graphql/queries/getCalculateProvisionalAmount';
import CardDataExportXLSX from '../../molecules/Cards/CardDataExportXLSX';
import useProcessData from '../../../hooks/useProcessData';
import useYear from '../../../hooks/useYear';
import { ButtonBack } from '../../atoms/Button/StyledButton';
import TableSkeleton from '../../atoms/Table/TableSkeleton';
import SkeletonCard from '../../atoms/Card/SkeletonCard';
import FilterFormSkeleton from '../../organisms/FilterForm/FilterFormSkeleton';
import { createGraphQLClient } from '../../../graphql/graphqlClient';

export default function RappelsGlobalPosition() {
  const graphQLClient = createGraphQLClient();
  const [searchParams] = useSearchParams();
  const [globalFilter, setGlobalFilter] = useState<FilterModel>(new FilterModel());
  const mediadores = ['AFIS', 'AGENTES', 'AGENTES', 'AGENTES', 'APS', 'DELEGADOS', 'MEDIACONCES', 'MEDIAINMOBI', 'MEDIASALUD', 'MEDIAVIDA', 'GDLG'];
  const location = useLocation();
  const [filters, setFilters] = useState<IFilter>({});
  const updateGlobalFilter = (newFilter: FilterModel) => setGlobalFilter(newFilter);
  const { year: currentYear } = useYear();
  const navigate = useNavigate();

  const variables:any = {
    type: 'Rappel',
    grouping: globalFilter.grouping,
    subgrouping: globalFilter.subgrouping,
    timeframe: (globalFilter.dateRange && globalFilter.dateRange.split(',')[0]) ? globalFilter.dateRange.split(',')[0].toString() : undefined,
    startDate: (globalFilter.dateRange && globalFilter.dateRange.split(',')[1]) ? globalFilter.dateRange.split(',')[1].toString() : undefined,
    endDate: (globalFilter.dateRange && globalFilter.dateRange.split(',')[2]) ? globalFilter.dateRange.split(',')[2].toString() : undefined,
    level: globalFilter.level,
    year: currentYear,
    status: globalFilter.status,
  };

  Object.keys(variables)?.forEach(
    (key:any) => (variables[key] === undefined || variables[key] === '') && delete variables[key],
  );

  const {
    data, error, refetch, isFetching,
  } = usePlan(variables);

  function getVariables() {
    return {
      type: 'Rappel',
      grouping: globalFilter.grouping,
      subgrouping: globalFilter.subgrouping,
      timeframe: (globalFilter.dateRange && globalFilter.dateRange.split(',')[0]) ? globalFilter.dateRange.split(',')[0].toString() : undefined,
      startDate: (globalFilter.dateRange && globalFilter.dateRange.split(',')[1]) ? globalFilter.dateRange.split(',')[1].toString() : undefined,
      endDate: (globalFilter.dateRange && globalFilter.dateRange.split(',')[2]) ? globalFilter.dateRange.split(',')[2].toString() : undefined,
      level: globalFilter.level,
      myTeam: false,
      year: currentYear,
      status: globalFilter.status,
    };
  }

  const {
    data: gpr,
    isFetching: isFetchingGpr,
    refetch: refetchChart,
  } = useGlobalPositionCharts(getVariables());
  const resultsRappel = gpr?.summaryGlobalPositionChartByTimeframe;

  const varsProvisionalAmount = {
    userId: data !== undefined && data?.me?.id,
    type: 'Rappel',
    timeframe: globalFilter?.dateRange?.split(',')[0],
    startDate: (globalFilter.dateRange && globalFilter.dateRange.split(',')[2]) ? globalFilter.dateRange.split(',')[1].toString() : '',
    endDate: (globalFilter.dateRange && globalFilter.dateRange.split(',')[2]) ? globalFilter.dateRange.split(',')[2].toString() : '',
    year: currentYear,
    status: globalFilter.status,
  };

  function getScore(hashData:any) {
    if (globalFilter.dateRange) {
      switch (globalFilter?.dateRange) {
      case 'ANUAL':
        return hashData.Anual;
      case 'SEMESTRAL':
        return hashData.Semestral;
      case 'INTERANUAL':
        return hashData.Interanual;
      case 'MENSUAL':
        return hashData.Mensual;
      case 'TRIMESTRAL':
        if (variables?.startDate === `${currentYear}-01-01`) {
          return hashData['1T'];
        } if (variables?.startDate === `${currentYear}-04-01`) {
          return hashData['2T'];
        } if (variables?.startDate === `${currentYear}-07-01`) {
          return hashData['3T'];
        }
        return hashData['4T'];
      default:
        return hashData?.total;
      }
    }
    return hashData?.total;
  }

  const {
    data: provisionalAmount,
    refetch: refetchProvisionalAmount,
  } = useQuery(
    ['CampaignCalculateProvisionalAmount', varsProvisionalAmount],
    async () => (graphQLClient && graphQLClient.request(
      GET_CALCULATE_PROVISIONAL_AMOUNT,
      varsProvisionalAmount,
    )),
    {
      refetchOnMount: false,
      enabled: false,
    },
  );

  const provAmount = provisionalAmount?.calculateProvisionalAmount?.provisionalAmount;

  const scoreRappel = [{
    id: 0, title: 'Ganados', subtitle: 'a fecha', score: getScore(resultsRappel?.totalPlanMetCount[0]), icon: 'cup',
  },
  {
    id: 1, title: 'provisional', maintitle: 'Importe gana', score: useNumberFormat(provAmount, true), icon: 'euro',
  }];

  const { getPlan: plan } = useGlobalPosition(data);

  useEffect(() => {
    if (!isFetching && !isFetchingGpr) {
      setFilters({
        temporalidad: [], rappelType: [], year: [], status: [],
      });
      refetchProvisionalAmount();
    }
  }, [isFetching, isFetchingGpr, globalFilter.dateRange]);

  useEffect(() => {
    refetch();
    if (!isFetching) {
      refetchChart();
      refetchProvisionalAmount();
    }
  }, [globalFilter.dateRange,
    globalFilter.grouping,
    globalFilter.subgrouping,
    globalFilter.status]);

  useEffect(() => {
    switch (searchParams.get('timeframe')) {
    case '1T':
      setTimeout(
        () => {
          setGlobalFilter({
            ...globalFilter,
            dateRange: `TRIMESTRAL,${currentYear}-01-01,${currentYear}-03-31`,
          });
        },
        1200,
      );
      break;
    case '2T':
      setTimeout(
        () => {
          setGlobalFilter({
            ...globalFilter,
            dateRange: `TRIMESTRAL,${currentYear}-04-01,${currentYear}-06-30`,
          });
        },
        1200,
      );
      break;
    case '3T':
      setTimeout(
        () => {
          setGlobalFilter({
            ...globalFilter,
            dateRange: `TRIMESTRAL,${currentYear}-07-01,${currentYear}-09-30`,
          });
        },
        1200,
      );
      break;
    case '4T':
      setTimeout(
        () => {
          setGlobalFilter({
            ...globalFilter,
            dateRange: `TRIMESTRAL,${currentYear}-10-01,${currentYear}-12-31`,
          });
        },
        1200,
      );
      break;
    case 'Anual':
      setTimeout(
        () => {
          setGlobalFilter({
            ...globalFilter,
            dateRange: 'ANUAL',
          });
        },
        1200,
      );
      break;
    case 'Semanal':
      setTimeout(
        () => {
          setGlobalFilter({
            ...globalFilter,
            dateRange: 'SEMANAL',
          });
        },
        1200,
      );
      break;
    case 'Quincenal':
      setTimeout(
        () => {
          setGlobalFilter({
            ...globalFilter,
            dateRange: 'QUINCENAL',
          });
        },
        1200,
      );
      break;
    case 'Bimestral':
      setTimeout(
        () => {
          setGlobalFilter({
            ...globalFilter,
            dateRange: 'BIMESTRAL',
          });
        },
        1200,
      );
      break;
    case 'Cuatrimestral':
      setTimeout(
        () => {
          setGlobalFilter({
            ...globalFilter,
            dateRange: 'CUATRIMESTRAL',
          });
        },
        1200,
      );
      break;
    default:
      break;
    }
  }, [location]);

  if (error) {
    return <Container>Error</Container>;
  }

  const plans = data?.me?.plans?.nodes?.map((p:any) => p);

  const results = data?.me?.plans?.nodes
    ?.map((p:any) => p?.results?.nodes
      ?.map((r:any) => r));

  const extra = data?.me?.plans?.nodes
    ?.map((p:any) => p?.results?.nodes
      ?.map((r:any) => r?.extraFields));

  const merge = plans?.map((p:any, i:any) => ({ ...p, ...results[i][0], ...extra[i][0] }));

  const processHeaders = plans?.map((p:any) => JSON.parse(p.translations));
  const processHeadersObjects: any = {};
  const processHeadersObjectsDecimals: any = [];

  processHeaders?.forEach((ph:any) => {
    Object.entries(ph)?.forEach(([key, value]: any) => {
      processHeadersObjects[key] = value.replace(' (decimales)', '');
    });
  });

  processHeaders?.forEach((ph:any) => {
    Object.entries(ph)?.forEach((item: any) => {
      let decimals = '';
      if (item[1].includes(' (decimales)')) {
        decimals = '(decimales)';
      }
      processHeadersObjectsDecimals.push(`${item[0]} ${decimals}`);
    });
  });

  const situation = (item:any) => {
    const tr = item ? JSON.parse(item?.translations) : {};
    const objSituation = item?.results?.nodes?.map((obj: any) => obj?.extraFields);
    const result = Object.keys(objSituation)
      ?.map((_: any, index: number) => Object.entries(objSituation[index])
        .filter(([key, value]: any) => tr[key]?.toLowerCase() === 'situación' && typeof value === 'string'));

    return result?.[0]?.[0]?.[1];
  };

  const dataCsv = merge?.map((c:any) => ({
    ...c,
    Rappeles: c?.description,
    Importe: c?.amount,
    Cumple: c?.comply,
    Situacion: situation(c),
    'Fecha inicio': c?.startDate !== undefined && useFormatDate(c?.startDate),
    'Fecha fin': c?.endDate !== undefined && useFormatDate(c?.endDate),
    'FECHA ACT': c?.dataDate !== undefined && c?.dataDate !== null ? useFormatDate(c?.dataDate?.split('T')[0]) : useFormatDate(c?.['FECHA ACT']),
    polizasNpAutosInd1T2021: useNumberFormat(c?.['Polizas NP Autos Ind. 1T 2021']),
    incremPolizas: c?.['Increm. Polizas'],
    'Emisión Empresas Mensual': useNumberFormat(c?.['Emisión Empresas Mensual']),
    'NP Empresas': useNumberFormat(c?.['NP Empresas']),
    'Objetivo Emision Empresas': useNumberFormat(c?.['Objetivo Emision Empresas']),
    'Objetivo NP Empresas Condicion 1': useNumberFormat(c?.['Objetivo NP Empresas Condicion 1']),
    'Objetivo NP Empresas Condicion 2': useNumberFormat(c?.['Objetivo NP Empresas Condicion 2']),
    'Objetivo Prorrateado Emision Empresas': useNumberFormat(c?.['Objetivo Prorrateado Emision Empresas']),
    'Objetivo Prorrateado NP Empresas Condicion 1': useNumberFormat(c?.['Objetivo Prorrateado NP Empresas Condicion 1']),
    'Objetivo Prorrateado NP Empresas Condicion 2': useNumberFormat(c?.['Objetivo Prorrateado NP Empresas Condicion 2']),
    'Falta Emision': useNumberFormat(c?.['Falta Emision']),
    'Falta NP Condicion 1': useNumberFormat(c?.['Falta NP Condicion 1']),
    'Falta NP Condicion 2': useNumberFormat(c?.['Falta NP Condicion 2']),
    'Incremento Prima Promedio': useNumberFormat(c?.['Incremento Prima Promedio']),
    'Prima Promedio 2021': useNumberFormat(c?.['Prima Promedio 2021']),
    PatrimonioFPEPSV: useNumberFormat(c?.PatrimonioFPEPSV),
    ProvisionesPPA: useNumberFormat(c?.ProvisionesPPA),
    ProvisionesPias: useNumberFormat(c?.ProvisionesPias),
    TotalProvisiones: useNumberFormat(c?.TotalProvisiones),
    PorcRappelPatrimonio: useNumberFormat(c?.PorcRappelPatrimonio),
    'Provisiones Multifondos': useNumberFormat(c?.['Provisiones Multifondos']),
    'Provisiones Dividendo Vida': useNumberFormat(c?.['Provisiones Dividendo Vida']),
    'Total Provisiones Vida': useNumberFormat(c?.['Total Provisiones Vida']),
    'Neto Comercial Primas': useNumberFormat(c?.['Neto Comercial Primas']),
    'Incremento Ofertas': useNumberFormat(c?.['Incremento Ofertas']),
    'Emisión Salud Mensual': useNumberFormat(c?.['Emisión Salud Mensual']),
    'Objetivo Emision Salud': useNumberFormat(c?.['Objetivo Emision Salud']),
    'Objetivo NP Salud Condicion 1': useNumberFormat(c?.['Objetivo NP Salud Condicion 1']),
    'Ranking Club Salud': useNumberFormat(c?.['Ranking Club Salud']),
    'Objetivo NP Salud Condicion 2': useNumberFormat(c?.['Objetivo NP Salud Condicion 2']),
    'Objetivo Prorrateado Emision Salud': useNumberFormat(c?.['Objetivo Prorrateado Emision Salud']),
    'Objetivo Prorrateado NP Salud Condicion 1': useNumberFormat(c?.['Objetivo Prorrateado NP Salud Condicion 1']),
    'Objetivo Prorrateado NP Salud Condicion 2': useNumberFormat(c?.['Objetivo Prorrateado NP Salud Condicion 2']),
    'Incremento Contactos': useNumberFormat(c?.['Incremento Contactos']),

  }));

  const uniqueHeaders: any = [];
  const getLevelsPlans = data?.me?.plans?.nodes
    ?.map((p: any) => p?.planLevels
      ?.map((l: any) => l)).flat();
  const headersFiltered = getLevelsPlans
    ?.sort((a: any, b: any) => a.order - b.order)
    ?.map((level: any) => level?.field);
  headersFiltered?.forEach((element: any) => (!uniqueHeaders
    .includes(element)
    ? uniqueHeaders.push(element)
    : (uniqueHeaders?.splice(uniqueHeaders?.indexOf(element), 1)
      && uniqueHeaders.push(element))));

  const headersFirst = ['Rappeles', 'Importe', 'Cumple', 'Situación Rappel', 'Fecha inicio', 'Fecha fin'];
  uniqueHeaders.unshift(...headersFirst);

  let filteredColumns:any = columns;
  if (mediadores.includes(data?.me?.calculatedCategory)) {
    filteredColumns = columns.filter((c: any) => c.accessor !== 'comparative');
  }

  const processDataCsv = dataCsv?.map((d: any) => ({ ...d }));
  processDataCsv?.forEach((item: any, index: number) => {
    Object.entries(item)?.forEach(([key, value]: any) => {
      let decimals = false;
      if (processHeadersObjectsDecimals.includes(`${key} (decimales)`)) {
        decimals = true;
      }
      processDataCsv[index][key] = useCsvNumber(value, decimals);
      if (key === 'Importe') {
        processDataCsv[index].IMPORTE = useCsvNumber(value, decimals);
      }
      if (key === 'Importe' && value === 'NO') {
        processDataCsv[index].IMPORTE = useCsvNumber(0, decimals);
      }
      if (key === 'Importe' && value === 0) {
        processDataCsv[index].IMPORTE = useCsvNumber(processDataCsv[index].resultsAmount, decimals);
      }
      if (key === 'Situacion') {
        processDataCsv[index]['SITUACION RAPPEL'] = useCsvNumber(value, decimals);
      }
      if (key === 'Cumple' && !value) {
        processDataCsv[index].comply = useCsvNumber('NO', decimals);
      }
      if (key === 'CUMPLE') {
        processDataCsv[index].comply = useCsvNumber(value, decimals);
      }
    });
  });

  const processHeadersFirst = {
    Rappeles: 'Rappels',
    IMPORTE: 'Importe',
    CUMPLE: 'Cumple',
    'SITUACION RAPPEL': 'Situación Rappel',
    'Fecha inicio': 'Fecha inicio',
    'Fecha fin': 'Fecha fin',
    planStatusName: 'Estado',
    'FECHA ACT': 'FECHA ACT',
  };

  const newProcessHeader = { ...processHeadersFirst };

  const processHedersCSV: any[] = [];

  const processHedersCSVKeys: any[] = [];
  Object.entries(newProcessHeader)?.forEach(([key, value]: any) => {
    if (!processHedersCSVKeys.includes(key)) {
      processHedersCSVKeys.push(key);
      processHedersCSV.push({ label: useMappingColumnNames(value, 'rappel'), key: useControlledColumns(key) });
    }
  });

  const processPlan = useProcessData(plan);

  return (
    <Container>
      {Object.getOwnPropertyNames(filters).length > 0 ? (
        <GroupFilterGeneral
          selectedFilters={filters}
          filter={globalFilter}
          setGlobalFilter={updateGlobalFilter}
        />
      ) : <FilterFormSkeleton countFilters={3} buttons={false} />}
      <ButtonBack onClick={() => navigate('/global-position/summary')} type="button">Volver</ButtonBack>
      <Typography color={Theme.colorRappel} size="32px" margin="0 0 2rem" display="block" align="center" weight="500">Rappeles</Typography>

      {(!isFetchingGpr && scoreRappel.some((item:any) => item.score.length > 0)) ? (
        <Score score={scoreRappel} cols={scoreRappel.length} maxWidth="306px" />
      ) : (<SkeletonCard cols={scoreRappel.length} maxWidth="306px" />)}

      {(processDataCsv?.length > 0) ? (
        <CardDataExportXLSX
          headers={processHedersCSV}
          data={processDataCsv}
          name="rappels_global_position"
        />
      ) : (<div style={{ height: '53px' }} />)}

      {(!isFetching && data) ? (
        <Table
          columns={filteredColumns}
          data={processPlan}
          theme={Theme.colorRappel}
          renderRowSubComponent={renderRowSubComponent}
        />
      ) : <TableSkeleton countCell={6} theme={Theme.colorRappel} />}
    </Container>
  );
}
