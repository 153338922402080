import {
  useState,
  useEffect,
  Fragment,
  useRef,
} from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import { columnsSummary, headers } from './dataCampaignsTeam';
import Theme from '../../../styles/Theme';
import Container from '../../atoms/Container/Container';
import Typography from '../../atoms/Typography/Typography';
import { LinkTable, Table } from '../../atoms/Table/Table';
import { Tabs, TabPanel } from '../../atoms/Tabs';
import GET_MY_TEAM_PLANS_BY_PARAMS_GQL from '../../../graphql/queries/getMyTeamPlansByParamsGql';
import LinkTo from '../../atoms/Link/LinkTo';
import FilterModel from '../../molecules/Filter/FilterModel';
import { IFilter } from '../../../interfaces/IFilter.d';
import GroupFilterGeneral from '../../molecules/Filter/GroupFilterGeneral';
import Score from '../../molecules/Score/Score';
import useNumberFormat from '../../../hooks/userNumberFormat';
import GET_MY_TEAM_DATA_FILTERS_GQL from '../../../graphql/queries/getMyTeamDataFiltersGql';
import FilterForm from '../../organisms/FilterForm/FilterForm';
import { Card } from '../../atoms/Card/Card';
import useFormatDate from '../../../hooks/useDate';
import useCsvNumber from '../../../hooks/useCsvNumber';
import GET_TEAM_RESULTS_GQL from '../../../graphql/queries/getTeamResultsGql';
import CardDataExportXLSX from '../../molecules/Cards/CardDataExportXLSX';
import useProcessData from '../../../hooks/useProcessData';
import SubscriptionButton from '../../molecules/Subscriptios/SubscriptionButton';
import GET_MY_INFO_GQL from '../../../graphql/queries/getCurrentUserInfoGql';
import { PlanByType } from '../../../interfaces/IPlan.d';
import FilterFormSkeleton from '../../organisms/FilterForm/FilterFormSkeleton';
import TableSkeleton from '../../atoms/Table/TableSkeleton';
import useYear from '../../../hooks/useYear';
import { ButtonBack } from '../../atoms/Button/StyledButton';
import SkeletonCard from '../../atoms/Card/SkeletonCard';
import { Params } from '../../../interfaces/Params.d';
import useRemoveUndefinedProperties from '../../../hooks/useRemoveUndefinedProperties';
import { createGraphQLClient } from '../../../graphql/graphqlClient';

export interface TeamCampaignParams {
  id: string;
}

export default function TeamCampaign({ id }:TeamCampaignParams) {
  const { grouping } = useParams();
  const graphQLClient = createGraphQLClient();
  const [globalFilter, setGlobalFilter] = useState<FilterModel>(new FilterModel());
  const [activeTab, setActiveTab] = useState(grouping || id || '');
  const [activeSubtab, setActiveSubtab] = useState('');
  const [tabs, setTabs] = useState<any>([]);
  const selectedFilters: IFilter = { temporalidad: [] };
  const type = 'Campaign';
  const { year } = useYear();
  const navigate = useNavigate();
  let dataCampaign:Array<[]> = [];
  const queryClient = useQueryClient();

  const updateGlobalFilter = (newFilter: FilterModel) => setGlobalFilter(newFilter);

  const variablesFilters = activeTab !== 'resumen' ? {
    grouping: activeTab.toUpperCase(),
    subgrouping: activeSubtab !== 'resumen' ? activeSubtab?.toUpperCase() : '',
    type,
    year,
  } : {
    type,
    year,
  };

  const variablesResults = activeTab !== 'resumen' ? {
    grouping: activeTab.toUpperCase(),
    subgrouping: activeSubtab !== 'resumen' ? activeSubtab?.toUpperCase() : '',
    type: 'Campaign',
    myTeam: true,
    status: globalFilter.status,
    year,
  } : {
    type,
    myTeam: true,
    status: globalFilter.status,
    year,
  };

  const campaignParams: Params = {
    type,
    grouping: activeTab !== 'resumen' ? activeTab.toUpperCase() : '',
    subgrouping: activeSubtab !== 'resumen' ? activeSubtab?.toUpperCase() : '',
    timeframe: (globalFilter.dateRange && globalFilter.dateRange.split(',')[0]) ? globalFilter.dateRange.split(',')[0].toString() : undefined,
    startDate: (globalFilter.dateRange && globalFilter.dateRange.split(',')[1]) ? globalFilter.dateRange.split(',')[1].toString() : undefined,
    endDate: (globalFilter.dateRange && globalFilter.dateRange.split(',')[2]) ? globalFilter.dateRange.split(',')[2].toString() : undefined,
    category: globalFilter.category ? globalFilter.category : undefined,
    dgt: globalFilter.dgt ? globalFilter.dgt : undefined,
    dt: globalFilter.dt ? globalFilter.dt : undefined,
    od: globalFilter.od ? globalFilter.od : undefined,
    year,
    firstName: globalFilter.firstName ? globalFilter.firstName : undefined,
    lastName: globalFilter.lastName ? globalFilter.lastName : undefined,
    nif: globalFilter.nif ? globalFilter.nif : undefined,
    search: globalFilter.search ? globalFilter.search : '',
    status: globalFilter.status ? globalFilter.status : '',
  };

  useRemoveUndefinedProperties(campaignParams);

  const {
    data: dataFilters,
    error: errorFilters,
    isFetching: isFetchingFilters,
  } = useQuery(
    ['CampaignTeamFilters', variablesFilters],
    async () => (graphQLClient && graphQLClient.request(
      GET_MY_TEAM_DATA_FILTERS_GQL,
      variablesFilters,
    )),
    {
      staleTime: Infinity,
    },
  );

  const {
    data: planCampaign,
    error: errorCampaign,
    refetch: refetchCampaign,
    isLoading: isLoadingCampaign,
    isFetching: isFetchingCampaign,
    status: statusCampaign,
  } = useQuery(
    ['Campaign_Team', campaignParams],
    async () => (graphQLClient && graphQLClient.request(
      GET_MY_TEAM_PLANS_BY_PARAMS_GQL,
      campaignParams,
    )),
    {
      staleTime: Infinity,
    },
  );

  const { data: dataUser } = useQuery(
    ['User'],
    async () => (graphQLClient && graphQLClient.request(GET_MY_INFO_GQL)),
  );

  const userId = dataUser?.me?.id;
  const groupingList :any = queryClient.getQueryState(['GroupingList']);

  const getTabs = () => {
    let response = [];
    const list = groupingList?.data?.groupingList?.grouping;
    if (list) {
      response = list[year].Campaign;
    }
    return response;
  };

  const {
    data: dataGetResults,
    refetch: refetchResults,
    isFetching: isFetchingResults,
    status: statusResults,
  } = useQuery(
    ['CampaignTeamResults', variablesResults],
    async () => (graphQLClient && graphQLClient.request(GET_TEAM_RESULTS_GQL, variablesResults)),
    {
      staleTime: Infinity,
    },
  );

  useEffect(() => {
    setGlobalFilter({ ...globalFilter, grouping: activeTab });
    setTabs(getTabs());
    if (!isFetchingCampaign && statusResults !== 'success') {
      refetchResults();
    }
  }, []);

  useEffect(() => {
    setTabs(getTabs());
  }, [groupingList]);

  useEffect(() => {
    navigate(`/team/campaign/${activeTab}`, { replace: true });
    setGlobalFilter({ ...globalFilter, grouping: activeTab });

    const completeActiveTab :any = tabs.find((tab: any) => tab.id === activeTab);
    if (completeActiveTab?.subgroupings) {
      setActiveSubtab(completeActiveTab?.subgroupings[0].id);
    }
  }, [activeTab]);

  useEffect(() => {
    setTabs(getTabs());
    if (statusCampaign !== 'success') {
      refetchCampaign();
    }
    if (statusResults !== 'success') {
      refetchResults();
    }
  }, [globalFilter]);

  useEffect(() => {
    if (statusCampaign !== 'success' && (globalFilter.type || globalFilter.grouping)) {
      refetchCampaign();
    }
    if (statusResults !== 'success' && (globalFilter.type || globalFilter.grouping)) {
      refetchResults();
    }
  }, [globalFilter.type, globalFilter.grouping]);

  const debouncedSearch = (value :any, currentGlobalFilter :FilterModel) => {
    setGlobalFilter({ ...currentGlobalFilter, search: value });
  };

  const setServicesValueDebounced = useRef(_.debounce(debouncedSearch, 1000));

  const handleChange = (e :any) => {
    setServicesValueDebounced.current(e.currentTarget.value, globalFilter);
  };

  dataCampaign = planCampaign?.myTeamPlans?.nodes?.map((c:PlanByType) => ({
    globalDgt: c?.globalDgt,
    campaignName: c?.description,
    participants: useNumberFormat(c?.participantsNumber),
    wins: c?.winsNumber,
    amount: useNumberFormat(c?.resultsAmount),
    dataDate: useFormatDate(c?.dataDate),
    status: c.planStatusName,
    CampaignResults: <LinkTo to={`/team/campaign/resumen/${c?.code}`} color={Theme.colorPrimary} style={{ display: 'block', textAlign: 'center' }}>Ver resultados</LinkTo>,
    timeframe: c?.timeframe,
    startDate: c?.startDate,
    endDate: c?.endDate,
    grouping: c?.grouping,
    subscribed: <SubscriptionButton subscribed={c.subscribed} planId={c.id} userId={userId} />,
    download: <LinkTable url={c?.basesUrl} title="Descargar" />,
    helpLink: <LinkTo to={`/ticket-create/${c?.id}`} color={Theme.colorPrimary} style={{ display: 'block', textAlign: 'center' }}>Incidencia/Consulta</LinkTo>,
  }));

  const campaignsByTimeFrame = dataCampaign && dataCampaign.reduce((rv:any, x:any) => {
    let dynamicTimeFrame = '';
    if (x.timeframe === 'TRIMESTRAL') {
      if (x.startDate.split('-')[1] === '01') {
        dynamicTimeFrame = '1T';
      } else if (x.startDate.split('-')[1] === '04') {
        dynamicTimeFrame = '2T';
      } else if (x.startDate.split('-')[1] === '07') {
        dynamicTimeFrame = '3T';
      } else if (x.startDate.split('-')[1] === '10') {
        dynamicTimeFrame = '4T';
      }
    }
    if (dynamicTimeFrame.length > 0) {
      // eslint-disable-next-line no-param-reassign
      (rv[dynamicTimeFrame] = rv[dynamicTimeFrame] || []).push(x);
    } else {
      // eslint-disable-next-line no-param-reassign
      (rv[x.timeframe] = rv[x.timeframe] || []).push(x);
    }
    return rv;
  }, {});

  const orderedCamps:any = {
    '1T': campaignsByTimeFrame?.['1T'] || [],
    '2T': campaignsByTimeFrame?.['2T'] || [],
    '3T': campaignsByTimeFrame?.['3T'] || [],
    '4T': campaignsByTimeFrame?.['4T'] || [],
    Anual: campaignsByTimeFrame?.ANUAL || [],
    Semestral: campaignsByTimeFrame?.SEMESTRAL || [],
    Mensual: campaignsByTimeFrame?.MENSUAL || [],
    Interanual: campaignsByTimeFrame?.INTERANUAL || [],
    Semanal: campaignsByTimeFrame?.SEMANAL || [],
    Quincenal: campaignsByTimeFrame?.QUINCENAL || [],
    Bimestral: campaignsByTimeFrame?.BIMESTRAL || [],
    Cuatrimestral: campaignsByTimeFrame?.CUATRIMESTRAL || [],
  };

  const csvData:any = {};
  Object.keys(orderedCamps)
    .forEach((k) => {
      csvData[k] = [];
      if (orderedCamps[k].length) {
        Object.entries(orderedCamps[k])?.forEach(([key]: any) => {
          csvData[k][key] = {};
          Object.entries(orderedCamps[k][key])?.forEach(([key2, value2]: any) => {
            csvData[k][key][key2] = useCsvNumber(value2);
          });
        });
      }
      return null;
    });

  const contadores:any = {};
  Object.keys(orderedCamps)
    .map((k) => {
      if (orderedCamps[k].length) {
        let winCounter = 0;
        let amountCounter = 0;
        let participantsCounter = 0;
        let complyCounter = 0;
        // eslint-disable-next-line no-return-assign
        const counter = orderedCamps[k].map((e:any) => {
          const h = {
            wins: (winCounter += Number(e.wins) || 0),
            amount: (amountCounter += Number(e.amount) || 0),
            participants: (participantsCounter += Number(e.participants) || 0),
            comply: (complyCounter += Number(e.win) || 0),
          };
          return h;
        })[orderedCamps[k].length - 1];
        contadores[k] = counter;
      }
      return null;
    })
    .filter((b) => b !== null);

  const results = dataGetResults?.summaryGlobalPositionChartByTimeframe;
  const scoreIndicators = (k: any) => {
    const participants = results !== undefined ? Object.entries(results?.participantsCount[0])
      .find(([key]) => k === key) : '';
    const wins = results !== undefined ? Object.entries(results?.totalUserWinCount[0])
      .find(([key]) => k === key) : '';

    const score = [
      {
        id: 0,
        title: 'Participa',
        subttitle: '',
        score: (participants?.[1]),
        icon: 'participant',
      },
      {
        id: 1,
        title: 'Ganan',
        subttitle: '',
        score: (wins?.[1]),
        icon: 'cup',
      },
    ];
    return score;
  };

  if (errorCampaign || errorFilters) {
    return <Container>Error</Container>;
  }

  return (
    <Container>
      <ButtonBack onClick={() => navigate('/team/summary/general')} type="button">Volver</ButtonBack>
      <Typography color={Theme.colorCampaign} size="32px" margin="0 0 1rem" display="block" align="center" weight="500">Campañas</Typography>
      <Card background="transparent" display="flex" alignItems="flex-start" justifyContent="space-between">
        <GroupFilterGeneral
          selectedFilters={selectedFilters}
          filter={globalFilter}
          setGlobalFilter={updateGlobalFilter}
        />
        <input
          type="text"
          placeholder="Buscar por nombre de campaña"
          onChange={handleChange}
          onPaste={handleChange}
          className="search"
          style={{ height: '38px', maxWidth: '450px', margin: '1rem 0 0' }}
        />
      </Card>

      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        onChangeTab={setActiveTab}
        activeSubtab={activeSubtab}
        onChangeSubtab={setActiveSubtab}
        theme={Theme.colorCampaign}
      />

      <TabPanel isActivePanel id="campaign-tab-panel">
        {activeTab === 'retos' && (
          <div style={{ textAlign: 'right', margin: '3rem 0 2rem' }}>
            <LinkTo
              to={`/team/campaign/results/${activeTab}?code=${planCampaign?.myTeamPlans?.nodes[0]?.code}`}
              background={Theme.colorCampaign}
              border="8px"
              color={Theme.colorWhite}
              padding="0.72rem 1.2rem"
              underline="none"
            >
              {`Ver resultados nominales de ${activeTab}`}
            </LinkTo>
          </div>
        )}

        {!isFetchingFilters ? (
          <FilterForm
            dataFilters={dataFilters}
            filter={globalFilter}
            setFilter={updateGlobalFilter}
            type="Campaign"
            grouping={activeTab}
            fetching={isFetchingCampaign}
          />
        ) : <FilterFormSkeleton countFilters={10} />}

        {(
          !isLoadingCampaign
            && !isFetchingCampaign
            && columnsSummary
            && dataCampaign
            && dataFilters) ? (
            Object.keys(orderedCamps).map((k:any) => (
              orderedCamps[k].length ? (
                <Fragment key={k}>
                  <Typography
                    align="center"
                    color={Theme.colorCampaign}
                    display="block"
                    size="28px"
                    margin="2rem auto"
                    weight="500"
                  >
                    {k === '1T' && 'Trimestre 1'}
                    {k === '2T' && 'Trimestre 2'}
                    {k === '3T' && 'Trimestre 3'}
                    {k === '4T' && 'Trimestre 4'}
                    {k === 'Semanal' && 'Semanal'}
                    {k === 'Quincenal' && 'Quincenal'}
                    {k === 'Mensual' && 'Mensual'}
                    {k === 'Bimestral' && 'Bimestral'}
                    {k === 'Cuatrimestral' && 'Cuatrimestral'}
                    {k === 'Semestral' && 'Semestral'}
                    {k === 'Anual' && 'Anual'}
                    {k === 'Interanual' && 'Interanual'}
                  </Typography>

                  <Card
                    background={Theme.colorWhite}
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                    gap="1rem"
                    margin="0"
                  >
                    {headers && csvData[k].length > 0 && (
                      <CardDataExportXLSX
                        headers={headers}
                        data={csvData[k]}
                        margin="0 0 0.5rem auto"
                        name={`campaigns_team_${activeTab}_${k.toLowerCase()}`}
                      />
                    )}
                  </Card>

                  <Table
                    columns={columnsSummary}
                    data={useProcessData(orderedCamps[k])}
                    theme={Theme.colorCampaign}
                  />

                  {!isFetchingResults ? (
                    <Score score={scoreIndicators(k)} cols={2} maxWidth="260px" margin="0 auto 4rem auto" />
                  ) : (<SkeletonCard cols={2} maxWidth="260px" />)}

                </Fragment>
              ) : ''
            ))
          ) : <TableSkeleton countCell={6} theme={Theme.colorCampaign} />}
      </TabPanel>
    </Container>
  );
}
