import useFormatDate from './useDate';
import useTableNumber from './useTableNumber';

function processValue(key: any, value: any, translations: any) {
  let newValue = null;

  if (/^(0?[1-9]|[12][0-9]|3[01])[/-](0?[1-9]|1[012])[/-]\d{4}$/.test(value)) {
    return useFormatDate(value);
  }

  if (/^\d{4}[/-](0?[1-9]|1[012])[/-](0?[1-9]|[12][0-9]|3[01])$/.test(value)) {
    return useFormatDate(value);
  }

  const translationsWithoutSpaces: any = {};
  if (typeof translations !== 'undefined') {
    Object.entries(translations)?.forEach(([keyT, valueT]) => {
      translationsWithoutSpaces[keyT.replace(/ /g, '')] = valueT;
    });
  }

  if ((translations !== undefined && translations[key] !== undefined && translations[key].includes('(decimales)'))
    || (translationsWithoutSpaces !== undefined && translationsWithoutSpaces[key] !== undefined && translationsWithoutSpaces[key].includes('(decimales)'))
  ) {
    newValue = useTableNumber(value, true);
    return newValue;
  }
  if ((translations !== undefined && translations[key] !== undefined && (translations[key].includes('(porcentaje)') || translations[key].includes('%')))
    || (translationsWithoutSpaces !== undefined && translationsWithoutSpaces[key] !== undefined && translationsWithoutSpaces[key].includes('(porcentaje)'
    || translationsWithoutSpaces[key].includes('(%)')))
  ) {
    newValue = useTableNumber(value, true);
    newValue += '%';
    return newValue;
  }
  if ((translations !== undefined && translations[key] !== undefined && translations[key].includes('%'))
    || (translationsWithoutSpaces !== undefined && translationsWithoutSpaces[key] !== undefined && translationsWithoutSpaces[key].includes('%'))) {
    newValue = useTableNumber(value, true);
    newValue += '%';
    return newValue;
  }
  if (typeof value === 'number') {
    newValue = useTableNumber(value);
    return newValue;
  }
  return newValue === null ? value : newValue;
}

function processArray(value:any, translations: any) {
  let array: any[] = [];
  array = value.map((v:any) => {
    let newItem: any = null;
    if (typeof v !== 'object') {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      newItem = processData(v, translations);
    }
    if (typeof v === 'object') {
      newItem = { ...v };
      Object.entries(v).forEach(([k, val]) => {
        if (typeof val === 'object') {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          newItem[k] = processData(val, translations);
        }
        if (typeof val !== 'object') {
          newItem[k] = processValue(k, val, translations);
        }
      });
    }
    return newItem;
  });
  return array;
}

export function processData(item: any, translations: any) {
  let newObj:any = null;
  if (typeof item === 'object') {
    if (Array.isArray(item)) {
      newObj = processArray(item, translations);
    }
    if (!Array.isArray(item) && item !== null && item !== undefined) {
      newObj = { ...item };
      Object.entries(item).forEach(([key, value]) => {
        if (key === 'comparative') {
          return;
        }
        if (typeof value === 'object') {
          if (Array.isArray(value)) {
            newObj[key] = processArray(value, translations);
          }
          if (!Array.isArray(value) && value !== null && value !== undefined) {
            Object.entries(value).forEach(([key1, value1]) => {
              if (value1 === 'object') {
                newObj[key][key1] = processData(value1, translations);
              }
            });
          }
        }
        if (typeof value !== 'object') {
          newObj[key] = processValue(key, value, translations);
        }
      });
    }
  }
  if (typeof item !== 'object') {
    return item;
  }
  return newObj;
}

export default function useProcessData(data: any) {
  const newData = data?.map((item: any) => (
    { ...item }));
  // , translations: JSON.parse(item.translations)
  const process = newData?.map(
    (element: any) => (element?.translations
      ? processData(element, JSON.parse(element?.translations))
      : processData(element, undefined)),
  );
  return process;
}
