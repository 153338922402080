import Styled from 'styled-components';
import { Form } from 'formik';
import { Link } from 'react-router-dom';
import Theme from '../../../styles/Theme';

export const FormContainer = Styled.div`
  background: ${Theme.colorWhite};
  border-radius: ${Theme.radius};
  border: 1px solid ${Theme.colorSecondary3};
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 550px;
  padding: 67px 2rem;
  margin-bottom: 73px;
  width: 90%;

  label {
    margin-bottom: 2rem;
    width: fit-content;
  }

  button {
    max-width: 262px;
    margin: 0 auto;
  }

  @media screen and (min-width: 768px) {
    padding: 67px 95px;
  }
`;

export const FormLogin = Styled(Form)`
  display: flex;
  flex-direction: column;
  max-width: 360px;
  width: 94%;
`;

export const FormFilter = Styled(Form)<{width?: string, margin?: string}>`
  max-width: ${(props) => props.width || '100%'};
  margin: ${(props) => props.margin || '0 0 2rem'};
  position: relative;
  z-index: 6;
`;

export const FormLink = Styled(Link)`
  color: ${Theme.colorPrimary};
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  text-decoration: underline;
  text-align: right;
  margin-bottom: 10px;
`;

export const FormSkeleton = Styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 1rem 0;
`;

export const FormSkeletonItem = Styled.div`
  width: 100%;
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
  height: 38px;
  padding-top: 21px;

  @media screen and (min-width: 768px) {
    margin-bottom: 0;
    width: calc(25% - 0.75rem);
  }

  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
`;

export const FormSkeletonButton = Styled.div`
  display:flex;
  justify-content: end;
  gap: 1rem;
  margin-bottom: 3rem;

  &:before,
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 40px;
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
  }

  @media screen and (min-width: 768px) {
    &:before,
    &:after {
      width: 160px;
    }
  }
`;

export const FormSkeletonSelect = Styled.div`
  display:flex;
  justify-content: end;
  gap: 1rem;
  margin-bottom: 1.5rem;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 40px;
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
  }
`;

export default {
  FormContainer,
  FormLogin,
  FormLink,
  FormSkeleton,
  FormSkeletonItem,
  FormSkeletonButton,
};
