import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { useFormikContext } from 'formik';
import TicketModel from '../components/models/Ticket';
import { createGraphQLClient } from '../graphql/graphqlClient';
import { getTokenFromStore } from '../store/sessionStore';
import useYear from './useYear';
import GET_USERS_TEAM_GQL from '../graphql/queries/getUsersTeamGql';
import GET_DROPDOWN_HELP_GQL from '../graphql/queries/getDropdownHelpGql';
import CREATE_OR_UPDATE_TICKET_GQL from '../graphql/mutations/createOrUpdateTicketGql';

type Ticket = {
  id: string;
  body: string;
  ticketStatus?: number;
  subject: string;
  ticketType: number;
  planId?: string;
  relatedUserId?: number;
  createdAt: string;
  updatedAt: string;
}

const initialValuesTicket: Ticket = {
  body: '',
  subject: '',
  ticketType: -1,
  ticketStatus: undefined,
  planId: '',
  id: '',
  createdAt: '',
  updatedAt: '',
  relatedUserId: -1,
};

const validationSchemaTicket = Yup.object().shape({
  body: Yup.string().required('El campo body es obligatorio'),
  subject: Yup.string().required('El campo subject es obligatorio'),
  ticketType: Yup.number().required('El campo ticketType es obligatorio'),
  ticketStatus: Yup.string().required('El campo ticketStatus es obligatorio'),
  planType: Yup.string().required('El campo planType es obligatorio'),
  planId: Yup.string().required('El campo planId es obligatorio'),
  id: Yup.string().required('El campo id es obligatorio'),
  createdAt: Yup.string().required('El campo createdAt es obligatorio'),
  updatedAt: Yup.string().required('El campo updatedAt es obligatorio'),
  relatedUserId: Yup.string().required('El campo relatedUserId es obligatorio'),
});

export default function useFormTicketManager() {
  const token = getTokenFromStore();
  const navigate = useNavigate();
  const { year } = useYear();
  const graphQLClient = createGraphQLClient();
  const [errorMessage, setErrorMessage] = useState({
    relatedUserId: undefined,
    ticketType: undefined,
    planId: undefined,
    subject: '',
    body: '',
  });
  const [submitError, setSubmitError] = useState<string>('');
  const [submitSuccess, setSubmitSuccess] = useState<string>('');
  const [subjectValue, setSubjectValue] = useState<string>('');
  const [bodyValue, setBodyValue] = useState<string>('');
  const [files, setFiles] = useState<any>([]);
  const [options, setOptions] = useState<any>(initialValuesTicket);
  const [internalValue, setInternalValue] = useState<TicketModel>(
    new TicketModel(),
  );
  const nifRef = useRef<any>(null);
  const planIdRef = useRef<any>(null);
  const ticketTypeRef = useRef<any>(null);

  const {
    data: dataUsersTeam,
  } = useQuery(
    ['UsersTeam', year],
    async () => (graphQLClient && graphQLClient.request(GET_USERS_TEAM_GQL, { year })),
    {
      staleTime: Infinity,
    },
  );

  const {
    data: dataFilters,
  } = useQuery(
    ['Dropdown', year],
    async () => (graphQLClient
      && graphQLClient.request(
        GET_DROPDOWN_HELP_GQL,
        { year },
      )),
    {
      staleTime: Infinity,
    },
  );

  const formattedData:any = {};
  let hashTicket: any = {};

  hashTicket = {
    arrayRelatedUserId: dataUsersTeam?.myTeamUsers?.nodes,
    arrayTicketType: dataFilters?.dropdownHelp?.arrayTicketType,
    arrayPlan: dataFilters?.dropdownHelp?.arrayPlans,
    arrayYears: dataFilters?.dropdownHelp?.arrayYears,
    arrayPlanType: dataFilters?.dropdownHelp?.arrayPlanType,
  };

  const stripHtml = (html:any) => {
    if (!html) return '';
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent ? doc.body.textContent : '';
  };

  const handleInputChange = (event: any, name: string) => {
    const inputValue = name === 'body' ? event : event?.target?.value;
    if (name === 'subject') {
      setInternalValue({
        ...internalValue,
        subject: inputValue,
      });
      if (inputValue.length !== 0) {
        setErrorMessage({
          ...errorMessage,
          subject: 'Este campo es obligatorio',
        });
      }
      if (inputValue.length > 144) {
        setErrorMessage({
          ...errorMessage,
          subject: 'Este campo no puede superar los 144 caracteres',
        });
      } else if (inputValue.length <= 144 && inputValue.length > 0) {
        setErrorMessage({
          ...errorMessage,
          subject: '',
        });
      }
    } else if (name === 'body') {
      setInternalValue({
        ...internalValue,
        body: inputValue,
      });
      if (stripHtml(inputValue).length !== 0) {
        setErrorMessage({
          ...errorMessage,
          body: 'Este campo es obligatorio',
        });
      }
      if (stripHtml(inputValue).length > 2000) {
        setErrorMessage({
          ...errorMessage,
          body: 'Este campo no puede superar los 2000 caracteres',
        });
      } else if (stripHtml(inputValue).length <= 2000 && stripHtml(inputValue).length > 0) {
        setErrorMessage({
          ...errorMessage,
          body: '',
        });
      }
    } else {
      setInternalValue({
        ...internalValue,
        [name]: event?.value,
      });
      if (event?.value?.length !== 0 || event?.value === 'undefined' || event?.value === undefined) {
        setErrorMessage({
          ...errorMessage,
          [name]: 'Este campo es obligatorio',
        });
      }
    }
  };

  Object.keys(hashTicket).map((k) => {
    if (hashTicket[k]?.length) {
      const arrayData = hashTicket[k].map(
        (e:string) => ({ label: e, value: e }),
      );
      formattedData[k] = arrayData;
    }
    return null;
  }).filter((b) => b !== null);

  useEffect(() => {
    setOptions(formattedData);
  }, []);

  const auth = `Bearer ${token}`;
  const impersonateId = localStorage.getItem('impersonateUser') ? JSON.parse(localStorage.getItem('impersonateUser') || '').id : null;
  const headers:any = { authorization: auth };
  if (impersonateId) {
    headers['impersonate-id'] = impersonateId;
  }
  const createTicket = (create: any) => axios.post(`${process.env.REACT_APP_GRAPHQL_ENDPOINT}`, {
    query: CREATE_OR_UPDATE_TICKET_GQL,
    variables: {
      id: create.id,
      body: create.body,
      subject: create.subject,
      ticketType: create.ticketType,
      ticketStatus: create.ticketStatus,
      files: create.files,
      planType: create.planType,
      planId: create.planId,
      relatedUserId: create.relatedUserId,
    },
  }, {
    headers,
  }).then((response: any) => {
    if (response.data.data?.createOrUpdateTicket) {
      setSubmitSuccess('Ticket creado correctamente');
      setTimeout(() => {
        setSubmitError('');
        navigate('/help');
      }, 4000);
    } else {
      setSubmitError('Error al crear el ticket');
    }
  });

  const formik = useFormikContext();
  const { mutate } = useMutation(createTicket);
  const handleSubmit = () => {
    if (
      internalValue?.relatedUserId !== undefined
      && internalValue?.ticketType !== undefined
      && internalValue?.planId !== undefined
      && internalValue?.subject !== undefined
      && internalValue?.body !== undefined
    ) {
      if (!(internalValue?.subject.length > 144) && !(internalValue?.body.length > 2000)) {
        mutate(internalValue as Ticket);
        setInternalValue(new TicketModel());
        formik?.resetForm();
        setErrorMessage({
          relatedUserId: undefined,
          ticketType: undefined,
          planId: undefined,
          subject: '',
          body: '',
        });
      }
    } else {
      setSubmitError('Todos los campos son obligatorios');
    }
  };

  return {
    navigate,
    internalValue,
    setInternalValue,
    options,
    handleSubmit,
    initialValuesTicket,
    validationSchemaTicket,
    hashTicket,
    nifRef,
    ticketTypeRef,
    planIdRef,
    subjectValue,
    bodyValue,
    files,
    setSubjectValue,
    setBodyValue,
    setFiles,
    handleInputChange,
    errorMessage,
    submitError,
    submitSuccess,
    stripHtml,
  };
}
