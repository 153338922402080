import bgCustom from '../../../assets/images/buenos_aires.jpg';
import bgCustom2024 from '../../../assets/images/convencion_25.jpg';

export default function BannerCustom(props: any) {
  const { year } = props;
  return (
    <div>
      {year === 2023 && (
        <div className="banner__custom__home">
          <div>
            <img src={bgCustom} alt="NUEVO Convención Buenos Aires 2024" />
            <div className="banner__text__container">
              <div className="banner__text">
                CONVENCIÓN A ARGENTINA 2024
              </div>
              <span>
                <a href="https://internos.mapfre.com/convencion/" target="_blank" rel="noreferrer">Aquí</a>
              </span>
            </div>
          </div>
        </div>
      )}

      {year === 2024 && (
        <div className="banner__custom__home banner__custom__home__2024">
          <div>
            <img src={bgCustom2024} alt="CONVENCIÓN 2025: BANGKOK" />
            <div className="banner__text__container">
              <div className="banner__text banner__text__white">
                CONVENCIÓN 2025: BANGKOK
              </div>
              { /* <span>
                <a href="https://internos.mapfre.com/convencion/" target="_blank" rel="noreferrer">Aquí</a>
                </span> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
