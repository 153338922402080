import { useState } from 'react';
import AWS from 'aws-sdk';
import styled from 'styled-components';
import Theme from '../../../styles/Theme';
import Spinner from '../Spinner/Spinner';

const S3_BUCKET_NAME = 'map015-produccion';
const S3_BUCKET_FOLDER = '/FicherosGestorIncidencias';
const REGION = 'eu-west-1';

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

const myBucket = new AWS.S3({
  params: { Bucket: `${S3_BUCKET_NAME}${S3_BUCKET_FOLDER}` },
  region: REGION,
});

const UploadButton = styled.button`
  background-color: ${Theme.colorPrimary};
  border: 0;
  color: ${Theme.colorWhite};
  padding: 10px 20px;
  line-height: 1.25;
  text-align: center;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: ${Theme.radius};
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  font-size: 12px;
  font-weight: 900;
  min-width: 160px;
  &:hover {
    cursor: pointer !important;
  }
`;

const FileInputWrapper = styled.div`
  position: relative;
  overflow: hidden;
  cursor: pointer;
  display: inline-block;

  &:hover {
    cursor: pointer !important;
  }
`;

const FileInput = styled.input`
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
`;

const FilesWrapper = styled.div`
  width: 100%;
`;

const FileUrl = styled.a`
  font-size: 12px;
`;

export default function FileUpload({
  publicUrls, setPublicUrls, maxMbSize, multipleFiles,
}:any) {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  let sizeSelected = 0;

  const MAX_FILE_SIZE = maxMbSize * 1024 * 1024;
  const validateFile = (file:any) => {
    const validTypes = ['image/jpeg', 'image/png', 'application/pdf', 'application/vnd.ms-excel', 'text/csv'];
    if (!validTypes.includes(file.type)) {
      setError('Invalid file type. Only JPG, PNG, PDF, XLS, and CSV files are allowed.');
      return false;
    }
    if (sizeSelected + file.size > MAX_FILE_SIZE) {
      setError(`El tamaño total de los ficheros excede el tamaño máximo: ${maxMbSize}mb.`);
      return false;
    }
    return true;
  };

  const getTimestampedFileName = (fileName:any) => {
    const timestamp = Date.now();
    const extension = fileName.substring(fileName.lastIndexOf('.'));
    const name = fileName.substring(0, fileName.lastIndexOf('.'));
    return `${name.replace(/[^a-zA-Z0-9._-]/g, '_')}_${timestamp}${extension}`;
  };

  const uploadFile = (file:any) => {
    setLoading(true);
    const timestampedFileName = getTimestampedFileName(file.name);
    const params = {
      ACL: 'public-read',
      Body: file,
      Bucket: `${S3_BUCKET_NAME}${S3_BUCKET_FOLDER}`,
      Key: timestampedFileName,
    };

    myBucket.upload(params, (err: any, data: any) => {
      if (err) {
        setError(err.toString());
      } else {
        setPublicUrls([...publicUrls, data.Location]);
        sizeSelected += file.size;
      }
    });
    setLoading(false);
  };

  const handleFileInput = (e:any) => {
    const file = e.target.files[0];

    if (!validateFile(file)) {
      return;
    }
    setError('');
    uploadFile(file);
  };

  return (
    <div style={{ marginTop: '10px', marginBottom: '10px', width: 'fit-content' }}>
      { !(!multipleFiles && publicUrls.count > 1) && (
        <FileInputWrapper>
          <UploadButton>
            {loading ? (
              <Spinner background="rgba(200,200,200,0.6)" color="#fff" />
            ) : 'Seleccionar archivo'}
          </UploadButton>
          <FileInput type="file" onChange={handleFileInput} />
        </FileInputWrapper>
      )}
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <FilesWrapper>
        {!error && publicUrls && (
          publicUrls.map((url:any) => (
            <>
              <FileUrl href={url} target="_blank">{url.match(/[^/]+$/)[0]}</FileUrl>
              <br />
            </>
          ))
        )}
      </FilesWrapper>
    </div>
  );
}
