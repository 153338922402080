import { gql } from 'graphql-request';

const CREATE_OR_UPDATE_TICKET_GQL = gql`
  mutation createOrUpdateTicket(
    $id: ID,
    $body: String!,
    $ticketStatus: Int,
    $subject: String!,
    $ticketType: Int!,
    $planId: ID,
    $relatedUserId: ID,
    $files: [String!],
    $createdAt: ISO8601Date,
    $updatedAt: ISO8601Date,
  ) {
    createOrUpdateTicket(
      ticket: {
        body: $body,
        subject: $subject,
        ticketType: $ticketType,
        ticketStatus: $ticketStatus,
        planId: $planId,
        id: $id,
        createdAt: $createdAt,
        updatedAt: $updatedAt,
        relatedUserId: $relatedUserId,
        files: $files,
      }
    ) {
      body
      createdAt
      id
      internalId
      openedAt
      planId
      relatedUserId
      subject
      ticketStatus
      ticketType
      files
      updatedAt
    }
  }
`;

export default CREATE_OR_UPDATE_TICKET_GQL;
