import { useEffect, useState, MouseEvent } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  columns, renderRowSubComponent, columnsCommercial,
  renderRowSubComponentCommercial,
  headersTop3Csv,
  headersCampaignByCode,
} from './dataCampainsGlobalPosition';
import { IFilter } from '../../../interfaces/IFilter.d';
import Container from '../../atoms/Container/Container';
import Typography from '../../atoms/Typography/Typography';
import Theme from '../../../styles/Theme';
import Score from '../../molecules/Score/Score';
import GroupFilterGeneral from '../../molecules/Filter/GroupFilterGeneral';
import { Table } from '../../atoms/Table/Table';
import FilterModel from '../../molecules/Filter/FilterModel';
import usePlan from '../../../hooks/usePlan';
import useGlobalPosition from '../../../hooks/useGlobalPosition';
import useGlobalPositionCharts from '../../../hooks/useGlobalPositionCharts';
import useNumberFormat from '../../../hooks/userNumberFormat';
import useCsvNumber from '../../../hooks/useCsvNumber';
import GET_TOP_3_CAMPAIGN_USERS_GQL from '../../../graphql/queries/getTop3CampaignUsersGql';
import Graphic from '../../atoms/Graphic/Graphic';
import Grid from '../../atoms/Grid/Grid';
import { Card } from '../../atoms/Card/Card';
import { StyledHeaderAmount } from '../../atoms/Card/StyledCard';
import { StyledData } from '../../atoms/Graphic/StyledGraphic';
import GET_COMMERCIAL_CAMPAIGN_POINTS_AND_POSITION from '../../../graphql/queries/getCommercialPointsAndPositionGql';
import useFormatDate from '../../../hooks/useDate';
import GET_COMMERCIAL_CAMPAIGN_DATA_GQL from '../../../graphql/queries/getCommercialCampaignDataGql';
import useMappingColumnNames from '../../../hooks/useMappingColumnNames';
import useControlledColumns from '../../../hooks/useControlledColumns';
import GET_CALCULATE_OBJECTIVES from '../../../graphql/queries/getCalculateObjectivesGql';
import { colsComercial } from './dataCampaignsTeam';
import { StyledTabButton, TabContainer, TabWrapper } from '../../atoms/Tabs/StyledTabs';
import GET_RESULTS_BY_CODE_GQL from '../../../graphql/queries/getResultsByCodeGql';
import CardDataExportXLSX from '../../molecules/Cards/CardDataExportXLSX';
import useProcessData from '../../../hooks/useProcessData';
import useProcessDataCSV from '../../../hooks/useProcessDataCSV';
import useYear from '../../../hooks/useYear';
import useCategoryInfo from '../../../hooks/useCategoryInfo';
import useRemoveAccents from '../../../hooks/useRemoveAccents';
import { ButtonBack } from '../../atoms/Button/StyledButton';
import TableSkeleton from '../../atoms/Table/TableSkeleton';
import SkeletonCard from '../../atoms/Card/SkeletonCard';
import FilterFormSkeleton from '../../organisms/FilterForm/FilterFormSkeleton';
import { createGraphQLClient } from '../../../graphql/graphqlClient';

export interface IDataCommercial {
  dgt: string;
  dt: string;
  od: string;
  firstName: string;
  lastName: string;
  points: string;
  extraFields: any;
  territoryNameDgt: string;
  territoryNameDt: string;
  territoryNameOd: string;
}

export default function CampaignGlobalPosition() {
  const graphQLClient = createGraphQLClient();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState<IFilter>({});
  const [globalFilter, setGlobalFilter] = useState<FilterModel>(new FilterModel());
  const mediadores = ['AFIS', 'AGENTES', 'AGENTES', 'AGENTES', 'APS', 'DELEGADOS', 'MEDIACONCES', 'MEDIAINMOBI', 'MEDIASALUD', 'MEDIAVIDA', 'GDLG'];
  const [clickedId, setClickedId] = useState<string>('');
  const { year } = useYear();
  const { userInfo } = useCategoryInfo(year);
  const navigate = useNavigate();

  const updateGlobalFilter = (newFilter: FilterModel) => {
    if ((globalFilter.grouping === '' || globalFilter.grouping === undefined) && (globalFilter.subgrouping === '' || globalFilter.subgrouping === undefined) && (newFilter.grouping === 'COMERCIAL' || newFilter.subgrouping === 'TRIMESTRALES')) {
      const currentMonth = new Date().getMonth();
      let range = '';
      // Months from 0 to 11
      if (currentMonth <= 2) {
        range = `TRIMESTRAL,${year}-01-01,${year}-03-31`;
      } else if (currentMonth <= 5) {
        range = `TRIMESTRAL,${year}-04-01,${year}-06-30`;
      } else if (currentMonth <= 8) {
        range = `TRIMESTRAL,${year}-07-01,${year}-09-30`;
      } else {
        range = `TRIMESTRAL,${year}-10-01,${year}-12-31`;
      }
      setGlobalFilter({ ...newFilter, dateRange: range });
    } else {
      setGlobalFilter(newFilter);
    }
  };

  const variables = (type: string) => {
    const vars: any = {
      type,
      grouping: globalFilter.grouping,
      subgrouping: globalFilter.subgrouping,
      // eslint-disable-next-line no-nested-ternary
      timeframe: (globalFilter.dateRange && globalFilter.dateRange.split(',')[0]) ? globalFilter.dateRange.split(',')[0].toString() : globalFilter.grouping === 'COMERCIAL' ? 'TRIMESTRAL' : undefined,
      // eslint-disable-next-line no-nested-ternary
      startDate: (globalFilter.dateRange && globalFilter.dateRange.split(',')[1]) ? globalFilter.dateRange.split(',')[1].toString() : undefined,
      // eslint-disable-next-line no-nested-ternary
      endDate: (globalFilter.dateRange && globalFilter.dateRange.split(',')[2]) ? globalFilter.dateRange.split(',')[2].toString() : undefined,
      level: globalFilter.level,
      year,
      status: globalFilter.grouping === 'COMERCIAL' ? undefined : globalFilter.status,
    };

    Object.keys(vars).forEach(
      (key:any) => (vars[key] === undefined || vars[key] === '') && delete vars[key],
    );

    return vars;
  };

  function getScore(hashData:any) {
    if (globalFilter.dateRange) {
      switch (globalFilter?.dateRange?.split(',')[0]) {
      case 'ANUAL':
        return hashData.Anual;
      case 'SEMESTRAL':
        return hashData.Semestral;
      case 'INTERANUAL':
        return hashData.Interanual;
      case 'MENSUAL':
        return hashData.Mensual;
      case 'SEMANAL':
        return hashData.Semanal;
      case 'QUINCENAL':
        return hashData.Quincenal;
      case 'BIMESTRAL':
        return hashData.Bimestral;
      case 'TRIMESTRAL':
        if (globalFilter?.dateRange?.split(',')[1] === `${year}-01-01`) {
          return hashData['1T'];
        } if (globalFilter?.dateRange?.split(',')[1] === `${year}-04-01`) {
          return hashData['2T'];
        } if (globalFilter?.dateRange?.split(',')[1] === `${year}-07-01`) {
          return hashData['3T'];
        }
        return hashData['4T'];
      case 'CUATRIMESTRAL':
        return hashData.Cuatrimestral;
      default:
        return hashData.total;
      }
    }
    return hashData.total;
  }

  const {
    data: dataPlan,
    error: errorPlan,
    refetch: refetchPlan,
    isFetching: isFetchingPlan,
  } = usePlan(variables('Campaign'));

  const {
    data: dataMpgChartByTimeframe,
    refetch: refetchMpgChartByTimeframe,
    isFetching: isFetchingMpgChartByTimeframe,
  } = useGlobalPositionCharts(variables('Campaign'));

  const resultsCampaign = dataMpgChartByTimeframe?.summaryGlobalPositionChartByTimeframe;

  const scoreCampaign = [{
    id: 0, title: 'Ganadas', score: resultsCampaign !== undefined ? getScore(resultsCampaign?.totalPlanWinCount[0]) : 0, icon: 'cup',
  }, {
    id: 1, title: 'Tréboles', score: resultsCampaign !== undefined ? getScore(resultsCampaign?.totalTreboles[0]) : 0, icon: 'clover',
  }, {
    id: 2, title: 'Puntos', score: resultsCampaign !== undefined ? useNumberFormat(getScore(resultsCampaign?.points[0]), true) : 0, icon: 'start',
  }, {
    id: 3, title: 'Importe', subtitle: 'a fecha', score: resultsCampaign !== undefined ? useNumberFormat(getScore(resultsCampaign?.totalAmount[0]), true) : 0, icon: 'euro',
  }];

  const planCode = dataPlan?.me?.plans?.nodes?.filter((plan: any) => ((plan?.grouping?.name === 'COMERCIAL' || plan?.subgrouping?.name === 'TRIMESTRALES') && plan?.timeframe === 'TRIMESTRAL' && plan?.endDate === globalFilter?.dateRange?.split(',')[2]))[0]?.code;

  const varsGrouping: any = {
    code: planCode,
    selectedScope: globalFilter.selectedScope ? globalFilter.selectedScope : undefined,
    category: globalFilter.category ? globalFilter.category : undefined,
    dgt: globalFilter.dgt ? globalFilter.dgt : undefined,
    dt: globalFilter.dt ? globalFilter.dt : undefined,
    od: globalFilter.od ? globalFilter.od : undefined,
    firstName: globalFilter.firstName ? globalFilter.firstName : undefined,
    lastName: globalFilter.lastName ? globalFilter.lastName : undefined,
    nif: globalFilter.nif ? globalFilter.nif : undefined,
    myTeamData: false,
  };

  Object.keys(varsGrouping).forEach(
    (key:any) => (varsGrouping[key] === undefined || varsGrouping[key] === '') && delete varsGrouping[key],
  );

  const {
    data: dataGroupedResults,
    refetch: refetchGroupedResults,
  } = useQuery(
    ['CampaignsGroupedResults', varsGrouping],
    async () => (graphQLClient && graphQLClient.request(
      GET_COMMERCIAL_CAMPAIGN_DATA_GQL,
      varsGrouping,
    )),
    {
      refetchOnMount: false,
      enabled: false,
    },
  );

  const {
    data: dataPlanResultByCode,
    refetch: refetchPlanResultByCode,
    isFetching: isFetchingPlanResultByCode,
  } = useQuery(
    ['CampaignMgp', planCode],
    async () => (graphQLClient && graphQLClient.request(
      GET_RESULTS_BY_CODE_GQL,
      { code: planCode },
    )),
    {
      refetchOnMount: false,
      enabled: false,
    },
  );

  const { getPlan: plan } = useGlobalPosition(dataPlan);

  const {
    data: dataTopPlans,
    error: errorTopPlans,
    refetch: refetchTopPlans,
  } = useQuery(
    ['CampaignCommercial', variables('Campaign')],
    async () => graphQLClient && graphQLClient.request(GET_TOP_3_CAMPAIGN_USERS_GQL, variables('Campaign')),
  );

  const commercialPointsAndPositionParams = {
    grouping: globalFilter.grouping || '',
    subgrouping: globalFilter.subgrouping || '',
    type: 'Campaign',
    userId: userInfo?.me?.id,
    startDate: globalFilter?.dateRange?.split(',')[1]?.toString() || '',
    endDate: globalFilter?.dateRange?.split(',')[2]?.toString() || '',
    timeframe: globalFilter?.dateRange?.split(',')[0]?.toString() || '',
    year,
  };

  const {
    data: dataCommercialPointsAndPosition,
    refetch: refetchCampaignComercialPointsAndPosition,
  } = useQuery(
    ['CampaignCommercialPointsAndPosition', commercialPointsAndPositionParams],
    async () => (graphQLClient && graphQLClient.request(
      GET_COMMERCIAL_CAMPAIGN_POINTS_AND_POSITION,
      commercialPointsAndPositionParams,
    )),
    {
      refetchOnMount: false,
      enabled: false,
    },
  );

  const calculateObjectivesParams = {
    type: 'Campaign',
    endDate: globalFilter?.dateRange?.split(',')[2]?.toString() || '',
    grouping: globalFilter.grouping || '',
    userId: userInfo?.me?.id,
    subgrouping: globalFilter.subgrouping || '',
    year,
  };

  const {
    data: dataCalculateObjective,
    refetch: refetchCalculateObjective,
  } = useQuery(
    ['CampaignCalculateObjectives', calculateObjectivesParams],
    async () => (graphQLClient && graphQLClient.request(
      GET_CALCULATE_OBJECTIVES,
      calculateObjectivesParams,
    )),
    {
      refetchOnMount: false,
      enabled: false,
    },
  );

  useEffect(() => {
    setFilters({
      temporalidad: [], campaignType: [], year: [], status: [],
    });
  }, []);

  const resultCommercialPoints: any = {};

  useEffect(() => {
    refetchPlan();
    if ((globalFilter.grouping === 'COMERCIAL' || globalFilter.subgrouping === 'TRIMESTRALES') && isFetchingPlan === false) {
      setFilters({ campaignType: [], year: [] });
      if (globalFilter.dateRange === undefined || globalFilter.dateRange === '') {
        const currentMonth = new Date().getMonth();
        let range = '';
        // Months from 0 to 11
        if (currentMonth <= 2) {
          range = `TRIMESTRAL,${year}-01-01,${year}-03-31`;
        } else if (currentMonth <= 5) {
          range = `TRIMESTRAL,${year}-04-01,${year}-06-30`;
        } else if (currentMonth <= 8) {
          range = `TRIMESTRAL,${year}-07-01,${year}-09-30`;
        } else {
          range = `TRIMESTRAL,${year}-10-01,${year}-12-31`;
        }
        setGlobalFilter({
          ...globalFilter,
          dateRange: range,
        });
      }
      if (dataPlan?.me?.id !== undefined && globalFilter.dateRange !== undefined && globalFilter.dateRange !== '') {
        refetchTopPlans();
        refetchCalculateObjective();
      }
      refetchCampaignComercialPointsAndPosition();
    } else if (globalFilter.grouping === 'COMERCIAL' || globalFilter.subgrouping === 'TRIMESTRALES') {
      setFilters({ campaignType: [], year: [] });
    } else {
      setFilters({
        temporalidad: [], campaignType: [], year: [], status: [],
      });
    }
    refetchMpgChartByTimeframe();
  }, [globalFilter.dateRange,
    globalFilter.grouping,
    globalFilter.subgrouping,
    globalFilter.status]);

  useEffect(() => {
    if (globalFilter.grouping !== 'COMERCIAL' && globalFilter.subgrouping !== 'TRIMESTRALES') {
      refetchPlan();
    }
    if (planCode !== undefined && isFetchingPlan === false) {
      refetchGroupedResults();
      if (isFetchingPlanResultByCode === false) {
        refetchPlanResultByCode();
      }
    }
  }, [planCode, globalFilter.grouping, globalFilter.subgrouping]);

  useEffect(() => {
    switch (searchParams.get('type')) {
    case 'comercial':
      setGlobalFilter({
        ...globalFilter,
        grouping: 'COMERCIAL',
      });
      break;
    case 'especifica':
      setGlobalFilter({
        ...globalFilter,
        grouping: 'ESPECIFICA',
      });
      break;
    case 'retos':
      setGlobalFilter({
        ...globalFilter,
        grouping: 'RETOS',
      });
      break;
    case 'nacionales':
      setGlobalFilter({
        ...globalFilter,
        grouping: 'NACIONALES',
      });
      break;
    default:
      break;
    }

    if (globalFilter.grouping !== undefined || globalFilter.subgrouping !== undefined) {
      switch (searchParams.get('timeframe')) {
      case '1T':
        setTimeout(
          () => {
            setGlobalFilter({
              ...globalFilter,
              dateRange: `TRIMESTRAL,${year}-01-01,${year}-03-31`,
            });
          },
          1200,
        );
        break;
      case '2T':
        setTimeout(
          () => {
            setGlobalFilter({
              ...globalFilter,
              dateRange: `TRIMESTRAL,${year}-04-01,${year}-06-30`,
            });
          },
          1200,
        );
        break;
      case '3T':
        setTimeout(
          () => {
            setGlobalFilter({
              ...globalFilter,
              dateRange: `TRIMESTRAL,${year}-07-01,${year}-09-30`,
            });
          },
          1200,
        );
        break;
      case '4T':
        setTimeout(
          () => {
            setGlobalFilter({
              ...globalFilter,
              dateRange: `TRIMESTRAL,${year}-10-01,${year}-12-31`,
            });
          },
          1200,
        );
        break;
      case 'Anual':
        setTimeout(
          () => {
            setGlobalFilter({
              ...globalFilter,
              dateRange: 'ANUAL',
            });
          },
          1200,
        );
        break;
      case 'Semanal':
        setTimeout(
          () => {
            setGlobalFilter({
              ...globalFilter,
              dateRange: 'SEMANAL',
            });
          },
          1200,
        );
        break;
      case 'Quincenal':
        setTimeout(
          () => {
            setGlobalFilter({
              ...globalFilter,
              dateRange: 'QUINCENAL',
            });
          },
          1200,
        );
        break;
      case 'Bimestral':
        setTimeout(
          () => {
            setGlobalFilter({
              ...globalFilter,
              dateRange: 'BIMESTRAL',
            });
          },
          1200,
        );
        break;
      case 'Cuatrimestral':
        setTimeout(
          () => {
            setGlobalFilter({
              ...globalFilter,
              dateRange: 'CUATRIMESTRAL',
            });
          },
          1200,
        );
        break;

      default:
        break;
      }
    }
  }, [isFetchingPlan === false || location]);

  if (dataCommercialPointsAndPosition !== undefined) {
    const commercialPoints = dataCommercialPointsAndPosition?.commercialPointsAndPositions?.points;
    if (globalFilter?.dateRange?.split(',')[1]?.toString() === `${year}-01-01`) {
      resultCommercialPoints['1T'] = commercialPoints;
    } else if (globalFilter?.dateRange?.split(',')[1]?.toString() === `${year}-04-01`) {
      resultCommercialPoints['2T'] = commercialPoints;
    } else if (globalFilter?.dateRange?.split(',')[1]?.toString() === `${year}-07-01`) {
      resultCommercialPoints['3T'] = commercialPoints;
    } else if (globalFilter?.dateRange?.split(',')[1]?.toString() === `${year}-10-01`) {
      resultCommercialPoints['4T'] = commercialPoints;
    }
  }

  if (errorPlan || errorTopPlans) {
    return <Container>Error</Container>;
  }

  const dataComercialCampaign = dataTopPlans?.topPlans.map((p: IDataCommercial, index: number) => ({
    position: index + 1,
    dgt: p?.territoryNameDgt !== null ? `${p?.dgt} - ${p?.territoryNameDgt}` : p?.dgt,
    dt: p?.territoryNameDt !== null ? `${p?.dt} - ${p?.territoryNameDt}` : p?.dt,
    od: p?.territoryNameOd !== null ? `${p?.od} - ${p?.territoryNameOd}` : p?.od,
    denomination: `${p?.firstName} ${p?.lastName}`,
    points: p?.points === null ? 0 : useNumberFormat(p?.points, true),
  }));

  const reducer = (accumulator: number, curr: number) => accumulator + curr;

  const getResultsPlans = dataPlan?.me?.plans?.nodes
    ?.map((p:any) => p?.results?.nodes
      ?.map((r:any) => r));

  const getExtraFields = getResultsPlans?.map((r:any) => (r[0]?.extraFields)) || [];
  const ranking = getExtraFields.includes(undefined) ? 0 : getExtraFields?.map((r:any) => (r['Posición Ranking'] || r['Posicion Ranking'] || 0));
  const points = getExtraFields.includes(undefined) ? 0 : getExtraFields?.map((r:any) => ((r['Total Puntos']) || r['TOTAL PUNTOS'] || 0));
  const objectives = getExtraFields.includes(undefined) ? 0 : getExtraFields?.map((r:any) => (r['Objetivos Cumplidos'] !== undefined ? r['Objetivos Cumplidos'] : 0));
  const getWin = getExtraFields.includes(undefined) ? ['NO'] : getExtraFields?.map((r:any) => (r.GANA !== undefined ? r.GANA : 'NO'));
  const preWin = getWin?.filter((item: any, index: any) => getWin.indexOf(item) === index);
  const win = preWin.length > 1 ? 'NO' : preWin[0];

  const scoreComercial = [{
    id: 0, title: 'Gana', score: win, icon: 'cup',
  },
  {
    id: 2, title: 'Ranking', maintitle: 'Posición', score: ranking === 0 ? 0 : ranking?.reduce(reducer, 0), icon: 'ranking',
  },
  {
    id: 3, title: 'Puntos', subtitle: 'totales', score: points === 0 ? 0 : Math.round(points?.reduce(reducer, 0)), icon: 'start',
  },
  {
    id: 4, title: 'Objetivos', subtitle: 'cumplidos', score: objectives === 0 ? 0 : (objectives?.reduce(reducer, 0)), icon: 'objectives',
  }];

  let dcoKeys: string[] = [];
  const groupedResults = dataCalculateObjective?.calculateObjective?.groupedResult;
  if (groupedResults !== undefined) {
    dcoKeys = Object.keys(groupedResults);
  }

  const arrayCalculateObjective: any = dcoKeys?.map((key: string, index: number) => ({
    id: index,
    name: groupedResults[key]?.name,
    points: groupedResults[key]?.puntos,
    value: groupedResults[key]?.puntos,
    total: groupedResults[key]?.objetivo,
    totalPoints: groupedResults[key]?.total_points,
  }));

  let dataQueryComer :any = [];
  let datQueryComSub: any = [];
  let dataTableCom: any = [];
  const colsSubLevel:any = [];
  const uniqueCols:any = [];

  const colsComercialDynamic: any = [];
  const orderedColsComercialDynamic: any = [];
  let orderedColsCsv: any = [];
  colsComercialDynamic.push(colsComercial[0]);

  if (dataGroupedResults !== undefined && dataPlanResultByCode !== undefined) {
    dataQueryComer = dataGroupedResults?.myTeamCommercialGroupedResultsByCode?.groupedDataScope;
    datQueryComSub = dataGroupedResults?.myTeamCommercialGroupedResultsByCode?.groupedResultByObjs;

    const formattedDatQueryComSub:any = {};
    let translations:any = {};
    if (plan[0]?.translations !== undefined) {
      translations = JSON.parse(plan[0]?.translations);
    }

    const formatValue = (fieldValue: any, col: string) => {
      if (typeof (fieldValue) === 'number') {
        const colInTranslations = Object.values(translations).includes(col);
        const trValues = Object.values(translations);
        const translationsWithoutSpaces: any = {};
        if (typeof translations !== 'undefined') {
          Object.entries(translations)?.forEach(([keyT, valueT]) => {
            translationsWithoutSpaces[keyT.replace(/ /g, '')] = `${valueT}`.replace(/ /g, '');
          });
        }

        if (typeof fieldValue === 'number') {
          if (translationsWithoutSpaces[col.replace(/ /g, '')]?.includes('(decimales)')
            || translationsWithoutSpaces[col.replace(/ /g, '')]?.includes('(porcentaje)')
          ) {
            if (translationsWithoutSpaces[col.replace(/ /g, '')].includes('(porcentaje)')) {
              return `${useNumberFormat(fieldValue * 100, true)} %`;
            }
            return `${useNumberFormat(fieldValue, true)}`;
          }
        }

        if (colInTranslations
          && (`${trValues.find((e:any) => e === col)}`?.includes('(decimales)')
          || translationsWithoutSpaces[col.replace(/ /g, '')]?.includes('(porcentaje)'))) {
          if (`${trValues.find((e:any) => e === col)}`?.includes('(porcentaje)')) {
            return `${useNumberFormat(fieldValue * 100, true)} %`;
          }
          return `${useNumberFormat(fieldValue, true)}`;
        }
        return useNumberFormat(fieldValue, false);
      }
      return fieldValue;
    };

    let hashDataFormatted:string = '';
    Object.keys(datQueryComSub).map((k:string) => {
      formattedDatQueryComSub[k] = [];
      if (datQueryComSub[k].length > 0) {
        datQueryComSub[k]?.map((hashData:any) => {
          const hashObjective:any = {};
          Object.keys(hashData)?.map((c: string) => {
            const formattedTransValues = Object?.values(translations)
              ?.map((e:any) => useRemoveAccents(e?.replace('(decimales)', '')?.replace('(porcentaje)', '')?.trim()));

            hashDataFormatted = useRemoveAccents(hashData?.groupedItem);

            const isInValues = formattedTransValues?.includes(`${c} ${hashDataFormatted}`);

            const colFromTranslations = isInValues ? Object.keys(translations).map((e:any) => {
              if (useRemoveAccents(translations[e]?.replace('(decimales)', '')?.replace('(porcentaje)', '')?.trim()) === `${c} ${hashDataFormatted}`) {
                return e;
              }
              return undefined;
            }).filter((element: any) => element !== undefined)[0] : c;

            hashObjective[c] = formatValue(hashData[c], colFromTranslations);
            return hashObjective;
          });
          formattedDatQueryComSub[k].push(hashObjective);
          return hashData;
        });
      }
      return formattedDatQueryComSub;
    });

    dataTableCom = dataQueryComer?.map(
      (k:any) => {
        const arrayTableComercial = Object.values(k)?.map((e: any, index:number) => {
          if (e.length > 0 && k !== undefined) {
            const hashDynamicData: any = {};
            const personalData:any = dataPlanResultByCode?.myTeamResultsByCode?.nodes?.find(
              (r:any) => parseInt(r?.id, 10) === e[index].result_id,
            );
            Object.keys(e[index]).map((a: any) => {
              hashDynamicData[a?.replace(/ /g, '')] = e[index][a];
              return hashDynamicData;
            });
            return ({
              ...hashDynamicData,
              territory: Object.keys(k)[0],
              totalPoints: useNumberFormat(e[index]['Total Puntos'], true),
              objs: e[index]['Objetivos Cumplidos'],
              ranking: e[index]['Posición Ranking'],
              win: e[index]?.GANA,
              NIF: personalData?.nif,
              denomination: `${personalData?.firstName} ${personalData?.lastName}`,
              subLevelData: formattedDatQueryComSub,
              group: formattedDatQueryComSub[Object.keys(k)[0]],
              dataDate: useFormatDate(e[index].data_date),
              translations: plan[0]?.translations,
            });
          }
          return undefined;
        });
        return arrayTableComercial;
      },
    ).flat().filter((element:any) => element !== undefined);

    if (dataTableCom !== undefined) {
      const getGroup = dataTableCom?.map((co: any) => co.group);

      getGroup.map((e:any) => (
        Object.keys(e).map((c:any) => (!colsSubLevel.includes(c)
        && colsSubLevel.push(c)))
      ));

      uniqueCols.unshift(...headersCampaignByCode);

      const mergeWith = dataTableCom.map((item: any, i:number) => ({ ...item, ...getGroup[i] }));

      const groupedItems = mergeWith[0].group.map((x: { groupedItem: any; }) => x.groupedItem);
      const columnsGroupedItems = mergeWith[0]?.group?.[0]
        ? Object.keys(mergeWith[0]?.group?.[0]) : [];
      columnsGroupedItems.pop(); // Delete groupedItem element
      const colNames: any[] = [];

      const formatColumn = (extraField: string) => {
        if (translations[extraField]) {
          if (translations[extraField]?.includes('(decimales)')) {
            return translations[extraField]?.replace(/\(decimales\)/gi, '');
          }
          if (translations[extraField]?.includes('porcentaje')) {
            return translations[extraField]?.replace(/\(porcentaje\)/gi, '');
          }
          return translations[extraField];
        }
        return extraField;
      };
      groupedItems.map((gI: any) => columnsGroupedItems.map((colGI) => colNames.push({ label: `${gI} ${colGI}`, key: colGI })));

      uniqueCols.push(...colNames);

      const resultsComer = plan[0]?.results?.nodes[0]?.extraFields;

      const planLevels1 = plan[0]?.planLevels?.filter((a: any) => a.level === 1)?.sort((a: any, b: any) => a.order - b.order)?.map((e: any) => e?.field?.replace(/ /g, '').split('.').join('').toLowerCase());
      const planLevelsObject1 = plan[0]?.planLevels?.filter(
        (a: any) => a.level === 1,
      )?.sort((a: any, b: any) => a.order - b.order);

      if (resultsComer) {
        const resultComerKeys = Object.keys(resultsComer);

        resultComerKeys.push('NIF', 'denomination');

        resultComerKeys.map((pf: any) => {
          if (planLevels1.includes(pf?.replace(/ /g, '')?.split('.')?.join('')?.toLowerCase()) && !pf.includes('dgt', 'dt', 'od')) {
            colsComercialDynamic.push({ Header: pf, accessor: pf?.replace(/ /g, '') });
          }
          return colsComercialDynamic;
        });
      }

      orderedColsComercialDynamic.push(colsComercial[0]);
      const accessors = colsComercialDynamic?.map((e:any) => e.accessor);
      planLevelsObject1?.map((pl:any) => {
        if (accessors?.includes(
          pl.field.replace(/ /g, '').split('.').join(''),
        )) {
          if (pl.field.toLowerCase() === 'denomination') {
            orderedColsComercialDynamic.push({ Header: 'Denominación', accessor: 'denomination' });
          } else {
            orderedColsComercialDynamic.push({
              Header: formatColumn(pl.field
                .split('.').join('')),
              accessor: accessors.find((e: any) => e === pl.field
                ?.replace(/ /g, '')?.split('.')?.join('')),
            });
          }
        }
        return orderedColsComercialDynamic;
      });
      orderedColsCsv = orderedColsComercialDynamic.map(
        (e:any) => ({ label: e.Header, key: e.accessor }),
      );
    }
  }

  const plans = dataPlan?.me?.plans?.nodes?.map((p:any) => p);

  const results = dataPlan?.me?.plans?.nodes
    ?.map((p:any) => p?.results?.nodes
      ?.map((r:any) => r));

  const extra = dataPlan?.me?.plans?.nodes
    ?.map((p:any) => p?.results?.nodes
      ?.map((r:any) => r?.extraFields));

  const merge = plans?.map((p:any, i:any) => ({ ...p, ...results[i][0], ...extra[i][0] }));

  const processHeaders = plans?.map((p:any) => JSON.parse(p.translations));
  const processHeadersObjects: any = {};
  const processHeadersObjectsDecimals: any = [];

  processHeaders?.forEach((ph:any) => {
    Object.entries(ph).forEach(([key, value]: any) => {
      processHeadersObjects[key] = value.replace(' (decimales)', '');
    });
  });

  processHeaders?.forEach((ph:any) => {
    Object.entries(ph).forEach((item: any) => {
      let decimals = '';
      if (item[1].includes(' (decimales)')) {
        decimals = '(decimales)';
      }
      processHeadersObjectsDecimals.push(`${item[0]} ${decimals}`);
    });
  });

  const dataCsv = merge?.map((p:any) => ({
    ...p,
    Campaña: p?.description,
    Importe: useNumberFormat(p?.amount),
    GANA: p?.GANA !== undefined ? p?.GANA : 'NO',
    'Fecha inicio': p?.startDate !== undefined ? useFormatDate(p?.startDate) : '',
    'Fecha fin': p?.endDate !== undefined ? useFormatDate(p?.endDate) : '',
    amount: useNumberFormat(p?.resultsAmount),
    dgt: `${p?.dgt} - ${p?.territoryNameDgt}`,
    dt: p?.territoryNameDt ? `${p?.dt} - ${p?.territoryNameDt}` : p?.dt,
    od: p?.territoryNameOd ? `${p?.od} - ${p?.territoryNameOd}` : p?.od,
    'NP Autos Individual': useNumberFormat(p?.['NP Autos Individual']),
    'Objetivo Autos Individual': useNumberFormat(p?.['Objetivo Autos Individual']),
    '% Incremento Autos Individual': `${useNumberFormat(p?.['% Incremento Autos Individual'])}%`,
    'NP Riesgo+Accidentes': useNumberFormat(p?.['NP Riesgo+Accidentes']),
    'Objetivo Riesgo+Accidentes': useNumberFormat(p?.['Objetivo Riesgo+Accidentes']),
    'Riesgo y Accidentes Puntos Ponderados': useNumberFormat(p?.['Riesgo y Accidentes Puntos Ponderados']),
    'Porcentaje Cumplimiento': `${useNumberFormat(p?.['Porcentaje Cumplimiento'])}%`,
    'Situación Falta': useNumberFormat(p?.['Situación Falta']),
    'Primas NP Autos Individual': useNumberFormat(p?.['Primas NP Autos Individual']),
    'Presupuesto NP Autos Individual': useNumberFormat(p?.['Presupuesto NP Autos Individual']),
    'Autos Individual Puntos Ponderados': useNumberFormat(p?.['Autos Individual Puntos Ponderados']),
    'Producto DGT Puntos Ponderados': useNumberFormat(p?.['Producto DGT Puntos Ponderados']),
    '% Presupuesto Autos Individual': `${useNumberFormat(p?.['% Presupuesto Autos Individual'])}%`,
    'Mínimo Tejido Comercial': useNumberFormat(p?.['Mínimo Tejido Comercial']),
    '% Presupuesto': `${useNumberFormat(p?.['% Presupuesto'])}%`,
    '% Presupuesto N Seguros': `${useNumberFormat(p?.['% Presupuesto N Seguros'])}%`,
    incrementRiskAccident: `${useNumberFormat(p?.['% Incremento Riesgo+Accidentes'])}%`,
    objCampaign: useNumberFormat(p?.['Objetivo Campaña']),
    primaNp2021: useNumberFormat(p?.['Primas NP 2021']),
    primaNp2022: useNumberFormat(p?.['Primas NP 2022']),
    prodDgt: `${useNumberFormat(p?.['% Incremento Prod.DGT'])}%`,
    npProdDgt: useNumberFormat(p?.['NP Prod.DGT']),
    objProdDgt: useNumberFormat(p?.['Objetivo Prod.DGT']),
    'FECHA ACT': p?.dataDate !== undefined && p?.dataDate !== null ? useFormatDate(p?.dataDate?.split('T')[0]) : useFormatDate(p?.['FECHA ACT']),
  }));

  const uniqueHeaders: any = [];
  const getLevelsPlans = dataPlan?.me?.plans?.nodes
    ?.map((p: any) => p?.planLevels
      ?.map((l: any) => l)).flat();
  const headersFiltered = getLevelsPlans
    ?.sort((a: any, b: any) => a.order - b.order)
    ?.map((level: any) => level?.field);
  headersFiltered?.forEach((element: any) => (!uniqueHeaders
    .includes(element)
    ? uniqueHeaders.push(element)
    : (uniqueHeaders?.splice(uniqueHeaders?.indexOf(element), 1)
      && uniqueHeaders.push(element))));

  const headersFirst = ['Campaña', 'Gana', 'Importe', 'Fecha inicio', 'Fecha fin'];
  uniqueHeaders.unshift(...headersFirst);

  let filteredColumns:any = columns;
  if (mediadores.includes(dataPlan?.me?.calculatedCategory)) {
    filteredColumns = columns.filter((c: any) => c.accessor !== 'comparative');
  }

  const handleClick = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setGlobalFilter({ ...globalFilter, dateRange: e.currentTarget.value });
    setClickedId(e.currentTarget.value?.split(',')[2]);
    const temporality = e.currentTarget.value;

    switch (temporality) {
    case `TRIMESTRAL,${year}-01-01,${year}-03-31`:
      setSearchParams({
        ...searchParams,
        timeframe: 'T1',
      });
      break;
    case `TRIMESTRAL,${year}-04-01,${year}-06-30`:
      setSearchParams({
        ...searchParams,
        timeframe: 'T2',
      });
      break;
    case `TRIMESTRAL,${year}-07-01,${year}-09-30`:
      setSearchParams({
        ...searchParams,
        timeframe: 'T3',
      });
      break;
    case `TRIMESTRAL,${year}-10-01,${year}-12-31`:
      setSearchParams({
        ...searchParams,
        timeframe: 'T4',
      });
      break;
    case 'Anual':
      setTimeout(
        () => {
          setGlobalFilter({
            ...globalFilter,
            dateRange: 'ANUAL',
          });
        },
        1200,
      );
      break;
    case 'Semanal':
      setTimeout(
        () => {
          setGlobalFilter({
            ...globalFilter,
            dateRange: 'SEMANAL',
          });
        },
        1200,
      );
      break;
    case 'Quincenal':
      setTimeout(
        () => {
          setGlobalFilter({
            ...globalFilter,
            dateRange: 'QUINCENAL',
          });
        },
        1200,
      );
      break;
    case 'Bimestral':
      setTimeout(
        () => {
          setGlobalFilter({
            ...globalFilter,
            dateRange: 'BIMESTRAL',
          });
        },
        1200,
      );
      break;
    case 'Cuatrimestral':
      setTimeout(
        () => {
          setGlobalFilter({
            ...globalFilter,
            dateRange: 'CUATRIMESTRAL',
          });
        },
        1200,
      );
      break;
    default:
      break;
    }
  };

  const processDataCsv = dataCsv?.map((d: any) => ({ ...d }));
  processDataCsv?.forEach((item: any, index: number) => {
    Object.entries(item).forEach(([key, value]: any) => {
      let decimals = false;
      if (processHeadersObjectsDecimals.includes(`${key} (decimales)`)) {
        decimals = true;
      }
      processDataCsv[index][key] = useCsvNumber(value, decimals);
    });
  });

  const processHeadersFirst = {
    Campaña: 'Campaña',
    GANA: 'Gana',
    IMPORTE: 'Importe',
    'Fecha inicio': 'Fecha inicio',
    'Fecha fin': 'Fecha fin',
    planStatusName: 'Estado',
    'FECHA ACT': 'FECHA ACT',
  };

  const newProcessHeader = { ...processHeadersFirst };

  const processHedersCSV: any[] = [];

  const processHedersCSVKeys: any[] = [];
  Object.entries(newProcessHeader).forEach(([key, value]: any) => {
    if (!processHedersCSVKeys.includes(key)) {
      processHedersCSVKeys.push(key);
      processHedersCSV.push({ label: useMappingColumnNames(value, 'campaign'), key: useControlledColumns(key) });
    }
  });

  const processPlan = useProcessData(plan);
  const processDataTableCom = useProcessData(dataTableCom);
  const processDataCom = useProcessData(dataComercialCampaign);

  return (
    <Container>
      {Object.getOwnPropertyNames(filters).length > 0 ? (
        <GroupFilterGeneral
          selectedFilters={filters}
          filter={globalFilter}
          setGlobalFilter={updateGlobalFilter}
        />
      ) : (<FilterFormSkeleton countFilters={3} />)}

      <ButtonBack onClick={() => navigate('/global-position/summary')} type="button">Volver</ButtonBack>

      <Typography color={Theme.colorCampaign} size="32px" margin="0 0 2rem" display="block" align="center" weight="500">Campañas</Typography>

      {(globalFilter.grouping === 'COMERCIAL' || globalFilter.subgrouping === 'TRIMESTRALES') && (
        <TabWrapper>
          <TabContainer>
            <StyledTabButton
              type="button"
              theme={Theme.colorCampaign}
              value={`TRIMESTRAL,${year}-01-01,${year}-03-31`}
              className={`${year}-03-31` === clickedId || `${year}-03-31` === globalFilter.dateRange?.split(',')[2] ? 'active' : ''}
              onClick={handleClick}
            >
              <span>1er TRIMESTRE</span>
            </StyledTabButton>
            <StyledTabButton
              type="button"
              theme={Theme.colorCampaign}
              value={`TRIMESTRAL,${year}-04-01,${year}-06-30`}
              className={`${year}-06-30` === clickedId || `${year}-06-30` === globalFilter.dateRange?.split(',')[2] ? 'active' : ''}
              onClick={handleClick}
            >
              <span>2do TRIMESTRE</span>
            </StyledTabButton>
            <StyledTabButton
              type="button"
              theme={Theme.colorCampaign}
              value={`TRIMESTRAL,${year}-07-01,${year}-09-30`}
              className={`${year}-09-30` === clickedId || `${year}-09-30` === globalFilter.dateRange?.split(',')[2] ? 'active' : ''}
              onClick={handleClick}
            >
              <span>3er TRIMESTRE</span>
            </StyledTabButton>
            <StyledTabButton
              type="button"
              theme={Theme.colorCampaign}
              value={`TRIMESTRAL,${year}-10-01,${year}-12-31`}
              className={`${year}-12-31` === clickedId || `${year}-12-31` === globalFilter.dateRange?.split(',')[2] ? 'active' : ''}
              onClick={handleClick}
            >
              <span>4to TRIMESTRE</span>
            </StyledTabButton>
          </TabContainer>
        </TabWrapper>
      )}

      {!isFetchingMpgChartByTimeframe ? (
        <Score score={scoreCampaign} cols={4} maxWidth="570px" />
      ) : (<SkeletonCard cols={4} maxWidth="570px" />)}

      {(processDataCsv?.length > 0) ? (
        <CardDataExportXLSX
          headers={processHedersCSV}
          data={processDataCsv}
          name="campaign_global_position"
        />
      ) : <div style={{ height: '53px' }} />}

      {(!isFetchingPlan && dataPlan) ? (
        <Table
          columns={filteredColumns}
          data={processPlan}
          theme={Theme.colorCampaign}
          renderRowSubComponent={renderRowSubComponent}
          className="tb-campaign__global-position"
        />
      ) : <TableSkeleton countCell={6} theme={Theme.colorCampaign} />}

      {(globalFilter.grouping === 'COMERCIAL' || globalFilter.subgrouping === 'TRIMESTRALES') && (
        <div>
          <div className="line" />

          <Grid
            regular
            columnsLg={2}
            rowHeight={['302px', '302px']}
            spaceBetween={2}
            alignItems="center"
          >
            <Graphic
              type="bar"
              title="Total de puntos y posición"
              theme={`${Theme.colorCampaign}`}
              scoresTemporalities={[
                {
                  score: resultCommercialPoints['1T'],
                  temp: 'T1',
                  timeframe: `TRIMESTRAL,${year}-01-01,${year}-03-31`,
                },
                {
                  score: resultCommercialPoints['2T'],
                  temp: 'T2',
                  timeframe: `TRIMESTRAL,${year}-04-01,${year}-06-30`,
                },
                {
                  score: resultCommercialPoints['3T'],
                  temp: 'T3',
                  timeframe: `TRIMESTRAL,${year}-07-01,${year}-09-30`,
                },
                {
                  score: resultCommercialPoints['4T'],
                  temp: 'T4',
                  timeframe: `TRIMESTRAL,${year}-10-01,${year}-12-31`,
                },
              ]}
            />
            {!isFetchingPlan ? (
              <Score score={scoreComercial} cols={4} maxWidth="100%" minWidth="100%" />
            ) : (<SkeletonCard cols={4} maxWidth="100%" />)}
          </Grid>

          <Card
            background="transparent"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
            gap="1.5rem"
            margin="0 0 2.5rem"
          >
            {dataCalculateObjective && isFetchingPlan === false && arrayCalculateObjective
              ?.map((item: any) => (item?.points !== undefined && (
                <Card
                  key={item.id}
                  padding="1.5rem"
                  alignItems="center"
                  justifyContent="space-between"
                  radius="0.5rem"
                  gap="0.5rem"
                  width="500px"
                  margin="30px"
                >
                  <div className="data_campaign_global_position">
                    <Typography size="20px" weight="600" type="h3" display="block" margin="0 0 1rem">
                      {item?.name}
                    </Typography>
                    <StyledHeaderAmount align="center" display="flex">
                      <Typography type="span" margin="0 auto 0 0" display="block" align="left">
                        Puntos
                      </Typography>
                      <Typography color={Theme.colorCampaign} size="22px" margin="0" weight="500">
                        {useNumberFormat(item?.totalPoints)}
                      </Typography>
                    </StyledHeaderAmount>
                  </div>
                  <div className="graphic_campaign_global_position">
                    <Graphic
                      type="pie"
                      theme={Theme.colorCampaign}
                      score={item?.points}
                      total={item?.total}
                      showData={false}
                    />
                    <StyledData theme={Theme.colorCampaign}>
                      <b>{`${useNumberFormat(item?.points)}€ NP`}</b>
                      <span>{`${useNumberFormat(item?.total)}€`}</span>
                    </StyledData>
                  </div>
                </Card>
              )))}
          </Card>

          <CardDataExportXLSX
            headers={orderedColsCsv}
            data={useProcessDataCSV(processDataTableCom)}
            margin="0 0 0.5rem auto"
            name={`campaign_${planCode}_results`}
          />

          <Table
            columns={orderedColsComercialDynamic}
            data={processDataTableCom}
            theme={Theme.colorCampaign}
            renderRowSubComponent={renderRowSubComponentCommercial}
          />

          {(columnsCommercial && dataComercialCampaign) && (
            <>
              <Typography align="center" color={Theme.colorCampaign} size="28px" display="block" margin="3rem auto 1rem auto">
                Top 3 Campañas comerciales
              </Typography>

              <CardDataExportXLSX
                headers={headersTop3Csv}
                data={useProcessDataCSV(dataComercialCampaign)}
                name="campaign_top_3_global_position"
              />

              <Table
                columns={columnsCommercial}
                data={processDataCom}
                theme={Theme.colorCampaign}
                className="tb-campaign__top-3"
              />
            </>
          )}
        </div>
      )}
    </Container>
  );
}
