import Theme from '../../../styles/Theme';
import IconArrow from '../../atoms/Icon/IconArrow';
import LinkTo from '../../atoms/Link/LinkTo';
import { LinkTable, Table } from '../../atoms/Table/Table';

export function renderRowSubComponent(results: any) {
  if (results?.row !== undefined) {
    const data = results?.row?.original?.subLevelData;
    const office = results?.row?.values?.territory;

    const colsSubLevel:any = [];
    if (Object.entries(data).length > 0) {
      data[office].map((e:any) => (
        Object.keys(e).map((c:any) => (!colsSubLevel.includes(c)
        && colsSubLevel.push(c)))
      ));
    }

    const uniqueCols:any = [];
    colsSubLevel.map((c:any) => {
      if (c === 'groupedItem') {
        uniqueCols.push({ Header: 'Agrupación', accessor: c });
      } else {
        uniqueCols.push({ Header: c, accessor: c });
      }
      return uniqueCols;
    });

    // eslint-disable-next-line no-nested-ternary
    uniqueCols.sort((x:any, y:any) => (x.Header === 'Agrupación' ? -1 : y.Header === 'Agrupación' ? 1 : 0));

    return (
      <Table
        columns={uniqueCols}
        data={data[office]}
        isScrollingSubtable={results?.row?.allCells?.length < uniqueCols?.length}
        isSubTable
      />
    );
  }

  return null;
}

export const columnsDetail = [
  { Header: 'DGT', accessor: 'dgt' },
  { Header: 'DT', accessor: 'dt' },
  {
    id: 'od',
    Header: 'OD',
    accessor: 'od',
  },
  { Header: 'Denominación', accessor: 'denomination' },
  { Header: 'Total puntos', accessor: 'amount' },
  { Header: 'Posición ranking', accessor: 'rankingGroup' },
  {
    id: 'wins',
    Header: 'Gana',
    accessor: 'wins',
    Cell: ({ row }:any) => (
      row?.values?.wins !== false ? 'SI' : 'NO'
    ),
  },
];

export const columnsSubDetail = [
  { Header: 'Agrupación' },
  { Header: 'Primas NP', Cell: ({ row }:any) => Object.values(row?.original?.extraFields)[3] },
  { Header: 'Objetivo' },
  { Header: '% Sobre comisión', Cell: ({ row }:any) => Object.values(row?.original?.extraFields)[2] },
  { Header: 'Puntos ponderados' },
];

export const headers = [
  { label: 'Convención', key: 'conventionName' },
  { label: 'Participa', key: 'participants' },
  { label: 'Gana', key: 'wins' },
  { label: 'Datos a día', key: 'dataDate' },
  { label: 'Estado', key: 'status' },
];

export const columnsSummary = [
  { Header: 'Avísame', accessor: 'subscribed' },
  { Header: 'Convención', accessor: 'conventionName' },
  { Header: 'Participa', accessor: 'participants' },
  { Header: 'Gana', accessor: 'wins' },
  { Header: 'Estado', accessor: 'status' },
  { Header: 'Datos a día', accessor: 'dataDate' },
  { Header: 'Resultados', accessor: 'conventionResults' },
  { Header: 'Bases', accessor: 'download' },
  { Header: 'Ayuda', accessor: 'helpLink' },
];

export const hide = [
  {
    id: 1,
    title: 'Tréboles',
    score: 40,
    icon: 'clover',
  },
];

export const score = [
  {
    id: 0,
    title: 'Ganadas',
    score: 3,
    icon: 'cup',
  },
  {
    id: 2,
    title: 'Puntos',
    score: 500,
    icon: 'start',
  },
  {
    id: 3,
    title: 'Importe',
    subtitle: 'a fecha',
    score: 15.000,
    icon: 'euro',
  },
];

export const columnsRetoResults = [
  { Header: 'DGT', accessor: 'dgt' },
  { Header: 'DT', accessor: 'dt' },
  { Header: 'OD', accessor: 'od' },
  { Header: 'Denominación', accessor: 'denomination' },
  { Header: 'NIF', accessor: 'nif' },
  { Header: 'Cat.', accessor: 'category' },
  { Header: 'Posición ranking', accessor: 'ranking' },
  { Header: 'LLAVE', accessor: 'llave' },
  { Header: 'GANA', accessor: 'win' },
  { Header: 'Datos a día', accessor: 'updatedAt' },
];

export const columnsRetoResultsCsv = [
  { label: 'DGT', key: 'dgt' },
  { label: 'DT', key: 'dt' },
  { label: 'OD', key: 'od' },
  { label: 'Denominación', key: 'denomination' },
  { label: 'NIF', key: 'nif' },
  { label: 'Cat.', key: 'category' },
  { label: 'Posición ranking', key: 'ranking' },
  { label: 'LLAVE', key: 'llave' },
  { label: 'GANA', key: 'win' },
  { label: 'Datos a día', key: 'updatedAt' },
];

export const columnsGrouped = [
  { Header: 'DGT', accessor: 'dgt' },
  { Header: 'DT', accessor: 'dt' },
  { Header: 'OD', accessor: 'od' },
  { Header: 'Denominación', accessor: 'denomination' },
  { Header: 'NIF', accessor: 'nif' },
  { Header: 'Cat.', accessor: 'category' },
  { Header: 'Retos 1T', accessor: 'valort1' },
  { Header: 'Retos 2T', accessor: 'valort2' },
  { Header: 'Retos 3T', accessor: 'valort3' },
  { Header: 'Retos 4T', accessor: 'valort4' },
  { Header: 'Retos Total', accessor: 'valorTotal' },
];

export const columnsGroupedCsv = [
  { label: 'DGT', key: 'dgt' },
  { label: 'DT', key: 'dt' },
  { label: 'OD', key: 'od' },
  { label: 'Denominación', key: 'denomination' },
  { label: 'NIF', key: 'nif' },
  { label: 'Cat.', key: 'category' },
  { label: 'Retos 1T', key: 'valort1' },
  { label: 'Retos 2T', key: 'valort2' },
  { label: 'Retos 3T', key: 'valort3' },
  { label: 'Retos 4T', key: 'valort4' },
  { label: 'Retos Total', key: 'valorTotal' },
];

export const colsComercial = [
  {
    Header: 'Territorio',
    accessor: 'territory',
    Cell: ({ row }:any) => (
      <label {...row.getToggleRowExpandedProps()}>
        <LinkTable title={row.original.territory} onClick={() => renderRowSubComponent(row)}>
          {row.isExpanded
            ? <IconArrow color={`${Theme.colorPrimary}`} rotate />
            : <IconArrow color={`${Theme.colorPrimary}`} /> }
        </LinkTable>
      </label>
    ),
  },
  { Header: 'Total Puntos', accessor: 'totalPoints' },
  { Header: 'Obj. Cumplidos', accessor: 'objs' },
  { Header: 'Posición Ranking', accessor: 'ranking' },
  { Header: 'Gana', accessor: 'win' },
  { Header: 'Datos a día', accessor: 'dataDate' },
];

export const colsSubDetailComercial = [
  { Header: 'Agrupación', accessor: 'goupedItem' },
  { Header: 'NP', accessor: 'np' },
  { Header: 'PPTO', accessor: 'ppto' },
  { Header: '% Presupuesto', accessor: 'presupuesto' },
  { Header: '% Mediadores Productivos', accessor: 'mediadoresProductivos' },
  { Header: '% Oferta', accessor: 'oferta' },
  { Header: '% Conversión', accessor: 'conversion' },
  { Header: 'Pólizas Acc. Sénior', accessor: 'polizasAccSenior' },
  { Header: 'Puntos', accessor: 'puntos' },
];

export const headersCampaignByCode = [
  { label: 'DGT', key: 'dgt' },
  { label: 'Total Puntos', key: 'totalPoints' },
  { label: 'Obj. Cumplidos', key: 'objs' },
  { label: 'Posición Ranking', key: 'ranking' },
  { label: 'Gana', key: 'win' },
  { label: 'Gana Tréboles', key: 'treboleWin' },
  { label: 'Datos a día', key: 'dataDate' },
  { label: 'Prod.DGT', key: '0' },
];

export const columnsConventionDetail = [
  {
    Header: 'Detalle',
    accessor: 'detail',
    Cell: ({ row }:any) => (
      <LinkTo to={`/team/convention/${row?.resultId}/detail`} color={Theme.colorPrimary} style={{ textAlign: 'center', display: 'block' }}>Ver detalle</LinkTo>
    ),
  },
];
