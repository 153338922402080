import { gql } from 'graphql-request';

const GET_USERS_TEAM_GQL = gql`
  query myTeamUsers($year: Int) {
    myTeamUsers(year: $year) {
      nodes {
        value
        label
      }
    }
  }
`;

export default GET_USERS_TEAM_GQL;
