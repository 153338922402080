import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import GET_MY_TEAM_DATA_FILTERS_GQL from '../../../graphql/queries/getMyTeamDataFiltersGql';
import GET_RESULTS_BY_CODE_GQL from '../../../graphql/queries/getResultsByCodeGql';
import Theme from '../../../styles/Theme';
import { ButtonBack } from '../../atoms/Button/StyledButton';
import Container from '../../atoms/Container/Container';
import { Table } from '../../atoms/Table/Table';
import Typography from '../../atoms/Typography/Typography';
import FilterModel from '../../molecules/Filter/FilterModel';
import FilterForm from '../../organisms/FilterForm/FilterForm';
import CategoryModel from '../../models/CategoryModel';
import useYear from '../../../hooks/useYear';
import ParamsModel from '../../models/PaginationModel';
import GET_PLAN_RESULTS_CONCRETE_BY_CODE_GQL from '../../../graphql/queries/getPlanResultsConcreteByCodeGql';
import FilterFormSkeleton from '../../organisms/FilterForm/FilterFormSkeleton';
import TableSkeleton from '../../atoms/Table/TableSkeleton';
import { Category, CategoryReponse } from '../../../interfaces/Category.d';
import DetailResultsAdapter from '../../../adapters/DetailResultsAdapter';
import CardDataExport from '../../molecules/Cards/CardDataExport';
import useExportStore from '../../../store/exportStore';
import Loading from '../../atoms/Loading/Loading';
import useRemoveUndefinedProperties from '../../../hooks/useRemoveUndefinedProperties';
import { createGraphQLClient } from '../../../graphql/graphqlClient';

export default function ConventionDetailResults() {
  const { id } = useParams<string>();
  const graphQLClient = createGraphQLClient();
  const navigate = useNavigate();
  const [globalFilter, setGlobalFilter] = useState<FilterModel>(new FilterModel());
  const [globalParams, setGlobalParams] = useState<ParamsModel>(new ParamsModel());
  const updateGlobalParams = (params: ParamsModel) => setGlobalParams(params);
  const updateGlobalFilter = (newFilter: FilterModel) => setGlobalFilter(newFilter);
  const [category, setCategory] = useState<Category>(new CategoryModel());
  const { year } = useYear();
  const queryClient = useQueryClient();
  const categoryResponse = queryClient.getQueryState<CategoryReponse>(['UserCategory']);

  const Params: any = {
    code: id,
    selectedScope: globalFilter.selectedScope ? globalFilter.selectedScope : undefined,
    category: globalFilter.category ? globalFilter.category : undefined,
    dgt: globalFilter.dgt ? globalFilter.dgt : undefined,
    dt: globalFilter.dt ? globalFilter.dt : undefined,
    od: globalFilter.od ? globalFilter.od : undefined,
    firstName: globalFilter.firstName ? globalFilter.firstName : undefined,
    lastName: globalFilter.lastName ? globalFilter.lastName : undefined,
    nif: globalFilter.nif ? globalFilter.nif : undefined,
    group: globalFilter.group ? globalFilter.group : undefined,
    myTeamData: true,
    year,
    status: globalFilter.status ? globalFilter.status : undefined,
    order: globalParams?.order || 'dgt',
    orderType: globalParams?.orderType || 'desc',
  };

  const conventionParams = {
    ...Params,
    numberElements: globalParams?.numberElements || 10,
    numberPage: globalParams?.numberPage || 1,
  };

  const conventionParamsExport = {
    ...Params,
    numberElements: undefined,
    numberPage: undefined,
  };

  const conventionByCodeParams = {
    code: id,
    myTeam: true,
    numberElements: globalParams?.numberElements || 10,
    numberPage: globalParams?.numberPage || 1,
    order: globalParams?.order || 'dgt',
    orderType: globalParams?.orderType || 'desc',
  };

  const filtersParams = {
    type: 'Convention',
    code: id,
    year,
  };

  useRemoveUndefinedProperties(conventionParams);

  const {
    data: dataPlanByCode,
    error: errorPlanByCode,
  } = useQuery(
    ['ConventionByCode', conventionByCodeParams],
    async () => (graphQLClient && graphQLClient.request(
      GET_PLAN_RESULTS_CONCRETE_BY_CODE_GQL,
      conventionByCodeParams,
    )),
    {
      staleTime: Infinity,
    },
  );

  const {
    data: dataResultsByCode,
    error: errorResultsByCode,
    refetch: refetchResultsByCode,
    isFetching: isFetchingResultsByCode,
    status: statusResultsByCode,
  } = useQuery(
    ['ConventionResultsByCode', conventionParams],
    async () => (graphQLClient && graphQLClient.request(GET_RESULTS_BY_CODE_GQL, conventionParams)),
    {
      staleTime: Infinity,
    },
  );

  const {
    data: dataFilters,
    error: errorFilters,
    isFetching: isFetchingFilters,
  } = useQuery(
    ['ConventionResultsFilters', filtersParams],
    async () => (graphQLClient && graphQLClient.request(
      GET_MY_TEAM_DATA_FILTERS_GQL,
      filtersParams,
    )),
    {
      staleTime: Infinity,
    },
  );

  const {
    data: dataResultsByCodeXlsx,
    refetch: refetchResultsByCodeXlsx,
    status: statusResultsByCodeXlsx,
  } = useQuery(
    ['ConventionResultsByCodeXlsx', conventionParamsExport],
    async () => (graphQLClient && graphQLClient.request(
      GET_RESULTS_BY_CODE_GQL,
      conventionParamsExport,
    )),
    {
      staleTime: Infinity,
    },
  );

  useEffect(() => {
    if (categoryResponse?.data?.data?.userCategory !== undefined) {
      setCategory(categoryResponse?.data?.data?.userCategory);
    }

    if (statusResultsByCode !== 'success' && (globalParams.numberPage !== undefined
      || globalParams.order !== undefined
      || globalParams.orderType !== undefined)
    ) {
      refetchResultsByCode();
    }

    if (statusResultsByCodeXlsx !== 'success' && (globalParams.numberPage !== undefined
      || globalParams.order !== undefined
      || globalParams.orderType !== undefined)
    ) {
      refetchResultsByCodeXlsx();
    }
  }, [globalParams.numberPage, globalParams.order, globalParams.orderType]);

  useEffect(() => {
    if (category !== undefined) {
      setGlobalFilter({ ...globalFilter, selectedScope: category?.scope });
    }
  }, [category]);

  useEffect(() => {
    if (statusResultsByCode === 'success') {
      refetchResultsByCodeXlsx();
    }
    if (statusResultsByCodeXlsx === 'success') {
      refetchResultsByCode();
    }
  }, [globalFilter]);

  const {
    columns,
    data,
  } = DetailResultsAdapter(id, dataResultsByCode, dataPlanByCode);

  const { readyToExport } = useExportStore((state) => ({
    readyToExport: state.readyToExport,
  }), shallow);

  if (errorResultsByCode || errorFilters || errorPlanByCode) {
    return <Container>Error</Container>;
  }

  return (
    <Container>
      <ButtonBack onClick={() => navigate(-1)} type="button">Volver</ButtonBack>
      <Typography align="center" color={Theme.colorConvention} display="block" size="28px" margin="2rem auto" weight="500">
        {dataPlanByCode?.planByCode?.description}
      </Typography>

      {!isFetchingFilters ? (
        <FilterForm
          dataFilters={dataFilters}
          filter={globalFilter}
          setFilter={updateGlobalFilter}
          type="Convention"
        />
      ) : <FilterFormSkeleton countFilters={10} />}

      {dataResultsByCodeXlsx && (
        <CardDataExport
          id={id}
          data={dataResultsByCodeXlsx}
          dependency={dataPlanByCode}
          fileName={`convention_${id}`}
        />
      )}

      {readyToExport === true && (
        <Loading message="Estamos procesando su fichero. Esto puede tardar unos minutos." tooltip />
      )}

      {!isFetchingResultsByCode && columns.length >= 2 ? (
        data && (
          <Table
            columns={columns}
            data={data}
            theme={Theme.colorConvention}
            countsRecords={dataResultsByCode?.myTeamResultsByCode?.count}
            defaultPageSize={10}
            countPerPage={10}
            globalParams={globalParams}
            setGlobalParams={updateGlobalParams}
          />
        )
      ) : <TableSkeleton countCell={6} theme={Theme.colorConvention} /> }

    </Container>
  );
}
