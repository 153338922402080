import { useQuery } from 'react-query';
import { Generic } from '../interfaces/Generic.d';
import { Column } from '../interfaces/Column.d';
import { DelegatePlan } from '../interfaces/DelegatePlan.d';
import useCsvNumber from './useCsvNumber';
import useProcessData from './useProcessData';
import { getGraphQLClient } from '../graphql/graphqlClient';
import GET_DELEGATE_PLAN_DETAIL from '../graphql/queries/getDelegatePlanDetailGql';
import useDelegatePlanDetailColumns from './useDelegatePlanDetailColumns';
import GET_DELEGATE_PLAN_RESUME from '../graphql/queries/getDelegatePlanResumeGql';
import Theme from '../styles/Theme';
import useNumberFormat from './userNumberFormat';

type Variables = {
  [key: string]: string | number | undefined;
  timeframe?: string;
  startDate?: string;
  endDate?: string;
  grouping?: string;
  type?: string;
  status?: string;
  year?: number;
}

export default function useDelegatePlanDetail(
  variables: Variables,
  planId: string,
  planName: string,
  planColor: string,
) {
  const graphQLClient = getGraphQLClient();
  let planStructure = [];
  let columns: Generic[] = [];
  let columnsExcel: Generic[] = [];
  let statistic: Generic = {};
  const statisticStructure: Generic = [];
  let graphics: Generic = {};
  const planType = variables.type;
  const {
    campaignColumns,
    campaignColumnsExcel,
    rappelColumns,
    rappelColumnsExcel,
    conventionColumns,
    conventionColumnsExcel,
  } = useDelegatePlanDetailColumns();

  const {
    data,
    error,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(
    [`${planType}_delegates_summary`, variables],
    async () => (graphQLClient && graphQLClient.request(
      GET_DELEGATE_PLAN_DETAIL,
      variables,
    )),
    {
      staleTime: Infinity,
    },
  );

  const {
    data: dataDelegatePlanResume,
  } = useQuery(
    [`${planType}_delegates_resume`, variables],
    async () => (graphQLClient && graphQLClient.request(
      GET_DELEGATE_PLAN_RESUME,
      variables,
    )),
    {
      staleTime: Infinity,
    },
  );

  const delegatePlanResume = dataDelegatePlanResume?.delegatePlanResume;

  if (planType && planType === 'Campaign') {
    columns = campaignColumns.map((column: Column) => column);
    columnsExcel = campaignColumnsExcel.reassignColumn;
    planStructure = data?.delegatePlanDetail?.campaigns?.nodes?.map((plan: DelegatePlan) => ({
      name: plan?.name,
      prize: plan?.prize,
      win: plan?.win,
    }));
    statistic = {
      participantsCountCampaign: delegatePlanResume?.participantsCountCampaign,
      trebolesCampaign: delegatePlanResume?.trebolesCampaign,
      pointsCampaign: delegatePlanResume?.pointsCampaign,
      dataAmountCampaign: delegatePlanResume?.dataAmountCampaign,
      participantsCountCampaignTotal: delegatePlanResume?.participantsCountCampaignTotal,
      trebolesCampaignTotal: delegatePlanResume?.trebolesCampaignTotal,
      pointsCampaignTotal: delegatePlanResume?.pointsCampaignTotal,
      dataAmountCampaignTotal: delegatePlanResume?.dataAmountCampaignTotal,
      winsCountCampaign: delegatePlanResume?.winsCountCampaign,
    };

    graphics = {
      values: [
        {
          id: 0,
          title: 'Participa',
          value: useNumberFormat(statistic?.participantsCountCampaign),
        },
        {
          id: 1,
          title: 'Ganadas',
          value: useNumberFormat(statistic?.winsCountCampaign),
        },
      ],
      title: 'Ganadas',
      description: useNumberFormat(statistic?.winsCountCampaign),
      charts: [
        {
          id: 0,
          type: 'pie',
          theme: Theme.colorCampaign,
          score: useNumberFormat(statistic?.winsCountCampaign),
          total: statistic?.participantsCountCampaign,
        },
        {
          id: 1,
          type: 'card',
          title: 'Tréboles',
          score: useNumberFormat(statistic?.trebolesCampaign),
          icon: 'clover',
        },
        {
          id: 2,
          type: 'card',
          title: 'Puntos',
          score: useNumberFormat(statistic?.pointsCampaign),
          icon: 'start',
        },
        {
          id: 3,
          type: 'card',
          title: 'Importe a fecha',
          score: useNumberFormat(statistic?.dataAmountCampaign),
          icon: 'euro',
        },
      ],
    };
  }

  if (planType && planType === 'Rappel') {
    columns = rappelColumns;
    columnsExcel = rappelColumnsExcel.reassignColumn;
    planStructure = data?.delegatePlanDetail?.rappels?.nodes?.map((plan: DelegatePlan) => ({
      name: plan?.name,
      win: plan?.win,
      amount: plan?.amount,
      state: plan?.state,
    }));

    statistic = {
      winsCountRappel: delegatePlanResume?.winsCountRappel,
      provisionalAmountRappel: delegatePlanResume?.provisionalAmountRappel,
      winCountRappelTotal: delegatePlanResume?.winCountRappelTotal,
      provisionalAmountRappelTotal: delegatePlanResume?.provisionalAmountRappelTotal,
      participantsCountRappel: delegatePlanResume?.participantsCountRappel,
    };

    graphics = {
      title: 'Ganados',
      description: undefined,
      values: [
        {
          id: 0,
          title: 'Participa',
          value: useNumberFormat(statistic?.participantsCountRappel),
        },
        {
          id: 1,
          title: 'Ganados',
          value: useNumberFormat(statistic?.winsCountRappel),
        },
      ],
      charts: [
        {
          id: 0,
          type: 'pie',
          theme: Theme.colorRappel,
          score: statistic?.winsCountRappel,
          total: statistic?.participantsCountRappel,
        },
        {
          id: 1,
          type: 'card',
          title: 'Importe provisional',
          score: useNumberFormat(statistic?.provisionalAmountRappel),
          icon: 'euro',
        },
      ],
    };
  }

  if (planType && planType === 'Convention') {
    columns = conventionColumns;
    columnsExcel = conventionColumnsExcel.reassignColumn;
    planStructure = data?.delegatePlanDetail?.conventions?.nodes?.map((plan: DelegatePlan) => ({
      name: plan?.name,
      insurance: plan?.insurance,
      met: plan?.met,
      rankingDgt: plan?.rankingDgt,
      rankingGeneral: plan?.rankingGeneral,
    }));
    statistic = {
      extraMillesConvention: delegatePlanResume?.extraMillesConvention,
      totalMillesConvention: delegatePlanResume?.totalMillesConvention,
      winConvention: delegatePlanResume?.winConvention === false ? 'NO' : 'SI',
      extraMillesConventionTotal: delegatePlanResume?.extraMillesConventionTotal,
      minMillesConvention: delegatePlanResume?.minMillesConvention,
      winConventionTotal: delegatePlanResume?.winConventionTotal,
    };

    graphics = {
      title: 'Total Millas',
      description: undefined,
      values: [
        {
          id: 0,
          title: 'Mínimo Millas',
          value: useNumberFormat(statistic?.minMillesConvention),
        },
        {
          id: 1,
          title: 'Total Millas',
          value: useNumberFormat(statistic?.totalMillesConvention),
        },
      ],
      charts: [
        {
          id: 0,
          type: 'pie',
          theme: Theme.colorConvention,
          score: statistic?.totalMillesConvention,
          total: statistic?.minMillesConvention,
        },
        {
          id: 1,
          type: 'card',
          title: 'Millas Extra',
          score: useNumberFormat(statistic?.extraMillesConvention),
          icon: 'euro',
        },
        {
          id: 2,
          type: 'card',
          title: 'Gana',
          score: statistic?.winConvention,
          icon: 'cup',
        },
      ],
    };
  }

  const planFormatExcel = planStructure !== undefined
    ? planStructure?.map((d: any) => ({ ...d }))
    : [];

  planFormatExcel.forEach((item: any, index: number) => {
    Object.entries(item).forEach(([key, value]: any) => {
      planFormatExcel[index][key] = useCsvNumber(value);
    });
  });

  const planFormat = useProcessData(planStructure);

  return {
    planId,
    planType,
    planName,
    planColor,
    planFormat,
    planFormatExcel,
    refetch,
    isFetching,
    error,
    isLoading,
    columns,
    columnsExcel,
    statistic,
    statisticStructure,
    graphics,
  };
}
