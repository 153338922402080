import Styled from 'styled-components';
import { Field } from 'formik';
import Theme from '../../../styles/Theme';

const InputField = Styled(Field)`
  appearance: none;
  background: ${Theme.colorWhite};
  border: 1px solid ${Theme.colorSecondary3};
  border-radius: ${Theme.radiusField};
  font-family: ${Theme.fontFamily};
  font-size: 16px;
  padding: 12px 16px;
  height: 48px;
  margin-bottom: 8px;
  transition: box-shadow 0.3s ease, outline 0.3s ease;

  &::placeholder {
    color: ${Theme.colorSecondary3};
  }

  &:focus,
  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 0.25rem rgba(156, 176, 188, 0.32);
  }

  &.error {
    box-shadow: 0 0 0 0.18rem ${Theme.colorError};
  }
`;

export const InputFieldForm = Styled(Field)`
  appearance: none;
  background: ${Theme.colorWhite};
  border: 1px solid ${Theme.colorSecondary3};
  border-radius: ${Theme.radiusField};
  font-family: ${Theme.fontFamily};
  font-size: 16px;
  padding: 12px 16px;
  height: 48px;
  margin-bottom: 8px;
  transition: box-shadow 0.3s ease, outline 0.3s ease;

  &::placeholder {
    color: ${Theme.colorSecondary3};
  }

  &:focus,
  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 0.25rem rgba(156, 176, 188, 0.32);
  }

  &.error {
    box-shadow: 0 0 0 0.18rem ${Theme.colorError};
  }
`;

export const TextAreaField = Styled.textarea`
  appearance: none;
  height: auto;
  min-height: 120px;
  padding: 16px;
  font-size: 16px;
  resize: none;
  font-family: inherit;
  background: ${Theme.colorWhite};
  border: 1px solid ${Theme.colorSecondary3};
  border-radius: ${Theme.radiusField};
  font-family: ${Theme.fontFamily};

  &::placeholder {
    color: ${Theme.colorSecondary3};
  }

  &:focus,
  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 0.25rem rgba(156, 176, 188, 0.32);
  }

  &.error {
    box-shadow: 0 0 0 0.18rem ${Theme.colorError};
  }
`;

export default InputField;
