import { Generic } from '../interfaces/Generic.d';
import useUrlDetailComparative from '../hooks/useUrlDetailComparative';
import useHelpLink from '../hooks/useHelpLink';
import useFormatDate from '../hooks/useDate';
import formatValue from '../hooks/useFormatValue';
import formatColumn from '../hooks/useFormatColumn';
import useMappingColumnNames from '../hooks/useMappingColumnNames';
import useUniqueColumns from '../hooks/useUniqueColumns';
import useRemoveAccents from '../hooks/useRemoveAccents';
import { CurrentUser } from '../interfaces/CurrentUser.d';
import { ComparativeResultsByCode } from '../interfaces/ComparativeResultsByCode.d';
import { Column } from '../interfaces/Column.d';
import { PlanLevel } from '../interfaces/PlanLevel.d';
import useReassignColumn from '../hooks/useReassignColumn';

export default function ComparativeAdapter(
  dataCurrentUserInfo: CurrentUser,
  dataConventionComparative: ComparativeResultsByCode,
  idConvention?: any,
) {
  const translations = dataConventionComparative?.comparativeResultsByCode
    ? JSON.parse(dataConventionComparative?.comparativeResultsByCode?.translations) : {};

  let resultsWithoutDGTs: Generic = {};
  let myOwnData: Generic = {};
  const dataResultsExtraFields: Generic = {};
  const dataFormatted:Generic = [];

  const myOwnResult:Generic = dataConventionComparative?.comparativeResultsByCode?.myOwnResult;
  const myOwnResultWithoutDGTs: Generic = {};

  const myDataOwnResult:Generic = {
    dgt: myOwnResult?.territoryNameDgt ? `${myOwnResult?.dgt} - ${myOwnResult?.territoryNameDgt}` : myOwnResult?.dgt,
    dt: myOwnResult?.territoryNameDt ? `${myOwnResult?.dt} - ${myOwnResult?.territoryNameDt}` : myOwnResult?.dt,
    od: myOwnResult?.territoryNameOd ? `${myOwnResult?.od} - ${myOwnResult?.territoryNameOd}` : myOwnResult?.od,
    denomination: `${myOwnResult?.firstName} ${myOwnResult?.lastName}`,
    nif: myOwnResult?.nif,
    extraFields: myOwnResult?.extraFields,
    dataDate: myOwnResult?.dataDate,
    Detalle: useUrlDetailComparative(myOwnResult, dataCurrentUserInfo),
    Ayuda: useHelpLink(idConvention),
  };

  if (myDataOwnResult && myDataOwnResult?.extraFields && Object.keys(translations).length > 0) {
    Object.keys(myDataOwnResult?.extraFields)
      .filter((column: string) => !['DGT', 'DT', 'OD']
        .includes(column))
      .map((key:string) => {
        if (key === 'FECHA ACT' || key === 'FECHA DATO') {
          if (myDataOwnResult?.dataDate) {
            myOwnResultWithoutDGTs['FECHA DATO'.replace(/ /g, '')] = useFormatDate(myDataOwnResult?.dataDate);
          } else {
            myOwnResultWithoutDGTs['FECHA DATO'.replace(/ /g, '')] = useFormatDate(myDataOwnResult?.extraFields[key]);
          }
        } else {
          myOwnResultWithoutDGTs[key.replace(/ /g, '').split('.').join('')] = formatValue(myDataOwnResult?.extraFields[key], key, translations);
        }
        return myOwnResultWithoutDGTs;
      });
    myOwnData = { ...myDataOwnResult, ...myOwnResultWithoutDGTs };
  }

  const myOrderedExtraFields: Generic[] = [];
  let myTotalCols: Column[] = [];
  dataConventionComparative?.comparativeResultsByCode?.planLevels
    .sort((a: any, b: any) => a.order - b.order)
    .map((pl:any) => {
      if (pl?.level === 1
          && Object.keys(translations).length > 0
          && Object.keys(myOwnResultWithoutDGTs)
            .map((e:any) => e.replace(/ /g, ''))
            .includes(pl.field.replace(/ /g, '')
              .split('.')
              .join(''))) {
        myOrderedExtraFields.push({
          Header: formatColumn(pl.field.split('.').join(''), translations),
          accessor: pl.field.replace(/ /g, '').split('.').join(''),
        });
      }
      return myOrderedExtraFields;
    });
  delete myDataOwnResult.extraFields;

  Object.keys(myDataOwnResult).map((col:string) => {
    if (col !== 'dataDate') {
      myTotalCols.push({ Header: useMappingColumnNames(col, 'convention'), accessor: col.replace(/ /g, '') });
    }
    return myTotalCols;
  });

  myTotalCols = [myTotalCols, myOrderedExtraFields].flat();

  const results = dataConventionComparative?.comparativeResultsByCode?.otherResults?.nodes
    ?.map((result) => ({
      dgt: result.territoryNameDgt ? `${result.dgt} - ${result?.territoryNameDgt}` : result.dgt,
      dt: result.territoryNameDt ? `${result.dt} - ${result.territoryNameDt}` : result.dt,
      od: result.territoryNameOd ? `${result.od} - ${result.territoryNameOd}` : result.od,
      denomination: `${result.firstName} ${result.lastName}`,
      nif: result?.nif,
      extraFields: result?.extraFields,
      puestoId: result?.puestoId,
      dataDate: result?.dataDate,
    }));

  results?.map((result: Generic) => {
    resultsWithoutDGTs = {};
    Object.keys(result?.extraFields)
      .filter((keys:string) => !['DGT', 'DT', 'OD']
        .includes(keys))
      .map((key:string) => {
        if (key === 'FECHA ACT' || key === 'FECHA DATO') {
          if (result?.dataDate) {
            resultsWithoutDGTs['FECHA DATO'.replace(/ /g, '')] = useFormatDate(result?.dataDate);
          } else {
            resultsWithoutDGTs['FECHA DATO'.replace(/ /g, '')] = useFormatDate(result?.extraFields[key]?.split('T')[0]);
          }
        } else {
          resultsWithoutDGTs[useRemoveAccents(key.replace(/ /g, '').split('.').join(''))] = formatValue(result?.extraFields[key], key, translations);
        }

        return resultsWithoutDGTs;
      });

    const rowWithExtras = { ...result, ...resultsWithoutDGTs };
    delete rowWithExtras.extraFields;
    dataFormatted.push(rowWithExtras);

    return result;
  });

  const orderedExtraFields: Generic = [];
  let totalCols: Generic = [];

  dataConventionComparative?.comparativeResultsByCode?.planLevels?.sort(
    (a: any, b: any) => a.order - b.order,
  ).map((pl:any) => {
    if (pl?.level === 1 && Object.keys(dataResultsExtraFields).map((e:any) => e.replace(/ /g, '')).includes(pl.field.replace(/ /g, '').split('.').join(''))) {
      orderedExtraFields.push({ Header: formatColumn(pl.field.split('.').join(''), translations), accessor: pl.field.replace(/ /g, '').split('.').join('') });
    }
    return orderedExtraFields;
  });

  if (results?.length > 0) {
    delete results[0]?.extraFields;

    Object.keys(results[0]).map((col: string) => {
      if (col !== 'dataDate') {
        totalCols.push({ Header: useMappingColumnNames(col, 'convention'), accessor: col.replace(/ /g, '') });
      }
      return totalCols;
    });
    totalCols = [totalCols, orderedExtraFields].flat();
  }

  const { uniqueColumns } = useUniqueColumns(myTotalCols);

  let otherColumns = uniqueColumns;
  let myOwnColumns: Column[] = [];

  myOwnColumns = otherColumns.filter((column: Column) => column.Header !== 'Detalle' && column.Header !== 'Ayuda');
  if (dataCurrentUserInfo?.me?.plans?.nodes[0]?.grouping?.name !== 'CONVENCION_RANKING'
  && dataCurrentUserInfo?.me?.plans?.nodes
    ?.map((e:any) => e)
    ?.map((l:any) => l?.planLevels?.some((x: PlanLevel) => x.level > 1))
    ?.some((r: any) => r === true)
  ) {
    myOwnColumns = [...myOwnColumns, { Header: 'Detalle', accessor: 'Detalle' }, { Header: 'Ayuda', accessor: 'Ayuda' }];
  }

  otherColumns = otherColumns.filter((column: Column) => (column.Header !== 'Detalle' && column.Header !== 'Ayuda'));

  const otherData = dataFormatted.map((data: Generic) => ({ ...data }));

  const myOwnColumnsXlsx = useReassignColumn(myOwnColumns.filter((column: Column) => (column.Header !== 'Detalle' && column.Header !== 'Ayuda')));
  const otherColumnsXlsx = useReassignColumn(otherColumns.filter((column: Column) => (column.Header !== 'Detalle' && column.Header !== 'Ayuda')));

  return {
    myOwnData,
    myOwnColumns,
    myOwnColumnsXlsx,
    otherData,
    otherColumns,
    otherColumnsXlsx,
  };
}
