import Styled from 'styled-components';
import Theme from '../../../styles/Theme';

const CtaCreateTicket = Styled.div`
  display: flex;
  justify-content: start;
  align-items: center;

  a {
    color: ${Theme.colorWhite};
    background-color: ${Theme.colorPrimary};
    border-color: #007bff;
    padding: 0.5rem 24px;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 8px;
    text-decoration: none;

    span {
      color: ${Theme.colorWhite};
    }

    &:hover {
      color: #fff;
    }
  }
`;

export default CtaCreateTicket;
