import { Column } from '../interfaces/Column.d';
import useReassignColumn from './useReassignColumn';

export default function useSummaryTeamColumns() {
  const campaignColumns:Column[] = [
    { Header: 'Avísame', accessor: 'subscribed' },
    { Header: 'Campaña', accessor: 'name' },
    { Header: 'Gana', accessor: 'wins' },
    { Header: 'Participantes', accessor: 'participants' },
    { Header: 'Datos a día', accessor: 'updatedAt' },
    { Header: 'Estado', accessor: 'status' },
  ];

  const campaignColumnsExcel = useReassignColumn(campaignColumns);

  const rappelColumns:Column[] = [
    { Header: 'Avísame', accessor: 'subscribed' },
    { Header: 'Rappel', accessor: 'name' },
    { Header: 'Importe', accessor: 'amount' },
    { Header: 'Participantes', accessor: 'participants' },
    { Header: 'Cumple', accessor: 'comply' },
    { Header: 'Datos a día', accessor: 'updatedAt' },
    { Header: 'Estado', accessor: 'status' },
  ];

  const rappelColumnsExcel = useReassignColumn(rappelColumns);

  const conventionColumns:Column[] = [
    { Header: 'Avísame', accessor: 'subscribed' },
    { Header: 'Convención', accessor: 'name' },
    { Header: 'Participa', accessor: 'participants' },
    { Header: 'Gana', accessor: 'wins' },
    { Header: 'Datos a día', accessor: 'dataDate' },
    { Header: 'Estado', accessor: 'status' },
    { Header: 'Ver resultados convención', accessor: 'results' },
  ];

  const conventionColumnsExcel = useReassignColumn(conventionColumns);

  return {
    campaignColumns,
    campaignColumnsExcel,
    rappelColumns,
    rappelColumnsExcel,
    conventionColumns,
    conventionColumnsExcel,
  };
}
